import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/home/components/pitch/nestleDashboard/components/welcomeWidget/WelcomeWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const WelcomeWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.card}>
      <div className={styles.header}>
        <WppTypography type="m-strong" className={styles.text}>
          {`Hello ${userDetails.firstname}!`}
        </WppTypography>
        <br />
        <WppTypography type="m-body" className={styles.text}>
          Welcome to OpenMind
        </WppTypography>
      </div>
      <div>
        <div>
          <WppTypography type="s-body" className={styles.text}>
            Cluster:&nbsp;
          </WppTypography>
          <WppTypography type="s-strong" className={styles.text}>
            DACH
          </WppTypography>
        </div>
        <div>
          <WppTypography type="s-body" className={styles.text}>
            Zone:&nbsp;
          </WppTypography>
          <WppTypography type="s-strong" className={styles.text}>
            Europe
          </WppTypography>
        </div>
        <div>
          <WppTypography type="s-body" className={styles.text}>
            Role:&nbsp;
          </WppTypography>
          <WppTypography type="s-strong" className={styles.text}>
            Data and Audience Director
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
