import AaronQuirk from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Aaron Quirk.jpg'
import CarolineReynolds from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Caroline Reynolds.jpg'
import CarolynSimpson from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Carolyn Simpson.jpg'
import DaleGriffins from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Dale Griffins.jpg'
import DanWhitmarsh from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Dan Whitmarsh.jpg'
import SarahAggarwal from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Sarah Aggarwal.jpg'
import TomCasswell from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Tom Casswell.jpg'
import TomWhite from 'pages/home/components/pitch/valueRetailDashboard/components/teamWidget/assets/Tom White.jpg'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Aaron Quirk',
    role: 'CEO EMEA',
    profileImgSrc: AaronQuirk,
  },
  {
    name: 'Tom White',
    role: 'Global Head of Performance',
    profileImgSrc: TomWhite,
  },
  {
    name: 'Dan Whitmarsh',
    role: 'Chief Strategy Officer',
    profileImgSrc: DanWhitmarsh,
  },
  {
    name: 'Sarah Aggarwal',
    role: 'Europe Analytics Lead',
    profileImgSrc: SarahAggarwal,
  },
  {
    name: 'Dale Griffins',
    role: 'WW Head of Integrated Planning',
    profileImgSrc: DaleGriffins,
  },
  {
    name: 'Tom Casswell',
    role: 'Executive Director - Worldwide Clients',
    profileImgSrc: TomCasswell,
  },
  {
    name: 'Carolyn Simpson',
    role: 'Global Head of Data Strategy',
    profileImgSrc: CarolynSimpson,
  },
  {
    name: 'Caroline Reynolds',
    role: 'Chief Digital Officer',
    profileImgSrc: CarolineReynolds,
  },
]
