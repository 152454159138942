import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/aldiDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.aldiWorkgroupTitle}>
        Welcome to ALDI Open OS
      </WppTypography>
      <Flex gap={4}>
        <WppTypography type="s-body">Location:</WppTypography>
        <WppTypography type="s-strong">Portugal</WppTypography>
      </Flex>
      <Flex gap={4}>
        <WppTypography type="s-body">Team:</WppTypography>
        <WppTypography type="s-strong">IMS</WppTypography>
      </Flex>
      <WppTypography type="s-strong" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <div className={styles.aldiNotificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-strong">Ricardo Santos&nbsp;</WppTypography>
            <WppTypography type="s-body">approved the campaign plan for&nbsp;</WppTypography>
            <WppTypography type="s-strong">32682 ALDR ALDI Achados Alim sem36 Sep23</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 12:10
          </WppTypography>
        </div>
      </div>
      <div className={styles.aldiNotificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-strong">Adriana Serra&nbsp;</WppTypography>
            <WppTypography type="s-body">approved the campaign plan for&nbsp;</WppTypography>
            <WppTypography type="s-strong">32788 ALDR ALDI Achados Preço sem38 Sep23&nbsp;</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Ricardo Santos&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">32682 ALDR ALDI Achados Alim sem36 Sep23&nbsp;</WppTypography>
        <WppTypography type="s-strong">project campaign</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 10:00
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Adriana Serra&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">32788 ALDR ALDI Achados Preço sem38 Sep23</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 09:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Jessa Benneker&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to view&nbsp;</WppTypography>
        <WppTypography type="s-strong">Portugal Transformation</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 09:27
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
