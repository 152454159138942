import { DashboardsWidget } from 'pages/home/components/pitch/pfizerDashboard/components/dashboardsWidget/DashboardsWidget'
import { GlobalDataWidget } from 'pages/home/components/pitch/pfizerDashboard/components/globalDataWidget/GlobalDataWidget'
import { OSAssistantWidget } from 'pages/home/components/pitch/pfizerDashboard/components/osAssistantWidget/OSAssistantWidget'
import { ProcessWidget } from 'pages/home/components/pitch/pfizerDashboard/components/processWidget/ProcessWidget'
import { ProjectManagementWidget } from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/ProjectManagementWidget'
import { TeamWidget } from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/TeamWidget'
import { UpcomingDatesWidget } from 'pages/home/components/pitch/pfizerDashboard/components/upcomingDatesWidget/UpcomingDatesWidget'
import { VideoWidget } from 'pages/home/components/pitch/pfizerDashboard/components/videoWidget/VideoWidget'
import styles from 'pages/home/components/pitch/pfizerDashboard/PfizerDashboard.module.scss'
import { pfizerWidgets } from 'pages/home/components/pitch/pfizerDashboard/widgetsMock'
import { PfizerWidgetType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType }: { widgetType: SystemWidget | PfizerWidgetType }) => {
  switch (widgetType) {
    case PfizerWidgetType.VIDEO:
      return <VideoWidget />
    case PfizerWidgetType.DASHBOARDS:
      return <DashboardsWidget />
    case PfizerWidgetType.PROCESS:
      return <ProcessWidget />
    case PfizerWidgetType.PROJECT_MANAGEMENT:
      return <ProjectManagementWidget />
    case PfizerWidgetType.OS_ASSISTANT:
      return <OSAssistantWidget />
    case PfizerWidgetType.UPCOMING_DATES:
      return <UpcomingDatesWidget />
    case PfizerWidgetType.GLOBAL_DATA:
      return <GlobalDataWidget />
    case PfizerWidgetType.TEAM:
      return <TeamWidget />
    default:
      return null
  }
}

export const PfizerDashboard = () => (
  <div className={styles.container}>
    {pfizerWidgets.map(({ id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name }) => (
      <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
        <RenderWidget widgetType={name} />
      </div>
    ))}
  </div>
)
