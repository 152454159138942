import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { CSSProperties, useMemo } from 'react'

import { useUpdateLinkLastUsedApi } from 'api/links/mutations/useUpdateLinkLastUsedApi'
import { Avatar } from 'components/common/avatar/Avatar'
import styles from 'pages/home/systemWidgets/favouriteLinksWidget/FavouriteLinksWidgetList.module.scss'
import {
  getFavouriteLinksWidgetListGap,
  useColumnsCount,
  useLoadingConfig,
} from 'pages/home/systemWidgets/favouriteLinksWidget/utils'
import { WidgetList } from 'pages/home/widgetList/WidgetList'
import { LoadingWidgetListItem, WidgetListItem } from 'pages/home/widgetListItem/WidgetListItem'
import { Link } from 'types/links/links'
import { trackAnalytics } from 'utils/analytics'
import { emptyArray } from 'utils/common'
import { EVENTS, getEventPayload } from 'utils/events'

interface Props {
  readOnly?: boolean
  favouriteLinks: Link[]
  width: number
  height: number
}

export const FavouriteLinksWidgetList = ({ readOnly = false, favouriteLinks, width, height }: Props) => {
  const { mutateAsync: handleUpdateLastUsed } = useUpdateLinkLastUsedApi()

  const columnsCount = useColumnsCount({ width, height })

  const gap = useMemo(() => getFavouriteLinksWidgetListGap({ width, height }), [width, height])

  const handleTrackUserAction = ({ id, name }: Link) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_FAVORITE_LINKS_WIDGET_ITEM_SELECT, name),
    })

    handleUpdateLastUsed({
      id,
    })
  }

  return (
    <WidgetList
      data-testid="favourite-links-list"
      getItemId={({ id }) => id}
      className={styles.list}
      gap={gap}
      style={{ '--columns-count': columnsCount } as CSSProperties}
      items={favouriteLinks}
      renderItem={link => {
        const { url, logoThumbnail, name, id, openInCurrentTab } = link

        return (
          <WidgetListItem
            data-testid={`favourite-${id}`}
            className={styles.listItem}
            {...(!readOnly && {
              linkConfig: {
                href: url,
                target: openInCurrentTab ? '_self' : '_blank',
                rel: 'noreferrer',
              },
              onClick: () => handleTrackUserAction(link),
            })}
          >
            <Avatar slot="left" src={logoThumbnail?.url || ''} name={name} />
            <span slot="label">{name}</span>
          </WidgetListItem>
        )
      }}
    />
  )
}

interface LoadingProps {
  width: number
  height: number
}

export const LoadingFavouriteLinksWidgetList = ({ width, height }: LoadingProps) => {
  const columnsCount = useColumnsCount({ width, height })
  const loadingConfig = useLoadingConfig({ width, height })!

  return (
    <WidgetList
      data-testid="loading-favourite-links-list"
      className={styles.list}
      style={{ '--columns-count': columnsCount } as CSSProperties}
      gap={loadingConfig.gap}
      items={emptyArray(loadingConfig.itemsCount)}
      renderItem={(_, index) => (
        <LoadingWidgetListItem>
          <WppSkeleton
            slot="left"
            width={loadingConfig.rectangleSize}
            height={loadingConfig.rectangleSize}
            variant="rectangle"
          />
          <WppSkeleton slot="label" width={index % 2 && loadingConfig.isVariable ? '80%' : '100%'} height={12} />
        </LoadingWidgetListItem>
      )}
    />
  )
}
