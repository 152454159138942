import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/valueRetailDashboard/components/introWidget/IntroWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const IntroWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex className={styles.cardContent} justify="center" align="center">
      <WppTypography type="4xl-display" className={styles.title}>
        Welcome to Modern Luxury
      </WppTypography>
    </Flex>
  </WidgetCard>
)
