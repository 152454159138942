import { IllyWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type IllyWidget = Omit<Widget, 'name'> & { name: IllyWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'illy_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [IllyWidgetType.PROCESS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [IllyWidgetType.INTRO]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [IllyWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [IllyWidgetType.MEDIA_EFFECTIVENESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [IllyWidgetType.EXCELLENCE_HUB]: {
    gridRowStart: 3,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [IllyWidgetType.MAP_DASHBOARD]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const illyWidgets: IllyWidget[] = [
  {
    ...gridConfig[IllyWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: IllyWidgetType.PROCESS,
    id: 'illy_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[IllyWidgetType.INTRO],
    ...commonWidgetsConfig,
    name: IllyWidgetType.INTRO,
    id: 'illy_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[IllyWidgetType.USER],
    ...commonWidgetsConfig,
    name: IllyWidgetType.USER,
    id: 'illy_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'illy_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[IllyWidgetType.MEDIA_EFFECTIVENESS],
    ...commonWidgetsConfig,
    name: IllyWidgetType.MEDIA_EFFECTIVENESS,
    id: 'illy_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[IllyWidgetType.EXCELLENCE_HUB],
    ...commonWidgetsConfig,
    name: IllyWidgetType.EXCELLENCE_HUB,
    id: 'illy_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[IllyWidgetType.MAP_DASHBOARD],
    ...commonWidgetsConfig,
    name: IllyWidgetType.MAP_DASHBOARD,
    id: 'illy_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'illy_8',
    createdAt: '',
    updatedAt: '',
  },
]
