import clsx from 'clsx'

import styles from 'pages/playground/components/imageCard/ImageCard.module.scss'
import stylesMain from 'pages/playground/Playground.module.scss'

interface Props {
  img: string
  text: string
  className?: string
}

export const ImageCard = ({ img, text, className }: Props) => {
  return (
    <div className={className}>
      <img className={clsx(stylesMain.wFull, styles.imgContainer)} src={img} alt={img} />
      <div className={clsx(stylesMain.blackH4, styles.container)}>{text}</div>
    </div>
  )
}
