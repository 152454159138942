import styles from 'pages/appsNavigation/noCodeApp/defaultNoCodeAppRenderer/DefaultNoCodeAppRenderer.module.scss'
import { PageBuilderRender } from 'pages/appsNavigation/noCodeApp/pageBuilderRender/PageBuilderRender'
import { PenpalParentFrame } from 'pages/appsNavigation/noCodeApp/penpalParentFrame/PenpalParentFrame'
import { NoCodeAppMiroBoard, NoCodeAppType, RenderableNoCodeApp } from 'types/apps/noCodeApps'

interface Props {
  app: Exclude<RenderableNoCodeApp, NoCodeAppMiroBoard>
}

export const DefaultNoCodeAppRenderer = ({ app }: Props) => {
  const { stableId, type } = app

  return (
    <>
      {type === NoCodeAppType.EmbeddedLink && (
        <PenpalParentFrame
          key={stableId}
          appId={stableId}
          allow="clipboard-write"
          sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          className={styles.iframe}
          src={app.url}
        />
      )}

      {type === NoCodeAppType.EmbeddedCode && (
        <PenpalParentFrame
          key={stableId}
          appId={stableId}
          allow="clipboard-write"
          sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          className={styles.iframe}
          srcDoc={app.embeddedCode}
        />
      )}

      {type === NoCodeAppType.CodeUpload && (
        <iframe
          key={stableId}
          allow="clipboard-write"
          sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          className={styles.iframe}
          src={app.url}
        />
      )}

      {type === NoCodeAppType.PageBuilder && <PageBuilderRender app={app} />}
    </>
  )
}
