import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/pfizerDashboard/components/osAssistantWidget/OSAssistantWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const OSAssistantWidget = () => {
  return (
    <WidgetCard className={styles.card}>
      <WppTypography slot="header" type="3xl-heading" className={styles.headerText}>
        The ultimate tool to unlock your inner
        <br />
        strength!
      </WppTypography>

      <WppTypography type="m-body" className={styles.headerBody}>
        Empower your mind and elevate your potential with our OS Assistant
      </WppTypography>

      <Link to="/narrative">
        <WppButton variant="secondary" className={styles.actionButton}>
          Go to OS Assistant
        </WppButton>
      </Link>
    </WidgetCard>
  )
}
