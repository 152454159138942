import { WppSelect, WppListItem } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, Ref, ReactNode, ComponentProps } from 'react'

import styles from 'components/common/select/Select.module.scss'
import { useSelectDropdownConfig } from 'components/common/select/utils'
import { useCommonLabelProps } from 'components/common/utils'

export interface SelectOption {
  label: string
  value: string
}

export interface SelectProps<T extends Record<string, any>> extends ComponentProps<typeof WppSelect> {
  options: T[]
  isOptionDisabled?: (option: T) => boolean
  getOptionValue?: (option: T) => string | number
  getOptionLabel?: (option: T) => string
  renderOptionContent?: (option: T) => ReactNode
  'data-testid'?: string
}

export const Select = forwardRef(function Select<T extends Record<string, any>>(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    renderOptionContent,
    isOptionDisabled = () => false,
    'data-testid': dataTestId,
    labelConfig,
    labelTooltipConfig,
    dropdownConfig,
    className,
    ...rest
  }: SelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const dropdownConfigInner = useSelectDropdownConfig(dropdownConfig)

  return (
    <WppSelect
      ref={ref}
      {...rest}
      {...labelProps}
      className={clsx(styles.select, className)}
      dropdownPosition={dropdownPosition}
      dropdownConfig={dropdownConfigInner}
      data-testid={dataTestId}
    >
      {options.map(option => {
        const optionValue = getOptionValue(option)

        return (
          <WppListItem key={optionValue} value={optionValue} disabled={isOptionDisabled(option)}>
            {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
          </WppListItem>
        )
      })}
    </WppSelect>
  )
}) as <T extends Record<string, any>>(props: SelectProps<T>) => JSX.Element
