import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { usePatchWorkfrontTask } from 'api/workfront/queries/usePatchWorkfrontTask'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ReactComponent as IconCalendar } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/calendar.svg'
import IconPencil from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/pencil.svg'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/projectRow/ProjectRow.module.scss'
import {
  AssignmmentDto,
  ProjectDto,
  ProjectProgress,
  ProjectStatus,
  Categories,
} from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjects/sectionProjectsMockData'
import { useAdobeExpress } from 'pages/home/components/pitch/playgroundDashboard/hooks/useAdobeExpress'
import { useOverlayContext } from 'pages/home/components/pitch/playgroundDashboard/hooks/useOverlayContext'
import { useWorkfrontToken } from 'pages/home/components/pitch/playgroundDashboard/hooks/useWorkfrontToken'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'
import { RadioSelect } from 'pages/playground/components/radioSelect/RadioSelect'

interface Props {
  assignment: AssignmmentDto
  project: ProjectDto
  category: Categories
  roundedTop: boolean
  roundedBottom: boolean
  borderBottom: boolean
  isIssue?: boolean
}

const BackgroundClasses = {
  [Categories.ALL]: 'green',
  [Categories.TASKS]: 'green',
  [Categories.APPROVALS]: 'blue',
  [Categories.REQUESTS]: 'blue2',
}

const ProjectProgressText = {
  [ProjectProgress.ASSEMBLE]: 'ASSEMBLE',
  [ProjectProgress.EXPLORE]: 'EXPLORE',
  [ProjectProgress.CREATE]: 'CREATE',
}

export const ProjectRow = ({ assignment, category, project, roundedTop, roundedBottom, borderBottom }: Props) => {
  const { initAndOpen } = useAdobeExpress()
  const { setVisible } = useOverlayContext()

  const { token } = useWorkfrontToken()
  const queryClient = useQueryClient()

  const showBriefingTool = () => {
    window.parent.postMessage({ type: 'message', activeTaskId: assignment.task.ID }, '*')
    setVisible()
  }

  const formatDate = (dateString: string) => {
    // Hack for invalid date from workfront
    const dateStringTmp = dateString.substring(0, dateString.lastIndexOf(':'))
    const date = new Date(dateStringTmp)
    const monthName = date.toLocaleString('default', { month: 'long' })
    const day = date.getDate()
    return `Due ${monthName} ${day}`
  }

  const [patchTaskEnabled, setPatchTaskEnabled] = useState(false)
  const [patchLoading, setPatchLoading] = useState(false)
  const [status, setStatus] = useState(assignment.task.status)

  const { response: responsePatch } = usePatchWorkfrontTask({
    params: { token: token, status: status, taskId: assignment.task.ID },
    enabled: patchTaskEnabled,
  })

  useEffect(() => {
    if (!patchTaskEnabled) return
    setPatchLoading(false)
    setPatchTaskEnabled(false)
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] })
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_PROJECTS] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsePatch])

  const changeStatus = (status: ProjectStatus) => {
    setStatus(status)
    setPatchTaskEnabled(true)
    setPatchLoading(true)
  }

  return (
    <div
      className={clsx(
        styles.flexBaseWidth,
        styles.row,
        styles[BackgroundClasses[category]],
        roundedTop && styles.roundedTop,
        roundedBottom && styles.roundedBottom,
      )}
    >
      <Flex
        className={clsx(borderBottom && styles.borderBottom, styles.paddingBottom)}
        align="center"
        gap={0}
        justify="between"
      >
        <div>
          <div className={stylesMain.blackH3}>{assignment.task.name}</div>
          <div className={stylesMain.blackPMobile}>{project.name}</div>
          <Flex className={stylesMain.mt16} align="start" gap={0}>
            <div className={styles.stepMargin}>
              <RadioSelect selectedIndex={assignment.task.milestone} length={6} disabled />
            </div>
            <div className={clsx(stylesMain.blackPMobileBold)}>{ProjectProgressText[assignment.task?.milestone]}</div>
          </Flex>
          <Flex className={stylesMain.mt20} align="start" gap={12}>
            <div>
              <IconCalendar height={24} />
            </div>
            <div className={clsx(stylesMain.blackPMobileBold)}>{formatDate(project.plannedStartDate)}</div>
          </Flex>
        </div>

        <div>
          <div>
            {assignment.task.status === ProjectStatus.NEW && (
              <div className={stylesMain['text-right']} onClick={() => changeStatus(ProjectStatus.IN_PROGRESS)}>
                <Button
                  text="Change status to in Progress"
                  variant="tertiary"
                  iconRight={patchLoading ? <WppSpinner /> : null}
                />
              </div>
            )}
            {assignment.task.status === ProjectStatus.IN_PROGRESS && (
              <div className={stylesMain['text-right']} onClick={() => changeStatus(ProjectStatus.COMPLETED)}>
                <Button
                  text="Change status to Done"
                  variant="tertiary"
                  iconRight={patchLoading ? <WppSpinner /> : null}
                />
              </div>
            )}

            {assignment.task.name === 'Experience Brief' && (
              <div className={clsx(stylesMain['text-right'], stylesMain.mt10)} onClick={() => showBriefingTool()}>
                <Button text="Marketing Opportunity Brief" variant="black" iconLeft={<img src={IconPencil} />} />
              </div>
            )}

            {assignment.task.name === 'Experience idea concept' && (
              <div className={stylesMain['text-right']}>
                <Link to="https://playground.wppopen.com/p/8739b0e0-c9cb-4ee5-9f99-b0a3b860a82c/667024f0-b04e-4600-9bfe-49c1b06582a5/moodboard?instanceId=651079d5-38ed-4933-97b9-2e80ff6a5adf">
                  <Button text="Open collaboration board" variant="black" iconLeft={<img src={IconPencil} />} />
                </Link>
                <Flex className={stylesMain.mt10} gap={12}>
                  <div onClick={() => showBriefingTool()}>
                    <Button text="View Brief" variant="tertiary" />
                  </div>
                  <Link to={`/workfront?taskId=${assignment.task.ID}`}>
                    <Button text="Open Task" variant="tertiary" />
                  </Link>
                </Flex>
              </div>
            )}

            {assignment.task.name === 'Creative experience toolkit' && (
              <div className={stylesMain['text-right']}>
                <div onClick={() => initAndOpen()}>
                  <Button text="Prepare Production Assets" variant="black" iconLeft={<img src={IconPencil} />} />
                </div>
                <Flex className={stylesMain.mt10} gap={12}>
                  <Link to="https://playground.wppopen.com/p/8739b0e0-c9cb-4ee5-9f99-b0a3b860a82c/667024f0-b04e-4600-9bfe-49c1b06582a5/moodboard?instanceId=651079d5-38ed-4933-97b9-2e80ff6a5adf">
                    <Button text="View collaboration board" variant="tertiary" />
                  </Link>
                  <div onClick={() => showBriefingTool()}>
                    <Button text="View Brief" variant="tertiary" />
                  </div>
                </Flex>
              </div>
            )}
          </div>
        </div>
      </Flex>
    </div>
  )
}
