import TeamMember1ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember1.png'
import TeamMember2ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember2.png'
import TeamMember3ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember3.png'
import TeamMember4ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember4.png'
import TeamMember5ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember5.png'
import TeamMember6ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember6.png'
import TeamMember7ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember7.png'
import TeamMember8ImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/assets/teamMember8.png'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Wendy Siew',
    role: 'Managing Director EssenceMedia Com Japan',
    profileImgSrc: TeamMember1ImgSrc,
  },
  {
    name: 'Kenshi Arai',
    role: 'MediaX Client Lead',
    profileImgSrc: TeamMember2ImgSrc,
  },
  {
    name: 'Kenichiro',
    role: 'Investment Director GroupM Japan',
    profileImgSrc: TeamMember3ImgSrc,
  },
  {
    name: 'Ryoko Maeta',
    role: 'MediaX Offline Media Lead',
    profileImgSrc: TeamMember4ImgSrc,
  },
  {
    name: 'Hiroki Ishikura',
    role: 'MediaX Analytics',
    profileImgSrc: TeamMember5ImgSrc,
  },

  {
    name: 'Ann Sakai',
    role: 'MediaX Strategic Localization',
    profileImgSrc: TeamMember6ImgSrc,
  },
  {
    name: 'Michael Estifa',
    role: 'MediaX VW Activation Planner',
    profileImgSrc: TeamMember7ImgSrc,
  },
  {
    name: 'Noel Magee',
    role: 'Media Planning Executive',
    profileImgSrc: TeamMember8ImgSrc,
  },
]
