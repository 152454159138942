import {
  ChildHierarchyLevelType,
  MayBeNull,
  NavigationTree,
  OpenAppHandler,
  SelectedWorkspace,
  SelectedWorkspaceItem,
} from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import { queryClient } from 'providers/osQueryClient/utils'
import { getAppInstanceUrl } from 'utils/navigation'
import { resolveActiveHierarchy } from 'utils/workspace'

export const resolveContextHierarchy = ({
  activeWorkspaceId,
  navigationTree,
}: {
  activeWorkspaceId: MayBeNull<string>
  navigationTree: NavigationTree
}): MayBeNull<SelectedWorkspace> => {
  if (!activeWorkspaceId) {
    return null
  }

  const activeHierarchy = resolveActiveHierarchy({
    validHierarchyId: activeWorkspaceId,
    navigationTree,
  })

  return {
    azId: activeWorkspaceId,
    mapping: Object.fromEntries(
      activeHierarchy.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : activeHierarchy[index - 1],
          navigationTree,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTree,
}: {
  azId: string
  parentAzId?: string
  navigationTree: NavigationTree
}): SelectedWorkspaceItem => ({
  azId,
  name: navigationTree.mapping[azId].name,
  type: navigationTree.mapping[azId].type as ChildHierarchyLevelType,
  parentAzId,
})

export const useOpenApp = (): OpenAppHandler => {
  const navigate = useNavigate()

  return useStableCallback(async (appInstanceId: string) => {
    const appUrl = getAppInstanceUrl({ id: appInstanceId })

    await queryClient.resetQueries({ queryKey: [ApiQueryKeys.APP_INSTANCE, { id: appInstanceId }] })

    navigate(appUrl)
  })
}
