import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/components/teamCard/TeamCard.module.scss'
import { TeamMember } from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/components/teamMember/TeamMember'
import { Team } from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/teamsMock'

interface Props {
  team: Team
}

export const TeamCard = ({ team }: Props) => (
  <div className={styles.haleonTeamCard}>
    <WppTypography type="m-strong" className={styles.haleonTitle}>
      {team.name}
    </WppTypography>
    <Flex direction="column" gap={20}>
      {team.members.map((member, id) => (
        <TeamMember member={member} key={id} />
      ))}
    </Flex>
  </div>
)
