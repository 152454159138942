import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconStep } from 'pages/playground/assets/icons/step-indicator.svg'
import styles from 'pages/playground/components/radioSelect/RadioSelect.module.scss'

interface Props {
  selectedIndex: number | null
  disabled?: boolean
  onSelectIndex?: (index: number) => void
  length?: number
}

export const RadioSelect = ({ selectedIndex, onSelectIndex, length = 5, disabled = false }: Props) => {
  const values = Array.from({ length: length }, (_, i) => i + 1)
  return (
    <Flex inline align="center" justify="center" gap={0} className={styles.container}>
      {values.map((el, idx) => {
        return disabled ? (
          <Flex key={idx} inline align="center" justify="center" gap={0} className={styles.containerOuterDisabled}>
            <div
              className={clsx(
                selectedIndex && selectedIndex > el ? styles.buttonIconDisabled : styles.buttonDisabled,
                selectedIndex === el && styles.activeDisabled,
              )}
              onClick={() => onSelectIndex && onSelectIndex(el)}
            >
              {selectedIndex && selectedIndex > el ? <IconStep /> : el}
            </div>
            <div className={styles.lineDisabled} />
          </Flex>
        ) : (
          <Flex key={idx} inline align="center" justify="center" gap={0} className={styles.containerOuter}>
            <div
              className={clsx(styles.button, selectedIndex === el && styles.active)}
              onClick={() => onSelectIndex && onSelectIndex(el)}
            >
              {selectedIndex === el ? '' : el}
            </div>
            <div className={styles.line} />
          </Flex>
        )
      })}
    </Flex>
  )
}
