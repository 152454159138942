import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import {
  firstRow,
  secondRow,
} from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionWorkday/sectionWorkdayMockData'
import { WorkdayCard } from 'pages/home/components/pitch/playgroundDashboard/components/workdayCard/WorkdayCard'
import { WorkdayConnect } from 'pages/home/components/pitch/playgroundDashboard/components/workdayConnect/WorkdayConnect'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SectionWorkday = () => {
  return (
    <div>
      <Flex align="start" gap={80}>
        <div className={stylesMain['w-3/5']}>
          <div className={stylesMain.black2xl}>Your Workday</div>
          <div className={clsx(stylesMain.blackH4, stylesMain.preWrap, stylesMain.mt24)}>
            {
              'Earn as you learn! Check out all the ways you can \ngain CelebrateYou points by learning and \nconnecting with fellow marketers.'
            }
          </div>
          <div className={stylesMain.mt24}>
            <Button
              text="See More Recommendations"
              variant="secondary"
              iconRight={<WppIconArrow direction="right" color="white" />}
            />
          </div>

          <Flex className={stylesMain.mt60} align="center" gap={28}>
            {firstRow.map((item, index) => (
              <div key={index} className={stylesMain['w-1/3']}>
                <WorkdayCard title={item.title} points={item.points} caption={item.caption}>
                  <img src={item.img} alt="" />
                </WorkdayCard>
              </div>
            ))}
          </Flex>
          {/* Row2 */}
          <Flex className={stylesMain.mt42} align="center" gap={28}>
            {secondRow.map((item, index) => (
              <div key={index} className={stylesMain['w-1/3']}>
                <WorkdayCard title={item.title} points={item.points} caption={item.caption}>
                  <img src={item.img} alt="" />
                </WorkdayCard>
              </div>
            ))}
          </Flex>
        </div>

        <div className={stylesMain['w-2/5']}>
          <WorkdayConnect />
        </div>
      </Flex>
    </div>
  )
}
