export interface Member {
  name: string
  role: string
  profileImg: string
}

export interface Team {
  country: string
  members: Member[]
}

export const teams: Team[] = [
  {
    country: 'Germany',
    members: [
      {
        name: 'Eguen Holler',
        role: 'Marketing Lead',
        profileImg: 'https://i.pravatar.cc/150?img=12',
      },
      {
        name: 'Betty Berry',
        role: 'Design Lead',
        profileImg: 'https://i.pravatar.cc/150?img=13',
      },
      {
        name: 'Anna Plus',
        role: 'UX Designer',
        profileImg: 'https://i.pravatar.cc/150?img=16',
      },
      {
        name: 'Holly Distel',
        role: 'UI Designer',
        profileImg: 'https://i.pravatar.cc/150?img=9',
      },
    ],
  },
  {
    country: 'Austria',
    members: [
      {
        name: 'Howard Brook',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=33',
      },
      {
        name: 'Hank Howard',
        role: 'Design Lead',
        profileImg: 'https://i.pravatar.cc/150?img=52',
      },
      {
        name: 'Paul Molly',
        role: 'UX Designer',
        profileImg: 'https://i.pravatar.cc/150?img=21',
      },
      {
        name: 'Peter Parker',
        role: 'UI Designer',
        profileImg: 'https://i.pravatar.cc/150?img=51',
      },
    ],
  },
  {
    country: 'Czech Republic',
    members: [
      {
        name: 'Alex Luis Felicio',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=57',
      },
      {
        name: 'Peter Paul Takil',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=59',
      },
      {
        name: 'Yousaf  Harps',
        role: 'Marketing',
        profileImg: 'https://i.pravatar.cc/150?img=32',
      },
      {
        name: 'Rob Akram',
        role: 'Business Analyst',
        profileImg: 'https://i.pravatar.cc/150?img=56',
      },
    ],
  },
]
