import { BeiersdorfWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type BeiersdorfWidget = Omit<Widget, 'name'> & { name: BeiersdorfWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'beiersdorf_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [BeiersdorfWidgetType.IMAGE]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [BeiersdorfWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [BeiersdorfWidgetType.CALENDAR]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [BeiersdorfWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [BeiersdorfWidgetType.TEAM]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const beiersdorfWidgets: BeiersdorfWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'beiersdorf_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[BeiersdorfWidgetType.IMAGE],
    ...commonWidgetsConfig,
    name: BeiersdorfWidgetType.IMAGE,
    id: 'beiersdorf_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[BeiersdorfWidgetType.USER],
    ...commonWidgetsConfig,
    name: BeiersdorfWidgetType.USER,
    id: 'beiersdorf_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[BeiersdorfWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: BeiersdorfWidgetType.CALENDAR,
    id: 'beiersdorf_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[BeiersdorfWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: BeiersdorfWidgetType.PROCESS,
    id: 'beiersdorf_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[BeiersdorfWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: BeiersdorfWidgetType.TEAM,
    id: 'beiersdorf_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'beiersdorf_7',
    createdAt: '',
    updatedAt: '',
  },
]
