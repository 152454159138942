import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/valueRetailDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.userCard}>
      <Flex justify="between" align="center">
        <WppTypography type="m-midi">{`Hello ${userDetails.firstname} ${userDetails.lastname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-midi" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <div className={styles.notificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-midi">Ana Dias&nbsp;</WppTypography>
            <WppTypography type="s-body">has assigned Brief for Competitor Insights to you</WppTypography>
          </div>
          <div className={styles.notificationsDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:46
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-midi">Laura Opara&nbsp;</WppTypography>
            <WppTypography type="s-body">has approved Brief for&nbsp;</WppTypography>
            <WppTypography type="s-midi">Competitor Insights </WppTypography>
          </div>
          <div className={styles.notificationsDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-midi">Adele Santarelli&nbsp;</WppTypography>
        <WppTypography type="s-body">has assigned&nbsp;</WppTypography>
        <WppTypography type="s-midi">Prepare Local Market&nbsp;</WppTypography>
        <WppTypography type="s-body">briefing for&nbsp;</WppTypography>
        <WppTypography type="s-midi">2024 Planning&nbsp;</WppTypography>
        <WppTypography type="s-body">to you</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:10
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
