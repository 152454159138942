import { WppCard } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { SvgImageLoadError } from 'components/svg/SvgImageLoadError'
import styles from 'pages/home/systemWidgets/newsWidget/newsPieceCard/standardNewsPieceCard/StandardNewsPieceCard.module.scss'
import { NewsPiece } from 'types/news'

export const StandardNewsPieceCard = ({ newsPiece }: { newsPiece: NewsPiece }) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const { banner_image: bannerImage, title } = newsPiece
  const isImagePlaceholderVisisble = isImageLoadError || !bannerImage

  return (
    <WppCard className={styles.root}>
      {isImagePlaceholderVisisble ? (
        <Flex align="center" justify="center" className={styles.imagePlaceholder}>
          <SvgImageLoadError />
        </Flex>
      ) : (
        <img className={styles.image} src={bannerImage} alt="Background" onError={() => setIsImageLoadError(true)} />
      )}
      <div
        className={clsx(styles.textContainer, {
          [styles.imagePlaceholderTextContainer]: isImagePlaceholderVisisble,
        })}
      >
        <Truncate lines={3} type="m-strong" className={styles.text}>
          {title}
        </Truncate>
      </div>
    </WppCard>
  )
}
