import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/perrigoDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.perrigoCard}>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.perrigoWorkgroupTitle}>
        Welcome to Perrigo OS
      </WppTypography>
      <Flex gap={4} direction="column">
        <Flex gap={4}>
          <WppTypography type="s-body">Your Market:</WppTypography>
          <WppTypography type="s-strong">UK</WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppTypography type="s-body">Your Division:</WppTypography>
          <WppTypography type="s-strong">Leadership & Governance</WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppTypography type="s-body">Your Role:</WppTypography>
          <WppTypography type="s-strong">Strategy</WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppTypography type="s-body">Category:</WppTypography>
          <WppTypography type="s-strong">Skincare</WppTypography>
        </Flex>
      </Flex>
      <WppTypography type="s-strong" className={styles.perrigoRecentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <Flex gap={14} direction="column">
        <div className={styles.perrigoNotificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Caroline Banquet&nbsp;</WppTypography>
              <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
              <WppTypography type="s-strong">2024 Physiomer</WppTypography>
            </div>
            <div className={styles.perrigoNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:46
            </WppTypography>
          </div>
        </div>
        <div className={styles.perrigoNotificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Francis Turner&nbsp;</WppTypography>
              <WppTypography type="s-body">updated task&nbsp;</WppTypography>
              <WppTypography type="s-strong">Review creatives for 2024&nbsp;</WppTypography>
              <WppTypography type="s-strong">Compeed Spots&nbsp;</WppTypography>
              <WppTypography type="s-body">status to &quot;Approved&quot;</WppTypography>
            </div>
            <div className={styles.perrigoNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:34
            </WppTypography>
          </div>
        </div>
        <div className={styles.perrigoNotificationCardOpened}>
          <WppTypography type="s-strong">Jamie Willard&nbsp;</WppTypography>
          <WppTypography type="s-body">assigned&nbsp;</WppTypography>
          <WppTypography type="s-strong">Prepare Local Market briefing for Compeed Patch launch&nbsp;</WppTypography>
          <WppTypography type="s-body">to you</WppTypography>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 11:10
            </WppTypography>
          </div>
        </div>
        <div className={styles.perrigoNotificationCardOpened}>
          <WppTypography type="s-strong">Jamie Willard&nbsp;</WppTypography>
          <WppTypography type="s-body">invited you to project&nbsp;</WppTypography>
          <WppTypography type="s-strong">Compeed media workshop</WppTypography>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 11:00
            </WppTypography>
          </div>
        </div>
      </Flex>
    </WidgetCard>
  )
}
