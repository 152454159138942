import { ValueRetailWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type ValueRetailWidget = Omit<Widget, 'name'> & { name: ValueRetailWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'valueRetail_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [ValueRetailWidgetType.PROCESS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [ValueRetailWidgetType.INTRO]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [ValueRetailWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 9,
  },
  [ValueRetailWidgetType.EXCELLENCE_HUB]: {
    gridRowStart: 3,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [ValueRetailWidgetType.TEAM]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [ValueRetailWidgetType.MEDIA_EFFECTIVENESS]: {
    gridRowStart: 3,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const valueRetailWidgets: ValueRetailWidget[] = [
  {
    ...gridConfig[ValueRetailWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.PROCESS,
    id: 'valueRetail_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[ValueRetailWidgetType.INTRO],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.INTRO,
    id: 'valueRetail_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[ValueRetailWidgetType.USER],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.USER,
    id: 'valueRetail_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'valueRetail_8',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[ValueRetailWidgetType.MEDIA_EFFECTIVENESS],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.MEDIA_EFFECTIVENESS,
    id: 'valueRetail_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[ValueRetailWidgetType.EXCELLENCE_HUB],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.EXCELLENCE_HUB,
    id: 'valueRetail_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[ValueRetailWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: ValueRetailWidgetType.TEAM,
    id: 'valueRetail_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'valueRetail_4',
    createdAt: '',
    updatedAt: '',
  },
]
