import Calendar from 'pages/playground/pages/profile/assets/icons/overview/calendar.svg'
import Campaign from 'pages/playground/pages/profile/assets/icons/overview/campaign.svg'
import Paste from 'pages/playground/pages/profile/assets/icons/overview/paste.svg'
import People from 'pages/playground/pages/profile/assets/icons/overview/people.svg'
import User from 'pages/playground/pages/profile/assets/icons/overview/user.svg'

export const achievements = [
  {
    icon: User,
    text: 'Drafting Customer \nSpecifications',
  },
  {
    icon: Calendar,
    text: 'Cost Scheduling for \nComplex Projects',
  },
  {
    icon: Paste,
    text: 'Packaging Work Definitions',
  },
  {
    icon: People,
    text: 'Resolving Team Conflicts',
  },
  {
    icon: Campaign,
    text: 'Defining Objectives for \nDigital Campaigns',
  },
]
export const growths = [
  {
    value: 36,
    text: 'Launch a New Brand / \nBrand Extension for a \nMarket',
  },
  {
    value: 36,
    text: 'Develop a Business Case',
  },
  {
    value: 48,
    text: 'Coffee Brand Experiences',
  },
  {
    value: 36,
    text: 'BP for On Brand at\nGlobal Level',
  },
  {
    value: 30,
    text: 'SKU Optimization Project',
  },
]
