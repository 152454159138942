import { NestleWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type NestleWidget = Omit<Widget, 'name'> & { name: NestleWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'nestle_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [NestleWidgetType.IMAGE]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [NestleWidgetType.WELCOME]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 2,
    gridColumnEnd: 17,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 2,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [NestleWidgetType.CALENDAR]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [NestleWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [NestleWidgetType.WRIKE]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
  [NestleWidgetType.TEAM]: {
    gridRowStart: 7,
    gridColumnStart: 1,
    gridRowEnd: 9,
    gridColumnEnd: 9,
  },
  [NestleWidgetType.CENTRE_OF_EXCELLENCE]: {
    gridRowStart: 7,
    gridColumnStart: 9,
    gridRowEnd: 9,
    gridColumnEnd: 17,
  },
}

export const nestleWidgets: NestleWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'nestle_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.IMAGE],
    ...commonWidgetsConfig,
    name: NestleWidgetType.IMAGE,
    id: 'nestle_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.WELCOME],
    ...commonWidgetsConfig,
    name: NestleWidgetType.WELCOME,
    id: 'nestle_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'nestle_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: NestleWidgetType.CALENDAR,
    id: 'nestle_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: NestleWidgetType.PROCESS,
    id: 'nestle_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.WRIKE],
    ...commonWidgetsConfig,
    name: NestleWidgetType.WRIKE,
    id: 'nestle_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: NestleWidgetType.TEAM,
    id: 'nestle_8',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[NestleWidgetType.CENTRE_OF_EXCELLENCE],
    ...commonWidgetsConfig,
    name: NestleWidgetType.CENTRE_OF_EXCELLENCE,
    id: 'nestle_9',
    createdAt: '',
    updatedAt: '',
  },
]
