export interface CentreOfExcellenceSection {
  title: string
  links: CentreOfExcellenceLink[]
}

export interface CentreOfExcellenceLink {
  label: string
  url: string
}

export const centreOfExcellenceSections: CentreOfExcellenceSection[] = [
  {
    title: 'Coffee & Confectionary',
    links: [{ label: 'Emerging Trends in CPG', url: '#' }],
  },
  {
    title: 'Nutrition & Dairy + Food + CPW',
    links: [{ label: 'Humanising Health', url: '#' }],
  },
  {
    title: 'Petcare + Waters',
    links: [{ label: 'The power of AI in Petcare', url: '#' }],
  },
  {
    title: 'Professional + NHS',
    links: [{ label: 'Media Principles for B2B', url: '#' }],
  },
  {
    title: 'Nestle Media Best Practices',
    links: [
      { label: 'Nestlé Media Principles', url: '#' },
      { label: 'Nestlé Hygiene BPIs', url: '#' },
      { label: 'Nestlé Golden Rules', url: '#' },
    ],
  },
]
