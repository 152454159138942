import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { SvgProjectTimelineOverview } from 'pages/home/components/pitch/svg/SvgProjectTimelineOverview'
import styles from 'pages/home/components/pitch/toyotaDashboard/components/projectTimelineWidget/ProjectTimelineWidget.module.scss'

export const ProjectTimelineWidget = () => (
  <WppCard className={styles.widget}>
    <WppTypography type="l-strong">Project timeline overview</WppTypography>
    <SvgProjectTimelineOverview className={styles.svg} />
  </WppCard>
)
