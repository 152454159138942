import {
  WppActionButton,
  WppTypography,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { StatisticCard } from 'pages/home/components/pitch/cheeriosDashboard/components/mediaEffectiveness/components/statisticCard/StatisticCard'
import styles from 'pages/home/components/pitch/cheeriosDashboard/components/mediaEffectiveness/MediaEffectiveness.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MediaEffectiveness = () => (
  <WidgetCard className={styles.card}>
    <WppTypography slot="header" type="m-strong">
      Media Effectiveness
    </WppTypography>

    <Link slot="actions" to="#">
      <WppActionButton variant="secondary">All Dashboards</WppActionButton>
    </Link>

    <WppSegmentedControl value="meta" size="s">
      <WppSegmentedControlItem value="meta">Meta</WppSegmentedControlItem>
      <WppSegmentedControlItem value="youtube">YouTube</WppSegmentedControlItem>
      <WppSegmentedControlItem value="instagram">Instagram</WppSegmentedControlItem>
      <WppSegmentedControlItem value="twitter">Twitter</WppSegmentedControlItem>
      <WppSegmentedControlItem value="tiktok">TikTok</WppSegmentedControlItem>
    </WppSegmentedControl>

    <Flex direction="row" gap={16} className={styles.statisticsWrapper}>
      <StatisticCard title="Cost" value="$17,345" prev30DaysValue={{ changePercentage: 24, value: '$12,212' }} />
      <StatisticCard title="Clicks" value="20,264" prev30DaysValue={{ changePercentage: 21, value: '18,176' }} />
      <StatisticCard
        title="Total Conversions"
        value="1,647"
        prev30DaysValue={{ changePercentage: -5, value: '1,565' }}
      />
    </Flex>
  </WidgetCard>
)
