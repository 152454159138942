import { MayBeNull, TenantConfigNavigationType } from '@wpp-open/core'
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'

import { useHierarchyNodeAppInstances } from 'components/hierarchyAppsManagement/utils'
import { useStableCallback } from 'hooks/useStableCallback'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useData } from 'providers/data/DataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { AppInstanceAssignmentType, AppInstanceShort } from 'types/apps/appInstances'
import { isNavigationTypeEnabled } from 'utils/navigation'

export interface NavigationMenuControllerContextValue {
  isCustomMenuAppInstancesLoading: boolean
  customMenuAppInstances: AppInstanceShort[]
  selectedNavigationNodeId: string
  selectedCustomMenuGroupId: MayBeNull<string>
  selectNavigationNode: (nodeId: string) => void
  selectCustomMenuGroup: (groupId: string) => void
}

const NavigationMenuControllerContext = createContext<NavigationMenuControllerContextValue>(null!)
export const useNavigationMenuController = () => useContext(NavigationMenuControllerContext)

export const NavigationMenuControllerProvider = ({ children }: PropsWithChildren<{}>) => {
  const { preselectedHierarchyNodeId, appData } = useOsState()
  const { navigationTree } = useData()
  const { currentTenant } = useTenantAndUserData()
  const { activeNodeId } = useHeaderNavigation()

  const { appInstance } = appData

  const [selectedNavigationNodeId, setSelectedNavigationNodeId] = useState(
    () => preselectedHierarchyNodeId || activeNodeId,
  )

  const isCustomMenuActive = selectedNavigationNodeId === navigationTree.rootId
  const [selectedCustomMenuGroupId, setSelectedCustomMenuGroupId] = useState(() =>
    appInstance?.group && isCustomMenuActive ? appInstance.group.id : null,
  )

  const isCustomNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  const { data: customMenuAppInstances, isLoading: isCustomMenuAppInstancesLoading } = useHierarchyNodeAppInstances({
    params: {
      assignmentId: navigationTree.rootId,
      assignmentType: AppInstanceAssignmentType.Custom,
    },
    enabled: isCustomNavigationEnabled && isCustomMenuActive,
  })

  const selectNavigationNode = useCallback((nodeId: string) => {
    setSelectedNavigationNodeId(nodeId)
    setSelectedCustomMenuGroupId(null)
  }, [])

  const selectCustomMenuGroup = useStableCallback((groupId: string) => {
    setSelectedNavigationNodeId(navigationTree.rootId)
    setSelectedCustomMenuGroupId(groupId)
  })

  const value: NavigationMenuControllerContextValue = {
    isCustomMenuAppInstancesLoading,
    customMenuAppInstances,
    selectedNavigationNodeId: selectedNavigationNodeId,
    selectedCustomMenuGroupId: selectedCustomMenuGroupId,
    selectNavigationNode,
    selectCustomMenuGroup,
  }

  return <NavigationMenuControllerContext.Provider value={value}>{children}</NavigationMenuControllerContext.Provider>
}
