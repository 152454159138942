import { MayBeNull, OpenMenuHandler } from '@wpp-open/core'
import { useState } from 'react'

import { useStableCallback } from 'hooks/useStableCallback'
import { useData } from 'providers/data/DataContext'
import { OsStateContextValue } from 'providers/osState/OsStateProvider'
import { isWorkspaceIdValid, WorkspaceType } from 'utils/workspace'

type NavigationState = Pick<
  OsStateContextValue,
  'isNavigationOpen' | 'preselectedHierarchyNodeId' | 'openNavigation' | 'closeNavigation'
>

export const useNavigationState = (): NavigationState => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [preselectedHierarchyNodeId, setPreselectedHierarchyNodeId] = useState<MayBeNull<string>>(null)
  const { navigationTree } = useData()

  const openNavigation: OpenMenuHandler = useStableCallback((preselectedNodeAzId = null) => {
    const isWorkspaceValid = isWorkspaceIdValid({
      workspaceAzId: preselectedNodeAzId,
      navigationTree,
      workspaceType: WorkspaceType.All,
    })

    setIsNavigationOpen(true)

    if (isWorkspaceValid) {
      setPreselectedHierarchyNodeId(preselectedNodeAzId)
    } else {
      console.warn('openNavigation: Invalid preselectedNodeAzId. Opening navigation without preselected workspace.')
      setPreselectedHierarchyNodeId(null)
    }
  })

  const closeNavigation = useStableCallback(() => {
    setIsNavigationOpen(false)
    setPreselectedHierarchyNodeId(null)
  })

  return {
    isNavigationOpen,
    preselectedHierarchyNodeId,
    openNavigation,
    closeNavigation,
  }
}
