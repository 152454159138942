import { HierarchyLevelType } from '@wpp-open/core'

import { Permission } from 'constants/permission'
import { environment } from 'environment'
import { MicroAppLibraryType, MicroAppType, SystemAppCode, SystemMicroAppConfig } from 'types/apps/microApps'
import { NoCodeAppType } from 'types/apps/noCodeApps'

export const LOCAL_APP_BASE_URL = 'local'
export const LOCAL_LEGACY_APP_BASE_URL = 'local-legacy'
export const LOCAL_LEGACY_CONTAINER_ID = 'local-legacy-dom-container'
export const LOCAL_APP_CODE = 'LOCAL'
export const LOCAL_LEGACY_APP_CODE = 'LOCAL_LEGACY'
export const LOCAL_APP_NAME = 'Local App'
export const LOCAL_APP_DEFAULT_URL_CONFIG = '8500/sjs/main'
export const LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG = '8500/main/localApp'

export const systemApps: Record<SystemAppCode, SystemMicroAppConfig> = {
  [SystemAppCode.News]: {
    type: MicroAppType.System,
    name: 'News',
    stableId: SystemAppCode.News,
    osRoute: 'news',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.NEWS_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    permission: null,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.Marketplace]: {
    type: MicroAppType.System,
    name: 'Marketplace',
    stableId: SystemAppCode.Marketplace,
    osRoute: 'marketplace',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.MARKETPLACE_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    permission: Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.Orchestration]: {
    type: MicroAppType.System,
    name: 'Orchestration',
    stableId: SystemAppCode.Orchestration,
    osRoute: 'orchestration',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.ORCHESTRATION_BUNDLE_URL,
    permission: Permission.WPP_ORCHESTRATION_APP_ACCESS,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.DevHub]: {
    type: MicroAppType.System,
    name: 'Dev Hub',
    stableId: SystemAppCode.DevHub,
    osRoute: 'devhub',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.DEVHUB_BUNDLE_URL,
    permission: Permission.WPP_DEVHUB_APP_ACCESS,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.ThemeBuilder]: {
    type: MicroAppType.System,
    name: 'Theme Builder',
    stableId: SystemAppCode.ThemeBuilder,
    osRoute: 'theme-builder',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.THEME_BUILDER_BUNDLE_URL,
    permission: null,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  // [MicroAppType.AudiencesConsumerStub]: {
  //   name: 'Audiences Consumer Stub',
  //   code: ExternalAppCode.AudiencesConsumerStub,
  //   baseUrl: 'audience-consumer-stub',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: 'https://consumer-demo-fe-ch-hulk.os-dev.io/',
  //   permission: Permission.WPP_DATA_STREAM_APP_ACCESS,
  //   workspaceLevels: [HierarchyLevelType.Brand],
  // },
  // [ExternalAppCode.AudiencesProducerStub]: {
  //   name: 'Audiences Producer Stub',
  //   code: ExternalAppCode.AudiencesProducerStub,
  //   baseUrl: 'audience-producer-stub',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: 'https://producer-demo-fe-ch-hulk.os-dev.io/',
  //   permission: Permission.WPP_DATA_STREAM_APP_ACCESS,
  //   workspaceLevels: [HierarchyLevelType.Brand],
  // },
  // [ExternalAppCode.BenefitsRoadmap]: {
  //   name: 'Benefits Roadmap',
  //   code: ExternalAppCode.BenefitsRoadmap,
  //   baseUrl: 'benefits-roadmap',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: 'https://benefits-roadmap-fe-ch-hulk.os-dev.io/',
  //   workspaceLevels: [HierarchyLevelType.Tenant],
  // },
  // [ExternalAppCode.SitecoreAssetsReview]: {
  //   name: 'Sitecore Asset Review',
  //   code: ExternalAppCode.SitecoreAssetsReview,
  //   baseUrl: 'sitecore-assets-review',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: environment.SITECORE_ASSETS_REVIEW_BUNDLE_URL,
  //   permission: Permission.ASSETS_REVIEW_APP_ACCESS,
  //   workspaceLevels: [HierarchyLevelType.Tenant],
  // },
  // [ExternalAppCode.AugmentedOccasions]: {
  //   name: 'Augmented Occasions',
  //   code: ExternalAppCode.AugmentedOccasions,
  //   baseUrl: 'augmented-occasions',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: environment.AUGMENTED_OCCASIONS_BUNDLE_URL,
  //   permission: Permission.AUGMENTED_OCCASIONS_APP_ACCESS,
  //   workspaceLevels: [HierarchyLevelType.Tenant],
  // },
  // [ExternalAppCode.NarrativeApp]: {
  //   name: 'Narrative App',
  //   code: ExternalAppCode.NarrativeApp,
  //   baseUrl: 'narrative',
  //   libraryType: AppLibraryType.SystemJS,
  //   bundleUrl: 'https://narratives-composer-fe-ch-pitch.wppopen.com/main.js',
  //   workspaceLevels: [HierarchyLevelType.Tenant],
  // },
  // [ExternalAppCode.Questionnaire]: {
  //   name: 'Questionnaire',
  //   code: ExternalAppCode.Questionnaire,
  //   baseUrl: 'questionnaire-app',
  //   libraryType: AppLibraryType.SystemJS,
  //   permission: Permission.PREREQUISITES_APP_FORM_VIEW,
  //   bundleUrl: 'https://questionnaire-fe-ch-pitch.wppopen.com/main.js',
  //   workspaceLevels: [HierarchyLevelType.Tenant],
  // },
  [SystemAppCode.ProcessBuilder]: {
    type: MicroAppType.System,
    name: 'Process Builder',
    stableId: SystemAppCode.ProcessBuilder,
    osRoute: 'process',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.PROCESS_BUILDER_BUNDLE_URL,
    permission: null,
    requiredHierarchy: [HierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
}

export const systemAppsList = Object.values(systemApps)

export const categoricalColorIndexByTypeDevHubApps = {
  [NoCodeAppType.EmbeddedLink]: 7,
  [NoCodeAppType.EmbeddedCode]: 7,
  [NoCodeAppType.ExternalLink]: 9,
  [NoCodeAppType.PageBuilder]: 5,
  [NoCodeAppType.MiroBoard]: 4,
  [NoCodeAppType.CodeUpload]: 5,
  [MicroAppType.Native]: 1,
} as const

export const categoricalColorIndexByHierarchyLevelType = {
  [HierarchyLevelType.Tenant]: 4,
  [HierarchyLevelType.Brand]: 9,
  [HierarchyLevelType.Client]: 8,
  [HierarchyLevelType.Market]: 3,
} as const
