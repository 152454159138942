import { WppIconClose, WppActionButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { Rnd } from 'react-rnd'

import { Flex } from 'components/common/flex/Flex'
import DelayUnmount from 'pages/home/components/pitch/playgroundDashboard/components/general/DelayUnmount'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/general/overlay/OverlayManager.module.scss'
import { debounce } from 'pages/home/components/pitch/playgroundDashboard/utils/debounce'

interface OverlayProp {
  onClose: () => void
  content: React.ReactNode
  width?: number
  isVisible?: boolean
}

export const Overlay = forwardRef(({ onClose, content, width, isVisible = true }: OverlayProp, ref) => {
  const [showModal, setShowModal] = useState(true)

  const DEFAULT_OVERLAY_SIZE = {
    width: `${window.innerWidth - 48}px`,
    height: `${window.innerHeight - 63 - 48}px`,
  }

  const DEFAULT_OVERLAY_POSITION = {
    x: 24,
    y: 24,
  }

  const [overlayPosition, setOverlayPosition] = useState(DEFAULT_OVERLAY_POSITION)
  const [overlaySize, setOverlaySize] = useState(
    width ? { ...DEFAULT_OVERLAY_SIZE, width: `${width}px` } : DEFAULT_OVERLAY_SIZE,
  )

  const _isMounted = useRef(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useImperativeHandle(ref, () => ({
    hideModal,
  }))

  const hideModal = () => {
    setShowModal(false)
  }

  return (
    <div>
      <DelayUnmount delayTime={200} show={showModal}>
        <div className={clsx(styles.dragSurface, !isVisible && styles.hidden)}>
          <Rnd
            minWidth={440}
            minHeight={600}
            bounds="parent"
            position={{ x: overlayPosition.x, y: overlayPosition.y }}
            size={{ width: overlaySize.width, height: overlaySize.height }}
            enableUserSelectHack={false}
            onDragStop={(e, d) => {
              setOverlayPosition({ x: d.x, y: d.y })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setOverlaySize({
                width: ref.style.width,
                height: ref.style.height,
              })
              setOverlayPosition({ x: position.x, y: position.y })
            }}
            cancel=".cancel-drag"
            className={styles.overlayRnd}
          >
            <div className={styles.overlay}>
              <Flex className={styles.header} justify="end" align="center" gap={8}>
                <Flex align="center" gap={2}>
                  <WppActionButton variant="secondary" onClick={onClose} className="cancel-drag">
                    <WppIconClose slot="icon-start" color="var(--wpp-grey-color-800)" />
                  </WppActionButton>
                </Flex>
              </Flex>
              {content}
            </div>
          </Rnd>
        </div>
      </DelayUnmount>
    </div>
  )
})

export const showOverlay = (type: string, content: any, width?: number, callback?: () => void) => {
  const ref = createRef()
  const container = document.getElementById('modals')

  const cl = debounce(() => {
    root?.unmount()

    // @ts-ignore
    ref.current?.hideModal && ref.current.hideModal()
    setTimeout(() => {
      if (container) {
        container.innerHTML = ''
      }
    }, 300)
  }, 50)

  const modal = (
    <Overlay
      width={width}
      ref={ref}
      onClose={() => {
        callback && callback()
        cl()
      }}
      content={content}
    />
  )

  const root = (container && createRoot(container)) || null

  if (root) {
    root.render(modal)
  }

  return {
    close: () => cl(),
  }
}
