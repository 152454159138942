import {
  WppTypography,
  WppIconBookContacts,
  WppIconNote,
  WppIconVideoOn,
  WppIconStatisticDocument,
  WppIconDataLine,
  WppIconTick,
  WppIconFolder,
  WppIconTaskList,
  WppIconFavorites,
  WppIconMerge,
  WppIconBranch,
  WppIconCalendar3Days,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/oldElPasoDashboard/components/excellenceHub/ExcellenceHub.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ExcellenceHub = () => (
  <WidgetCard className={styles.card}>
    <WppTypography slot="header" type="m-strong">
      Excellence Hub
    </WppTypography>
    <Flex justify="between" gap={16}>
      <div>
        <div>
          <WppTypography type="s-strong">Learn the Business</WppTypography>
        </div>
        <WppTypography type="xs-body" className={styles.sectionSubtitle}>
          Onboarding & Reference Documents
        </WppTypography>
        <div className={styles.linksContainer}>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconBookContacts color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Onboarding Hub
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconNote color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Historical Monthly Newsletters
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconVideoOn color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Explore our Training Portal
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconStatisticDocument color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Learn About Processes
              </WppTypography>
            </Flex>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <WppTypography type="s-strong">Process and Templates</WppTypography>
        </div>
        <WppTypography type="xs-body" className={styles.sectionSubtitle}>
          Learn our operations
        </WppTypography>
        <div className={styles.linksContainer}>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconDataLine color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Campaign Planning Process
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconFavorites color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Master Deck
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconTick color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Compliance Audit
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconMerge color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Digital Workflow
              </WppTypography>
            </Flex>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <WppTypography type="s-strong">Prepare Deliverables</WppTypography>
        </div>
        <WppTypography type="xs-body" className={styles.sectionSubtitle}>
          Integral documents for execution
        </WppTypography>
        <div className={styles.linksContainer}>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconFolder color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Templates for Execution
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconTaskList color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Update Campaign Tracker
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconBranch color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Cross Brand Projects
              </WppTypography>
            </Flex>
          </Link>
          <Link to="#">
            <Flex className={styles.item} gap={7}>
              <WppIconCalendar3Days color="var(--wpp-grey-color-800)" />
              <WppTypography type="s-body" className={styles.itemText}>
                Campaigns Rollout Dashboards
              </WppTypography>
            </Flex>
          </Link>
        </div>
      </div>
    </Flex>
  </WidgetCard>
)
