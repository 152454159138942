import { MayBeNull } from '@wpp-open/core'

import { useData } from 'providers/data/DataContext'
import { getAppInstanceAppData } from 'providers/osState/utils/appData'
import {
  filterAppInstanceWorkspaceId,
  getInvalidResolvedAppData,
  isValidAppInstanceWorkspace,
} from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppInstanceAssignmentType, AppInstanceFull } from 'types/apps/appInstances'
import { RoutedAppInstanceApp } from 'utils/appInstances'

interface Params {
  appInstance: AppInstanceFull
  app: RoutedAppInstanceApp
  currentBaseUrl: string
  workspaceAzId: string
  additionalContext: MayBeNull<Record<string, any>>
}

export const useWorkspaceAppInstance = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree } = useData()

  const isEnabled = !!params

  const isCustomNavigation = params?.appInstance.assignmentType === AppInstanceAssignmentType.Custom

  // Validate and process workspace data
  if (isEnabled) {
    const { appInstance, app, currentBaseUrl, workspaceAzId, additionalContext } = params
    const filteredWorkspaceAzId = filterAppInstanceWorkspaceId({
      workspaceAzId,
      navigationTree,
    })
    const isWorkspaceValid = isValidAppInstanceWorkspace({
      workspaceAzId: filteredWorkspaceAzId,
      navigationTree,
      app,
    })

    if (isWorkspaceValid) {
      return {
        appData: getAppInstanceAppData({
          app,
          currentBaseUrl,
          activeWorkspaceId: isCustomNavigation ? null : filteredWorkspaceAzId,
          appInstance,
          project: null,
          projectCanvas: null,
          projectPhase: null,
          projectItem: null,
          additionalContext,
          isCustomNavigation: isCustomNavigation,
        }),
        sideEffectNode: null,
      }
    }

    return getInvalidResolvedAppData()
  }

  return null
}
