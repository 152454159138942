import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import processImgSrc from 'assets/pitch/pfizer/Scheme.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => {
  return (
    <Link to="/process/custom">
      <WidgetCard className={styles.card}>
        <div slot="header">
          <WppTypography type="m-strong">The Pfizer Universal Process</WppTypography>
        </div>
        <Flex justify="between" align="center" className={styles.cardContent}>
          <img src={processImgSrc} className={styles.image} alt="" />
          <WppTypography type="l-body" className={styles.description}>
            The Universal Process is a powerful and integrated approach that leverages data and insights to drive
            content development and media activation. By tailoring entry points to specific brand and market needs, it
            enables exceptional outcomes and maximum impact.
          </WppTypography>
        </Flex>
      </WidgetCard>
    </Link>
  )
}
