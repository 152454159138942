import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import NestleDashboardSrc from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/assets/nestleDashboard.png'
import PfizerDashboardSrc from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/assets/pfizerDashboard.png'
import SpotifySrc from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/assets/spotify.png'
import VolkswagenDashboardSrc from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/assets/volkswagenDashboard.png'
import styles from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/ClientTenantWidget.module.scss'

export const ClientTenantWidget = () => {
  const slides = [
    { title: 'Pfizer', dashboardSrc: PfizerDashboardSrc, url: '/pfizer' },
    { title: 'Nestle', dashboardSrc: NestleDashboardSrc, url: '/nestle' },
    { title: 'Spotify', dashboardSrc: SpotifySrc, url: '/spotify' },
    { title: 'Volkswagen', dashboardSrc: VolkswagenDashboardSrc, url: '/volkswagen' },
  ]
  const [activeSlide, setActiveSlide] = useState(0)
  const changeSlide = (slide: number) => {
    setActiveSlide(slide)
  }
  const previeusSlide = activeSlide - 1 < 0 ? slides.length - 1 : activeSlide - 1
  const nextSlide = activeSlide + 1 > slides.length - 1 ? 0 : activeSlide + 1

  const slidesView = () => {
    return (
      <>
        <Link to={slides[previeusSlide].url}>
          <Flex>
            <img className={styles.slide} src={slides[previeusSlide].dashboardSrc} />
          </Flex>
        </Link>
        <Link to={slides[activeSlide].url}>
          <Flex>
            <img className={clsx(styles.slide, styles.activeSlide)} src={slides[activeSlide].dashboardSrc} />
          </Flex>
        </Link>
        <Link to={slides[nextSlide].url}>
          <Flex>
            <img className={styles.slide} src={slides[nextSlide].dashboardSrc} />
          </Flex>
        </Link>
      </>
    )
  }

  const controlSlide = () => {
    return (
      <Flex className={styles.controls} gap={8}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={clsx(styles.control, index === activeSlide && styles.activeControl)}
            onClick={() => changeSlide(index)}
          />
        ))}
      </Flex>
    )
  }

  const interval = setInterval(() => {
    if (activeSlide + 1 <= slides.length - 1) {
      setActiveSlide(activeSlide + 1)
    } else {
      setActiveSlide(0)
    }
  }, 4000)

  document.onkeydown = function (e) {
    switch (e.key) {
      case 'ArrowLeft':
        setActiveSlide(previeusSlide)
        break
      case 'ArrowRight':
        setActiveSlide(nextSlide)
        break
    }
  }

  useEffect(() => {
    return () => clearInterval(interval)
  })

  return (
    <WppCard className={clsx(styles.card, styles[slides[activeSlide].title.toLowerCase()])}>
      <Flex direction="column">
        <WppTypography type="2xl-heading">Client tenant</WppTypography>
        <WppTypography type="m-body">See how other agencies are already taking advantage of WPP Open</WppTypography>
      </Flex>
      <Flex className={styles.carusel} align="center" gap={24} justify="center">
        <Flex className={styles.caruselTitle}>
          <WppTypography type="3xl-heading">{slides[activeSlide].title}</WppTypography>
        </Flex>
        {slidesView()}
        {controlSlide()}
      </Flex>
    </WppCard>
  )
}
