import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import processImgSrc from 'assets/pitch/nestle/process-scheme.png'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/nestleDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => (
  <Link to="/process/custom">
    <WidgetCard>
      <div slot="header">
        <WppTypography type="m-strong">The OPEN MIND Process</WppTypography>
      </div>
      <Flex justify="between" align="center" className={styles.processCardContent}>
        <img src={processImgSrc} className={styles.processImage} alt="" />
        <WppTypography type="l-body" className={styles.description}>
          The Open Mind process is a powerful and integrated approach that leverages data and insights to drive content
          development and media activation. By tailoring entry points to specific brand and market needs, it enables
          exceptional outcomes and maximum impact.
        </WppTypography>
      </Flex>
    </WidgetCard>
  </Link>
)
