// import IconBook from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/book.svg'
// import IconChat from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/chat.svg'
// import IconList from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/list.svg'
// import IconOptions from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/options.svg'

export enum Categories {
  ALL = 0,
  TASKS = 1,
  APPROVALS = 2,
  REQUESTS = 3,
}

export enum ProjectProgress {
  ASSEMBLE = 1,
  EXPLORE = 2,
  CREATE = 3,
}

export enum ProjectStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'INP',
  COMPLETED = 'CPL',
}

export interface ProjectApiDto {
  id: string
  assignment: AssignmmentDto
  project: ProjectDto
}

export interface AssignmmentDto {
  id: string
  task: {
    ID: string
    name: string
    milestone: ProjectProgress
    description: string
    canstart: boolean
    status: ProjectStatus
  }
  optask: null | any
}

interface Task {
  id: string
  name: string
  milestone: ProjectProgress | null
  tasknumber: number
  status: ProjectStatus
}

export interface ProjectDto {
  id: string
  name: string
  plannedStartDate: string
  milestonepath: null | any
  tasks: Task[]
}

// export interface Project {
//   class: string
//   title: string
//   caption: string
//   date?: string

//   // Row only data
//   raisedByData?: {
//     img: string
//     name: string
//   }
//   progress?: {
//     value: number
//     maxValue: number
//     text: string
//   }
//   buttons?: {
//     black?: boolean
//     text: string
//     iconRight: string | null
//     iconLeft: string | null
//     link?: string
//   }[]

//   // Card only data
//   content?: string
//   mentionedBy?: {
//     img: string
//     name: string
//   }
// }

export const categoriesProjects: Record<Categories, ProjectApiDto[]> = {
  [Categories.ALL]: [],
  // Row Data
  [Categories.TASKS]: [
    {
      id: '65f16515002639f3168eaf959513b512',
      assignment: {
        id: '65f1650f002636f1b1b93c77ae43663c',
        task: {
          ID: '65f164c40025fb404c8bfa645580013e',
          name: 'Create a Marketing Opportunity Brief',
          milestone: ProjectProgress.ASSEMBLE,
          description: '',
          canstart: true,
          status: ProjectStatus.NEW,
        },
        optask: null,
      },
      project: {
        id: '65f164b20025ebf6bd0d233256bcb97d',
        name: 'Sparkling Tea TikTok Project',
        plannedStartDate: 'Due September 23',
        milestonepath: null,
        tasks: [
          {
            id: '65f164c40025fb404c8bfa645580013e',
            name: 'Create a Marketing Opportunity Brief',
            milestone: ProjectProgress.ASSEMBLE,
            tasknumber: 1.0,
            status: ProjectStatus.NEW,
          },
        ],
      },
    },

    {
      id: '65f16515002639f3168eaf959513b512',
      assignment: {
        id: '65f1650f002636f1b1b93c77ae43663c',
        task: {
          ID: '65f164c40025fb404c8bfa645580013e',
          name: 'Prepare Brief Moodboard',
          milestone: ProjectProgress.EXPLORE,
          description: '',
          canstart: true,
          status: ProjectStatus.NEW,
        },
        optask: null,
      },
      project: {
        id: '65f164b20025ebf6bd0d233256bcb97d',
        name: 'Sparkling Tea TikTok Project',
        plannedStartDate: 'Due October 1',
        milestonepath: null,
        tasks: [
          {
            id: '65f164c40025fb404c8bfa645580013e',
            name: 'Prepare Brief Moodboard',
            milestone: ProjectProgress.EXPLORE,
            tasknumber: 1.0,
            status: ProjectStatus.NEW,
          },
        ],
      },
    },

    {
      id: '65f16515002639f3168eaf959513b512',
      assignment: {
        id: '65f1650f002636f1b1b93c77ae43663c',
        task: {
          ID: '65f164c40025fb404c8bfa645580013e',
          name: 'Prepare Conceptual Design',
          milestone: ProjectProgress.CREATE,
          description: '',
          canstart: true,
          status: ProjectStatus.NEW,
        },
        optask: null,
      },
      project: {
        id: '65f164b20025ebf6bd0d233256bcb97d',
        name: 'Sparkling Tea TikTok Project',
        plannedStartDate: 'Due October 7',
        milestonepath: null,
        tasks: [
          {
            id: '65f164c40025fb404c8bfa645580013e',
            name: 'Prepare Conceptual Design',
            milestone: ProjectProgress.CREATE,
            tasknumber: 1.0,
            status: ProjectStatus.NEW,
          },
        ],
      },
    },
    // {
    //   class: 'green',
    //   title: 'A New Project Has Been Assigned to you',
    //   caption: 'Fanta Banana Summer 2024',
    //   progress: {
    //     value: 1,
    //     maxValue: 6,
    //     text: 'ASSEMBLE',
    //   },
    //   date: 'Due September 23',
    //   buttons: [
    //     {
    //       text: 'Fill in Team Blueprint',
    //       iconRight: IconOptions,
    //       iconLeft: IconBook,
    //     },
    //     {
    //       link: 'https://playground.wppopen.com/orchestration/project/8739b0e0-c9cb-4ee5-9f99-b0a3b860a82c/workflow',
    //       text: 'Open Project',
    //       iconRight: null,
    //       iconLeft: null,
    //     },
    //   ],
    // },
    // {
    //   class: 'green',
    //   title: 'Prepare Brief Moodboard',
    //   caption: 'Sparkling Tea TikTok Project',
    //   progress: {
    //     value: 1,
    //     maxValue: 6,
    //     text: 'PREPARE BRIEF',
    //   },
    //   date: 'Due October 1',
    //   buttons: [
    //     {
    //       black: true,
    //       text: 'Create Brief',
    //       iconRight: IconOptions,
    //       iconLeft: null,
    //     },
    //   ],
    // },
  ],
  [Categories.APPROVALS]: [
    // {
    //   class: 'blue',
    //   title: 'Create Initial Shopper Strategy',
    //   caption: 'Sparkling Tea TikTok Project',
    //   progress: {
    //     value: 3,
    //     maxValue: 6,
    //     text: 'EXPLORE',
    //   },
    //   date: 'Due October 1',
    //   buttons: [
    //     {
    //       text: 'See Best Practices',
    //       iconRight: IconOptions,
    //       iconLeft: IconList,
    //     },
    //     {
    //       text: 'Message Team',
    //       iconRight: IconChat,
    //       iconLeft: null,
    //     },
    //     {
    //       link: 'https://playground.wppopen.com/orchestration/project/23c9e034-1d5e-4595-a358-1027d205b301/workflow',
    //       text: 'Open Project',
    //       iconRight: null,
    //       iconLeft: null,
    //     },
    //   ],
    // },
  ],

  [Categories.REQUESTS]: [
    // {
    //   class: 'blue2',
    //   title: 'Create Initial Shopper Strategy',
    //   caption: 'Sparkling Tea TikTok Project',
    //   progress: {
    //     value: 3,
    //     maxValue: 6,
    //     text: 'EXPLORE',
    //   },
    //   date: 'Due October 1',
    //   buttons: [
    //     {
    //       text: 'See Best Practices',
    //       iconRight: IconOptions,
    //       iconLeft: IconList,
    //     },
    //     {
    //       text: 'Message Team',
    //       iconRight: IconChat,
    //       iconLeft: null,
    //     },
    //     {
    //       link: 'https://playground.wppopen.com/orchestration/project/23c9e034-1d5e-4595-a358-1027d205b301/workflow',
    //       text: 'Open Project',
    //       iconRight: null,
    //       iconLeft: null,
    //     },
    //   ],
    // },
  ],
}
