import { WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import emptyContent from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/assets/emptyContent.svg'
import { StatisticCard } from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/components/statisticCard/StatisticCard'
import { dataMock } from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/dataMock'
import styles from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/MediaEffectiveness.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MediaEffectiveness = () => {
  const [activeTab, setActiveTab] = useState<string>('footfall')

  return (
    <WidgetCard className={styles.mediaCard}>
      <Flex direction="column" className={styles.controlsWrapper}>
        <WppSegmentedControl value={activeTab} onWppChange={({ detail }) => setActiveTab(detail.value as string)}>
          <WppSegmentedControlItem value="footfall">Footfall</WppSegmentedControlItem>
          <WppSegmentedControlItem value="sales">Sales</WppSegmentedControlItem>
          <WppSegmentedControlItem value="instagram">Instagram</WppSegmentedControlItem>
          <WppSegmentedControlItem value="youTube">YouTube</WppSegmentedControlItem>
          <WppSegmentedControlItem value="meta">Meta</WppSegmentedControlItem>
        </WppSegmentedControl>

        <Flex direction="column" gap={20} className={styles.content}>
          <img src={dataMock[activeTab] ? dataMock[activeTab].chart : emptyContent} alt="" className={styles.img} />

          <Flex gap={8}>
            {dataMock[activeTab] &&
              dataMock[activeTab].data.map((column, colIndex) => (
                <Flex key={colIndex} direction="column" gap={12} className={styles.dataColumn}>
                  {column.map(({ title, subtitle, value, isGrayValue }, itemIndex) => (
                    <StatisticCard
                      title={title}
                      subtitle={subtitle}
                      value={value}
                      isGrayValue={isGrayValue}
                      key={itemIndex}
                    />
                  ))}
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Flex>
    </WidgetCard>
  )
}
