import { EasyJetWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type easy_jetWidget = Omit<Widget, 'name'> & { name: EasyJetWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'easy_jet_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [EasyJetWidgetType.TERMINAL]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 1,
    gridColumnEnd: 2,
  },
  [EasyJetWidgetType.AIR_FLOW]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridRowEnd: 2,
    gridColumnEnd: 2,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 2,
    gridColumnStart: 2,
    gridRowEnd: 2,
    gridColumnEnd: 18,
  },
  [SystemWidget.FAVOURITE_LINKS]: {
    gridRowStart: 1,
    gridColumnStart: 10,
    gridRowEnd: 2,
    gridColumnEnd: 18,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 2,
    gridRowEnd: 2,
    gridColumnEnd: 10,
  },
}

export const easyJetWidgets: easy_jetWidget[] = [
  {
    ...gridConfig[EasyJetWidgetType.TERMINAL],
    ...commonWidgetsConfig,
    name: EasyJetWidgetType.TERMINAL,
    id: 'easy_jet_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[EasyJetWidgetType.AIR_FLOW],
    ...commonWidgetsConfig,
    name: EasyJetWidgetType.AIR_FLOW,
    id: 'easy_jet_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'easy_jet_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.FAVOURITE_LINKS],
    ...commonWidgetsConfig,
    name: SystemWidget.FAVOURITE_LINKS,
    id: 'easy_jet_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'easy_jet_5',
    createdAt: '',
    updatedAt: '',
  },
]
