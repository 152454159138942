import { facadeApi } from 'api'
import { Link } from 'types/links/links'

export interface Params {
  filter?: { isoAlpha2?: string; includeNoCountries?: boolean }
  sort: string
}

export interface LinksResponse {
  data: Link[]
}

export const fetchMyLinksApi = ({ sort, filter }: Params) =>
  facadeApi.get<LinksResponse>('/links/my', { params: { sort, filter } })
