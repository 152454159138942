import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { clsx } from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pitch/exploreProcessWidget/ExploreProcessWidget.module.scss'

export const ExploreProcessWidget = () => {
  const cards = [
    {
      title: 'OpCo Convergence',
      titleClass: 'yellow',
      description: 'Integrate your agency into the Open and get the best digital transformation process ever.',
      buttonTitle: 'Learn more',
      link: '/opco-convergence-overview',
    },
    {
      title: 'Pitch Support',
      titleClass: 'red',
      description: 'Build collaboration and communication between the Business and Delivery teams.',
      buttonTitle: 'Learn more',
      link: '/central/pitch-support-overview',
    },
    {
      title: 'Client Tenant',
      titleClass: 'green',
      description: 'Immerse external companies & clients into the Open innovative business ecosystem',
      buttonTitle: 'Learn more',
      link: '',
    },
    {
      title: 'Product Migration',
      titleClass: 'blue',
      description: 'Expand product reach, monetise, and seize new opportunities for growth and improvement',
      buttonTitle: 'Learn more',
      link: '/product-migration',
    },
  ]

  return (
    <WppCard size="l" className={styles.container}>
      <WppTypography type="3xl-heading" className={styles.title}>
        Learn more about achieving your objective with WPP Open
      </WppTypography>
      <WppTypography className={styles.description} type="m-body">
        Choose one of the following process types to understand how WPP Open can be tailored to meet your
        organization&lsquo;s specific needs and help you achieve your objectives.
      </WppTypography>
      <div className={styles.ellipse} />
      <div className={styles.cardExplore}>
        {cards.map(card => (
          <WppCard size="l" key={card.title} className={styles.card}>
            <Flex className={clsx(styles.cover, styles[card.titleClass])} justify="center" align="center">
              <div className={styles.videoShadow} />
              <div className={styles.borderPlay} />
            </Flex>
            <WppTypography type="l-strong">{card.title}</WppTypography>
            <WppTypography type="m-body" className={styles.cardDescription}>
              {card.description}
            </WppTypography>
            {card.link && (
              <Link to={card.link}>
                <WppButton variant="secondary" className={styles.button}>
                  <WppTypography type="s-strong" className={styles.buttonLabel}>
                    {card.buttonTitle}
                  </WppTypography>
                </WppButton>
              </Link>
            )}
          </WppCard>
        ))}
      </div>
    </WppCard>
  )
}
