import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import processImgSrc from 'pages/home/components/pitch/orangeDashboard/components/processWidget/assets/process-img.svg'
import styles from 'pages/home/components/pitch/orangeDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => (
  <Link to="/process/custom">
    <WidgetCard className={styles.processCard}>
      <div slot="header">
        <WppTypography type="m-strong" className={styles.headerTitle}>
          WPP Orange Partnership Hub
        </WppTypography>
      </div>
      <div className={styles.contentContainer}>
        <img src={processImgSrc} className={styles.orangeProcessImage} alt="" />
      </div>
    </WidgetCard>
  </Link>
)
