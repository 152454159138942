import img0 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/0.png'
import img1 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/1.png'
import img2 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/2.png'
import img3 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/3.png'
import img4 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/4.png'
import img5 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/5.png'
import img6 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/6.png'
import img7 from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/assets/7.png'

export interface Member {
  name: string
  role: string
  profileImg: string
}

export interface Team {
  name: string
  members: Member[]
}

export const teams: Team[] = [
  {
    name: '',
    members: [
      {
        name: 'Mira João Oliviera',
        role: 'CEO',
        profileImg: img0,
      },
      {
        name: 'Frederico Correia',
        role: 'Head of Client',
        profileImg: img1,
      },
      {
        name: 'Vanessa Machado',
        role: 'Client Director',
        profileImg: img2,
      },
    ],
  },
  {
    name: '',
    members: [
      {
        name: 'Susana Pina',
        role: 'Client Specialist',
        profileImg: img3,
      },
      {
        name: 'Liliana Santos',
        role: 'Client Associate',
        profileImg: img4,
      },
      {
        name: 'André Tavares',
        role: 'Head of Digital',
        profileImg: img5,
      },
    ],
  },
  {
    name: '',
    members: [
      {
        name: 'Karine Santos',
        role: 'Head of Startegy',
        profileImg: img6,
      },
      {
        name: 'Marisa Valente',
        role: 'Head of Business & Science',
        profileImg: img7,
      },
    ],
  },
]
