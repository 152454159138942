import member1Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/1.png'
import member2Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/2.png'
import member3Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/3.png'
import member4Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/4.png'
import member5Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/5.png'
import member6Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/6.png'
import member7Src from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/assets/members/7.png'

export interface Member {
  name: string
  role: string
  profileImg: string
}

export interface Team {
  name: string
  members: Member[]
}

export const teams: Team[] = [
  {
    name: 'WPP Global Client Lead',
    members: [
      {
        name: 'Alina Kessel',
        role: '',
        profileImg: member1Src,
      },
    ],
  },
  {
    name: 'Core Leadership',
    members: [
      {
        name: 'Alice Hunter',
        role: 'Business Lead',
        profileImg: member2Src,
      },
      {
        name: 'Gareth Ellis',
        role: 'Strategy Lead',
        profileImg: member3Src,
      },
      {
        name: 'Jaime Mandelbau',
        role: 'Strategy Lead',
        profileImg: member4Src,
      },
    ],
  },
  {
    name: 'Subject Matter Experts',
    members: [
      {
        name: 'Anna Vogt',
        role: 'Chief Strategy Officer',
        profileImg: member5Src,
      },
      {
        name: 'Carl Reed',
        role: 'Data Lead',
        profileImg: member6Src,
      },
      {
        name: 'Nina Rahmatallah',
        role: 'Consulting',
        profileImg: member7Src,
      },
    ],
  },
]
