import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ImageWidget } from 'pages/home/components/pitch/volkswagenDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/volkswagenDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/volkswagenDashboard/components/userWidget/UserWidget'
import { WrikeWidget } from 'pages/home/components/pitch/volkswagenDashboard/components/wrikeWidget/WrikeWidget'
import styles from 'pages/home/components/pitch/volkswagenDashboard/VolkswagenDashboard.module.scss'
import { volkswagenWidgets } from 'pages/home/components/pitch/volkswagenDashboard/widgetsMock'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { VolkswagenWidgetType, SystemWidget, ProjectsWidgetDetailsType } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | VolkswagenWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case VolkswagenWidgetType.IMAGE:
      return <ImageWidget />
    case VolkswagenWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/05/29'),
              getText: () => 'Svetlana Savatic assigned Publisher brief for Brand Campaign - Škoda to you',
            },
            {
              getDate: () => new Date('2023/05/30'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
            {
              getDate: () => new Date('2023/06/01'),
              getText: () => 'Alice Smith updated task Review creatives for Summer 2023 status to “Pending”',
            },
            {
              getDate: () => new Date('2023/06/03'),
              getText: () => 'Carolin Wels updated task Media strategy for VW NF Amarok status to "Completed"',
            },
            {
              getDate: () => new Date('2023/06/05'),
              getText: () => 'Markus Bettler invited you to VW Fast Start workshop',
            },
            {
              getDate: () => new Date('2023/06/06'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
            {
              getDate: () => new Date('2023/06/07'),
              getText: () => 'Svetlana Savatic assigned Publisher brief for Brand Campaign - Škoda to you',
            },
            {
              getDate: () => new Date('2023/06/09'),
              getText: () => 'Ben Aloe invited you to project Media Campaign Winter 2023',
            },
            {
              getDate: () => new Date('2023/06/12'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
            {
              getDate: () => new Date('2023/06/14'),
              getText: () => 'Alice Smith updated task Review creatives for Summer 2023 status to “Pending”',
            },
            {
              getDate: () => new Date('2023/06/15'),
              getText: () => 'Ben Aloe invited you to project Media Campaign Winter 2023',
            },
            {
              getDate: () => new Date('2023/06/17'),
              getText: () =>
                'Alice Smith invited you to Media Campaign Summer 2023\n\nAlice Smith updated task Review creatives for Summer 2023 status to “Approved”\n\nHannah Heine assigned Prepare creative brief for Seat France to you\n\nHannah Heine invited you to project Seat media workshop',
            },
            {
              getDate: () => new Date('2023/06/20'),
              getText: () => 'Markus Bettler invited you to VW Fast Start workshop',
            },
            {
              getDate: () => new Date('2023/06/21'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
            {
              getDate: () => new Date('2023/06/23'),
              getText: () => 'Tasillo Flamm updated task Invoicing for VW NF California 6.1 status to "Completed"',
            },
            {
              getDate: () => new Date('2023/06/26'),
              getText: () => 'Tasillo Flamm updated task Invoicing for VW NF California 6.1 status to "Completed"',
            },
            {
              getDate: () => new Date('2023/06/28'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
            {
              getDate: () => new Date('2023/06/29'),
              getText: () => 'Svetlana Savatic assigned Publisher brief for Brand Campaign - Škoda to you',
            },
            {
              getDate: () => new Date('2023/07/01'),
              getText: () => 'Stephanie Dirscherl invited you to Audi Q8 e-tron project',
            },
          ]}
          clockOptions={[{ timeZone: 'Europe/Budapest', city: 'Wolfsburg' }]}
        />
      )
    case VolkswagenWidgetType.PROCESS:
      return <ProcessWidget />
    case VolkswagenWidgetType.USER:
      return <UserWidget />
    case VolkswagenWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    case VolkswagenWidgetType.WRIKE:
      return <WrikeWidget />
    default:
      return null
  }
}

export const VolkswagenDashboard = () => (
  <div className={styles.container}>
    {volkswagenWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
