import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/upcomingDatesWidget/UpcomingDatesWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const listOptionsByGroup = [
  {
    label: 'Creative',
    items: [
      {
        date: '1.5.2023',
        label: 'Indispensable 2023',
      },
      {
        date: '4.5.2023',
        label: 'Against the ‘Graine',
      },
      {
        date: '18.5.2023',
        label: 'Light the way 2023',
      },
      {
        date: '22.5.2023',
        label: 'Don’t got this 2023',
      },
      {
        date: '22.5.2023',
        label: 'Call it a comeback 2023',
      },
      {
        date: '27.5.2023',
        label: 'Growth starts within 2023',
      },
    ],
  },
  {
    label: 'Media',
    items: [
      {
        date: '1.6.2023',
        label: 'Indispensable 2023',
      },
    ],
  },
]

export const UpcomingDatesWidget = () => {
  return (
    <WidgetCard>
      <WppTypography slot="header" type="m-strong">
        Key upcoming dates
      </WppTypography>

      <Flex gap={20} direction="column" className={styles.listContainer}>
        {listOptionsByGroup.map((option, groupIdx) => (
          <Flex key={groupIdx} gap={16} direction="column">
            <WppTypography type="s-strong">{option.label}</WppTypography>
            {option.items.map((item, idx) => (
              <Flex key={idx} gap={10} direction="row">
                <WppTypography type="s-body">{item.date}</WppTypography>
                <WppTypography type="s-body">{item.label}</WppTypography>
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </WidgetCard>
  )
}
