import { workfrontApi } from 'api'
import { ProjectApiDto } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjects/sectionProjectsMockData'

interface WorkfrontAssignmentsResponse {
  data: {
    workItems: ProjectApiDto[]
  }[]
}

export const fetchWorkfrontAssignmentsApi = ({ token }: { token: string }) =>
  workfrontApi.get<WorkfrontAssignmentsResponse>('/workItems', {
    headers: {
      'X-Workfront-Authorization': `Bearer ${token}`,
    },
  })
