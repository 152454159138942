import { AnalyticsActionType } from '@wpp-open/core'
import fastDeepEqual from 'fast-deep-equal/es6'
import { useMemo } from 'react'

import { useLinksApi } from 'api/links/queries/useLinksApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { queryClient } from 'providers/osQueryClient/utils'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export const FAVOURITE_LINKS_ITEMS_FIT_COUNT = 10
export const DEFAULT_COLUMNS_COUNT = 1

export const useHasAccessToFavouriteLinks = () => {
  const { hasPermission } = useHasPermission()
  return hasPermission(Permission.OS_APPS_ACCESS)
}

export const useFavouriteLinks = () => {
  const enabled = useHasAccessToFavouriteLinks()

  const params: { sort: string; filter?: { isoAlpha2: string } } = {
    sort: 'name',
  }

  const { data, ...rest } = useLinksApi({
    params,
    enabled,
  })

  return {
    data: data.filter(({ pinned, favorite, active }) => active && (pinned || favorite)),
    ...rest,
  }
}

export const handleTrackFavouriteLinksViewAll = () =>
  trackAnalytics({
    type: AnalyticsActionType.action,
    payload: EVENTS.HOME_PAGE.ACTIONS.HOME_FAVORITE_LINKS_WIDGET_VIEW_ALL,
  })

export const reloadFavouriteLinks = () => queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.LINKS] })

export const favouriteLinksWidgetSizes = [
  { width: 8, height: 2 },
  { width: 4, height: 1 },
  { width: 4, height: 3 },
]

export const favouriteLinksWidgetSizesMap = {
  L: favouriteLinksWidgetSizes[0],
  S: favouriteLinksWidgetSizes[1],
  M: favouriteLinksWidgetSizes[2],
}

const FAVOURITE_LINKS_LIST_GAP = {
  S: 12,
  M: 8,
  L: 8,
}

export const favouriteLinksWidgetSizesLoadingStateConfig = {
  L: {
    rectangleSize: 24,
    isVariable: false,
    itemsCount: 15,
    gap: 8,
  },
  M: {
    rectangleSize: 24,
    isVariable: true,
    itemsCount: 15,
    gap: 8,
  },
  S: {
    rectangleSize: 30,
    isVariable: true,
    itemsCount: 3,
    gap: 12,
  },
}

const getFavouriteLinksWidgetListColumnsCount = (dimensions: { width: number; height: number }) => {
  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.L)) {
    return 2
  }

  return DEFAULT_COLUMNS_COUNT
}

export const getFavouriteLinksWidgetListGap = (dimensions: { width: number; height: number }) => {
  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.L)) {
    return FAVOURITE_LINKS_LIST_GAP.L
  }
  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.M)) {
    return FAVOURITE_LINKS_LIST_GAP.M
  }
  return FAVOURITE_LINKS_LIST_GAP.S
}

const getFavouriteLinksWidgetLoadingConfig = (dimensions: { width: number; height: number }) => {
  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.L)) {
    return favouriteLinksWidgetSizesLoadingStateConfig.L
  }

  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.M)) {
    return favouriteLinksWidgetSizesLoadingStateConfig.M
  }

  if (fastDeepEqual(dimensions, favouriteLinksWidgetSizesMap.S)) {
    return favouriteLinksWidgetSizesLoadingStateConfig.S
  }
  return favouriteLinksWidgetSizesLoadingStateConfig.M
}

export const useColumnsCount = ({ width, height }: { width: number; height: number }) =>
  useMemo(() => getFavouriteLinksWidgetListColumnsCount({ width, height }), [width, height])

export const useLoadingConfig = ({ width, height }: { width: number; height: number }) =>
  useMemo(() => getFavouriteLinksWidgetLoadingConfig({ width, height }), [width, height])
