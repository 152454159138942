import { WppTypography } from '@platform-ui-kit/components-library-react'

import { CentreOfExcellenceLink } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/centreOfExcallenceDataMock'
import { CentreOfExcellenceLinkCard } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/components/centreOfExcellenceLinkCard/CentreOfExcellenceLinkCard'
import styles from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/components/centreOfExcellenceSection/CentreOfExcellenceSection.module.scss'

interface Props {
  title: string
  links: CentreOfExcellenceLink[]
}

export const CentreOfExcellenceSection = ({ title, links }: Props) => (
  <div className={styles.container}>
    <WppTypography type="m-strong" className={styles.title}>
      {title}
    </WppTypography>
    <div>
      {links.map((link, index) => (
        <CentreOfExcellenceLinkCard link={link} key={index} />
      ))}
    </div>
  </div>
)
