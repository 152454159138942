import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { NewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/newsPieceCard/NewsPieceCard'
import styles from 'pages/home/systemWidgets/newsWidget/newsPieceCard/snippetNewsPieceCard/SnippetNewsPieceCard.module.scss'
import { NewsPiece } from 'types/news'

export const SnippetNewsPieceCard = ({ newsPiece }: { newsPiece: NewsPiece }) => {
  const { banner_image: bannerImage, title, created_at: createdAt } = newsPiece

  return (
    <NewsPieceCard>
      <div className={styles.content}>
        <NewsPieceCard.Image src={bannerImage} />

        <Flex className={styles.textContent} justify="center" direction="column" gap={24}>
          {!!title && (
            <Truncate lines={4} type="s-strong" className={styles.title}>
              {title}
            </Truncate>
          )}

          {!!createdAt && <NewsPieceCard.CreatedAt createdAt={createdAt} />}
        </Flex>
      </div>
    </NewsPieceCard>
  )
}
