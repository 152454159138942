import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/toyotaDashboard/components/leanAppWidget/LeanAppWidget.module.scss'

interface Props {
  title: string
  buttonText: string
  leanAppUrl: string
}

export const LeanAppWidget = ({ title, buttonText, leanAppUrl }: Props) => {
  return (
    <WppCard className={styles.widget}>
      <div slot="header" className={styles.line} />
      <WppTypography className={styles.text} type="2xl-heading">
        {title}
      </WppTypography>
      <Link to={leanAppUrl}>
        <WppButton className={styles.button}>{buttonText}</WppButton>
      </Link>
    </WppCard>
  )
}
