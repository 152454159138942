import { WppTypography, WppCard } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/components/statisticCard/StatisticCard.module.scss'

interface Props {
  title: string
  subtitle: string
  value: string
  isGrayValue?: boolean
}

export const StatisticCard = ({ title, subtitle, value, isGrayValue }: Props) => (
  <WppCard className={styles.statCard}>
    <WppTypography type="s-body">{title}</WppTypography>
    <WppTypography type="xs-body">{subtitle}</WppTypography>
    <WppTypography
      type="xs-body"
      className={clsx({
        [styles.greenValue]: !isGrayValue,
      })}
    >
      {value}
    </WppTypography>
  </WppCard>
)
