import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { CulturalDigestWidget } from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/CulturalDigestWidget'
import { MetricsWidget } from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/MetricsWidget'
import { ProcessWidget } from 'pages/home/components/pitch/spotifyDashboard/components/processWidget/ProcessWidget'
import { ProjectsWidget } from 'pages/home/components/pitch/spotifyDashboard/components/projectsWidget/ProjectsWidget'
import { TopCreativesWidget } from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/TopCreativesWidget'
import { UserWidget } from 'pages/home/components/pitch/spotifyDashboard/components/userWidget/UserWidget'
import styles from 'pages/home/components/pitch/spotifyDashboard/SpotifyDashboard.module.scss'
import { spotifyWidgets } from 'pages/home/components/pitch/spotifyDashboard/widgetsMock'
import { Project } from 'types/projects/project'
import { SpotifyWidgetType, SystemWidget } from 'types/widgets/widget'

export interface ISpotifyDemoConfig {
  projectLink: string
  projects: Pick<Project, 'name' | 'type' | 'id'>[]
  newsTenantId: string
}

const RenderWidget = ({
  widgetType,
  demoConfig,
}: {
  widgetType: SystemWidget | SpotifyWidgetType
  demoConfig?: ISpotifyDemoConfig
}) => {
  switch (widgetType) {
    case SystemWidget.PROJECTS:
      return <ProjectsWidget />
    case SpotifyWidgetType.PROCESS:
      return <ProcessWidget isDisabled={Boolean(demoConfig)} />
    case SpotifyWidgetType.USER:
      return <UserWidget />
    case SpotifyWidgetType.CULTURAL_DIGEST:
      return <CulturalDigestWidget />
    case SpotifyWidgetType.METRICS:
      return <MetricsWidget />
    case SpotifyWidgetType.TOP_CREATIVES:
      return <TopCreativesWidget />
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} inline tenantId={demoConfig?.newsTenantId} />
    default:
      return null
  }
}

export const SpotifyDashboard = ({ demoConfig }: { demoConfig?: ISpotifyDemoConfig }) => (
  <div className={styles.container}>
    {spotifyWidgets.map(({ id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name }) => (
      <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
        <RenderWidget widgetType={name} demoConfig={demoConfig} />
      </div>
    ))}
  </div>
)
