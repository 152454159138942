import { WppCard, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useRef } from 'react'

import {
  Drawing,
  drawingList,
} from 'pages/home/components/pitch/allwynDashboard/components/nextDrawingWidget/drawingList'
import styles from 'pages/home/components/pitch/allwynDashboard/components/nextDrawingWidget/NextDrawingWidget.module.scss'

const DrawingItem = ({ title, content, subContent, subtitle, backgroundImage }: Drawing) => (
  <WppCard size="m" className={styles.drawingItem}>
    <img src={backgroundImage} className={styles.drawImage} alt={title} />
    <div className={styles.drawingContainer}>
      <div className={styles.drawingHeader}>
        <WppTypography type="m-strong" className={styles.text}>
          {title}
        </WppTypography>
        <WppTypography type="l-strong" className={styles.text}>
          {subtitle}
        </WppTypography>
      </div>

      <div className={styles.drawingText}>
        <WppTypography type="xl-heading" className={styles.text}>
          {content}
        </WppTypography>
        <WppTypography type={subtitle === 'SET FOR LIFE' ? '3xl-heading' : '4xl-display'} className={styles.text}>
          {subContent}
        </WppTypography>
      </div>
    </div>
  </WppCard>
)

export const NextDrawingWidget = () => {
  const listRef = useRef<HTMLUListElement>(null)

  const handleListScroll = (direction: 'left' | 'right') =>
    listRef?.current!.scrollBy({ left: direction === 'left' ? -350 : 350, behavior: 'smooth' })

  return (
    <WppCard className={styles.widget}>
      <div slot="header" className={styles.cardHeader}>
        <WppTypography type="l-strong">Next Drawing</WppTypography>
        <WppIconChevron direction="left" onClick={() => handleListScroll('left')} />
        <WppIconChevron direction="right" onClick={() => handleListScroll('right')} />
      </div>

      <ul className={styles.drawingList} ref={listRef}>
        {drawingList.map(({ title, subContent, subtitle, content, backgroundImage }, index) => (
          <DrawingItem
            key={index}
            title={title}
            subtitle={subtitle}
            content={content}
            subContent={subContent}
            backgroundImage={backgroundImage}
          />
        ))}
      </ul>
    </WppCard>
  )
}
