import { useState } from 'react'

const workfrontLSKey = 'WPP_WORKFRONT_TOKEN'

export const useWorkfrontToken = () => {
  const [token, setToken] = useState(window.localStorage.getItem(workfrontLSKey) || '')

  const setAndSaveToken = (token: string) => {
    setToken(token)
    window.localStorage.setItem(workfrontLSKey, token)
  }

  const removeToken = () => {
    setToken('')
    window.localStorage.removeItem(workfrontLSKey)
  }

  return {
    token,
    setAndSaveToken,
    removeToken,
  }
}
