import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/toyotaDashboard/components/newsWidget/NewsWidget.module.scss'

interface Props {
  title: string
  url: string
  imageSrc: string
}

export const NewsWidget = ({ title, url, imageSrc }: Props) => (
  <Link to={url} target="_blank">
    <WppCard className={styles.card}>
      <img src={imageSrc} className={styles.image} alt="" />
      <WppTypography type="m-strong" className={styles.text}>
        {title}
      </WppTypography>
    </WppCard>
  </Link>
)
