import { HeroWidget } from 'pages/home/components/pitch/generalMillsDashboard/components/heroWidget/HeroWidget'
import { IntroWidget } from 'pages/home/components/pitch/generalMillsDashboard/components/introWidget/IntroWidget'
import { MediaEffectiveness } from 'pages/home/components/pitch/generalMillsDashboard/components/mediaEffectiveness/MediaEffectiveness'
import { MilestonesAndTimelinesWidget } from 'pages/home/components/pitch/generalMillsDashboard/components/milestonesAndTimelinesWidget/MilestonesAndTimelinesWidget'
import styles from 'pages/home/components/pitch/generalMillsDashboard/GeneralMillsDashboard.module.scss'
import { generalMillsWidgets } from 'pages/home/components/pitch/generalMillsDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { GeneralMillsWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | GeneralMillsWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case GeneralMillsWidgetType.HERO_BANNER:
      return <HeroWidget />
    case GeneralMillsWidgetType.INTRO:
      return <IntroWidget />
    case GeneralMillsWidgetType.MEDIA_EFFECTIVENESS:
      return <MediaEffectiveness />
    case GeneralMillsWidgetType.MILESTONES_AND_TIMELINES:
      return <MilestonesAndTimelinesWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const GeneralMillsDashboard = () => (
  <div className={styles.container}>
    {generalMillsWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
