import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserPermissionsApi } from 'api/alphaZulu/queries/useUserPermissionsApi'
import { useNavigationTreeApi } from 'api/navigation/queries/useNavigationTreeApi'
import { useUserAssignedRolesApi } from 'api/roles/queries/useUserAssignedRolesApi'
import { is403Error } from 'api/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useInitSegmentAnalytics } from 'hooks/useInitSegmentAnalytics'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { useAuth } from 'providers/auth/AuthContext'
import { DataContext } from 'providers/data/DataContext'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

/**
 * Provides necessary data to both new and legacy contexts
 */
export const DataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { currentTenant, userDetails } = useTenantAndUserData()

  const { data: userAssignedRoles, isError: isUserAssignedRolesError } = useUserAssignedRolesApi({
    params: { email: userDetails.email, id: userDetails.id },
  })

  const {
    isLoading: isPermissionsLoading,
    data: permissions,
    isError: isPermissionsError,
  } = useUserPermissionsApi({
    params: {
      email: user.email,
      accountId: currentTenant.azMeta.organizationsId,
    },
  })

  const {
    isLoading: isNavigationTreeLoading,
    data: navigationTree,
    error: navigationTreeError,
  } = useNavigationTreeApi({
    params: {
      tenantId: currentTenant.id,
    },
  })

  useProviderNoncriticalError({
    isError: isUserAssignedRolesError,
    message: t('os.provider_errors.user_assigned_roles'),
  })

  useProviderNoncriticalError({
    isError: isPermissionsError,
    message: t('os.provider_errors.permissions'),
  })

  useInitSegmentAnalytics({ userDetails, roles: userAssignedRoles })

  const isLoading = isPermissionsLoading || isNavigationTreeLoading

  if (isLoading) {
    return <LoadingPage />
  }

  if (navigationTreeError) {
    return is403Error(navigationTreeError) ? <ForbiddenOSAccessError /> : <OsIsNotAvailableError />
  }

  return (
    <DataContext.Provider
      value={{
        permissions: permissions!,
        navigationTree: navigationTree!,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
