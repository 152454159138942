import styles from 'pages/home/components/pitch/beiersdorfDashboard/BeiersdorfDashboard.module.scss'
import { ImageWidget } from 'pages/home/components/pitch/beiersdorfDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/beiersdorfDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/beiersdorfDashboard/components/userWidget/UserWidget'
import { beiersdorfWidgets } from 'pages/home/components/pitch/beiersdorfDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { BeiersdorfWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | BeiersdorfWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case BeiersdorfWidgetType.IMAGE:
      return <ImageWidget />
    case BeiersdorfWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/06/14'),
              getText: () => 'Alice Smith invited you to  Media Campaign Summer 2023',
            },
            {
              getDate: () => new Date('2023/06/15'),
              getText: () => 'Hanna Heine assigned Prepare creative brief for Coppertone Summer to you',
            },
            {
              getDate: () => new Date('2023/06/17'),
              getText: () =>
                'Alice Smith invited you to Media Campaign Summer 2023\n\nAlice Smith updated task Review creatives for Summer 2023 status to “Approved”\n\nHannah Heine assigned Prepare creative brief for Seat France to you\n\nHannah Heine invited you to project Seat media workshop',
            },
          ]}
          clockOptions={[{ timeZone: 'Europe/Budapest', city: 'Hamburg' }]}
        />
      )
    case BeiersdorfWidgetType.PROCESS:
      return <ProcessWidget />
    case BeiersdorfWidgetType.USER:
      return <UserWidget />
    case BeiersdorfWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const BeiersdorfDashboard = () => (
  <div className={styles.container}>
    {beiersdorfWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
