import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useRef, forwardRef, Ref } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { Select, SelectProps, SelectOption } from 'components/common/select/Select'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

export type FormSelectOption = SelectOption

export interface FormSelectProps<T extends Record<string, any>> extends Omit<SelectProps<T>, 'onChange' | 'value'> {
  name: string
  'data-testid'?: string
}

export const FormSelect = forwardRef(function FormSelect<T extends Record<string, any>>(
  { name, message, messageType, 'data-testid': dataTestId, onWppChange, dropdownConfig, ...rest }: FormSelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  const innerRef = useRef<HTMLWppSelectElement>(null)

  useProvideFieldFocus({
    fieldRef,
    setFocus: () => innerRef.current?.setFocus(),
  })

  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <Select
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      name={name}
      value={value}
      dropdownConfig={{
        ...dropdownConfig,
        onHidden: instance => {
          dropdownConfig?.onHidden?.(instance)
          onBlur()
        },
      }}
      messageType={shouldShowError ? 'error' : messageType}
      message={shouldShowError ? errorText : message}
      onWppChange={e => {
        onChange(e.detail.value)
        onWppChange?.(e)
      }}
      data-testid={dataTestId}
    />
  )
}) as <T extends Record<string, any>>(props: FormSelectProps<T>) => JSX.Element

export const FormSelectSkeleton = () => (
  <Flex gap={8} direction="column">
    <WppSkeleton width="30%" height={22} />
    <WppSkeleton height={40} />
  </Flex>
)
