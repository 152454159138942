import 'https://cc-embed.adobe.com/sdk/v4/CCEverywhere.js'
import { useState } from 'react'

const hostInfo = {
  clientId: '187c3704fce245b7b218a3018b6b50f9',
  appName: 'PlaygroundTest',
}

const configParams = {
  loginMode: 'delayed',
}

const docConfig = {}

const exportConfig = [] as any[]
const containerConfig = {
  padding: 100,
}

export const useAdobeExpress = () => {
  const [initialized, setInitialized] = useState(false)
  const [opened, setIsOpened] = useState(false)
  const [editorCC, setEditor] = useState(null as any)

  const initAndOpen = async () => {
    if (opened) return
    try {
      setIsOpened(true)
      const callbacks = {
        onCancel: () => {
          setIsOpened(false)
        },
        // onPublish: (intent: any, publishParams: any) => {
        //   console.log('Publish intent', intent, publishParams)
        // },
        onError: (err: any) => {
          console.error('Error received is', err.toString())
        },
      }

      const appConfig = { callbacks }
      if (initialized && editorCC) {
        editorCC.create(docConfig, appConfig, exportConfig, containerConfig)
      } else {
        // @ts-ignore
        const { editor } = await window.CCEverywhere.initialize(hostInfo, configParams)
        setInitialized(true)
        setEditor(editor)
        await editor.create(docConfig, appConfig, exportConfig, containerConfig)
      }
    } catch (e) {
      console.error('Failed to initialize CCEverywhere', e)
    }
  }

  return {
    initAndOpen,
  }
}
