import { ProjectType } from '@wpp-open/core'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/spotifyDashboard/components/projectsWidget/ProcessWidget.module.scss'
import { ProjectsWidgetCard } from 'pages/home/systemWidgets/projectsWidget/projectsWidgetCard/ProjectsWidgetCard'
import { ProjectsWidgetList } from 'pages/home/systemWidgets/projectsWidget/projectsWidgetList/ProjectsWidgetList'
import { ProjectsWidgetView as SystemProjectsWidget } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { WidgetTitle } from 'pages/home/WidgetTitle'
import { WidgetProject } from 'types/projects/widgetProject'
import { ProjectsWidgetDetailsType, ProjectsWidgetDTO } from 'types/widgets/widget'

export const upcomingProjectsMock = [
  { name: 'Coachella 2024', type: ProjectType.Campaign, id: 'id1', hierarchy: null, startDate: null, endDate: null },
  { name: 'Olympics 2024', type: ProjectType.Campaign, id: 'id2', hierarchy: null, startDate: null, endDate: null },
  { name: 'Wrapped 2024', type: ProjectType.Campaign, id: 'id3', hierarchy: null, startDate: null, endDate: null },
]

export const recentBriefsMock: WidgetProject[] = [
  {
    name: 'EMEA Deezer Double down',
    type: ProjectType.Campaign,
    hierarchy: null,
    startDate: null,
    endDate: null,
    id: 'id1',
  },
  {
    name: 'Clean Room Integration',
    type: ProjectType.Campaign,
    hierarchy: null,
    startDate: null,
    endDate: null,
    id: 'id2',
  },
  { name: 'Coachella 2023', type: ProjectType.Campaign, hierarchy: null, startDate: null, endDate: null, id: 'id3' },
]

export const ProjectsWidget = () => (
  <Flex direction="column" gap={24} className={styles.projects}>
    <SystemProjectsWidget widget={{ title: 'Active Projects' } as ProjectsWidgetDTO} />
    <ProjectsWidgetCard isHierarchyEnabled={false} height={0}>
      <WidgetTitle>Upcoming Projects</WidgetTitle>
      <ProjectsWidgetList
        projects={upcomingProjectsMock}
        isHierarchyEnabled={false}
        readOnly
        detailsType={ProjectsWidgetDetailsType.ProjectType}
        height={0}
      />
    </ProjectsWidgetCard>
    <ProjectsWidgetCard isHierarchyEnabled={false} height={0}>
      <WidgetTitle>Recent Briefs</WidgetTitle>
      <ProjectsWidgetList
        projects={recentBriefsMock}
        isHierarchyEnabled={false}
        readOnly
        detailsType={ProjectsWidgetDetailsType.ProjectType}
        height={0}
      />
    </ProjectsWidgetCard>
  </Flex>
)
