import { AllwynWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type AllwynWidget = Omit<Widget, 'name'> & { name: AllwynWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'allwyn_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 4,
    gridColumnEnd: 5,
  },
  [AllwynWidgetType.CALENDAR]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 4,
    gridColumnEnd: 13,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 4,
    gridColumnEnd: 17,
  },
}

export const allwynWidgets: AllwynWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'allwyn_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[AllwynWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: AllwynWidgetType.CALENDAR,
    id: 'allwyn_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'allwyn_3',
    createdAt: '',
    updatedAt: '',
  },
]
