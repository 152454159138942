import { UserDetails, MayBeNull, AnalyticsActionType } from '@wpp-open/core'
import { useEffect, useMemo } from 'react'

import { environment } from 'environment'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { UserAssignedRoles } from 'types/users/users'
import { analytics, trackAnalytics } from 'utils/analytics'
import { noop } from 'utils/common'

export const useInitSegmentAnalytics = process.env.DEV
  ? noop
  : ({ userDetails, roles }: { userDetails: MayBeNull<UserDetails>; roles: UserAssignedRoles[] }) => {
      const { currentTenant } = useTenantAndUserData()

      const identifyPayload = useMemo(() => {
        if (userDetails) {
          const { agency, country, createdAt, firstname, lastname, email, id } = userDetails
          const domain = email.match(/(?:@).*(?=[.])/)![0].replace('@', '')

          return {
            id,
            firstname,
            lastname,
            email,
            agency,
            tenantAzId: currentTenant.azId,
            createdAt,
            country,
            domain,
            roles,
          }
        }

        return null
      }, [userDetails, currentTenant, roles])

      useEffect(() => {
        if (identifyPayload) {
          const init = async () => {
            const { id, ...rest } = identifyPayload

            await (analytics.plugins as any).segment.group(`v4-${environment.LEGACY_BOX_ENV}-${currentTenant.name}`, {
              tenantId: currentTenant.azId,
              environment: environment.LEGACY_BOX_ENV,
              osVersion: 'v4',
            })

            await analytics.identify(id, rest)

            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: `Tenant ${currentTenant.name}`,
            })
          }

          init()
        }
      }, [currentTenant.azId, currentTenant.name, identifyPayload])
    }
