import { WppIconPlay } from '@platform-ui-kit/components-library-react'

import videoImgSrc from 'assets/pitch/pfizer/home-image.png'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/videoWidget/VideoWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const VideoWidget = () => {
  return (
    <WidgetCard className={styles.card}>
      <img src={videoImgSrc} className={styles.image} alt="" />
      <Flex className={styles.playIcon} as={WppIconPlay} align="center" justify="center" />
    </WidgetCard>
  )
}
