import { usePrelaunchedInstanceApi } from 'src/api/apps/queries/usePrelaunchedInstanceApi'

import { is401Error, is403Error, is404Error, is5xxError } from 'api/utils'
import { CriticalError, NotFoundError } from 'components/renderError'
import { MiroAdminIssuesState } from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroAdminIssuesState'
import styles from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroBoardAppRenderer.module.scss'
import { MiroRestrictedState } from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroRestrictedState'
import { NoCodeAppLoading } from 'pages/appsNavigation/noCodeApp/noCodeAppLoading/NoCodeAppLoading'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppInstanceAssignmentType } from 'types/apps/appInstances'
import { NoCodeAppMiroBoard } from 'types/apps/noCodeApps'

export const MiroBoardAppRenderer = ({ app }: { app: NoCodeAppMiroBoard }) => {
  const { appData } = useOsState()

  const email =
    appData.appInstance?.assignmentType === AppInstanceAssignmentType.Project
      ? appData.appInstance?.context?.miroOwner
      : appData.appInstance?.createdBy

  const {
    isLoading,
    data: appInstance,
    error,
  } = usePrelaunchedInstanceApi({
    params: {
      instanceId: app.stableId,
    },
    enabled: !!app,
  })

  if (isLoading) {
    return <NoCodeAppLoading />
  }

  if (!app || is404Error(error)) {
    return <NotFoundError />
  }

  if (is5xxError(error)) {
    return <CriticalError />
  }

  if (is401Error(error)) {
    return <MiroAdminIssuesState email={email} />
  }

  if (is403Error(error)) {
    return <MiroRestrictedState email={email} />
  }

  // Fallback for any other unexpected errors
  if (!appInstance) {
    return <NotFoundError />
  }

  const {
    id,
    context: { boardId },
  } = appInstance

  if (!boardId) {
    return <NotFoundError />
  }

  return (
    <iframe
      key={id}
      sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      className={styles.root}
      src={`https://miro.com/app/live-embed/${boardId}/?autoplay=true`}
      allow="fullscreen; clipboard-read; clipboard-write"
      allowFullScreen
    />
  )
}
