import { WppListItem } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import styles from 'pages/home/widgetListItem/WidgetListItem.module.scss'

export const WidgetListItem = forwardRef<HTMLWppListItemElement, ComponentPropsWithoutRef<typeof WppListItem>>(
  ({ className, ...rest }, ref) => <WppListItem ref={ref} {...rest} className={clsx(styles.listItem, className)} />,
)

export const LoadingWidgetListItem = forwardRef<
  HTMLWppListItemElement,
  ComponentPropsWithoutRef<typeof WidgetListItem>
>(({ className, ...rest }, ref) => (
  <WidgetListItem ref={ref} {...rest} className={clsx(styles.loadingListItem, className)} />
))
