export interface Member {
  name: string
  role: string
  profileImg: string
}

export interface Team {
  name: string
  members: Member[]
}

export const teams: Team[] = [
  {
    name: 'Dashboarding & Reporting',
    members: [
      {
        name: 'Eguen Holler',
        role: 'Marketing Lead',
        profileImg: 'https://i.pravatar.cc/150?img=12',
      },
      {
        name: 'Betty Berry',
        role: 'Design Lead',
        profileImg: 'https://i.pravatar.cc/150?img=13',
      },
      {
        name: 'Anna Plus',
        role: 'UX Designer',
        profileImg: 'https://i.pravatar.cc/150?img=16',
      },
    ],
  },
  {
    name: 'Audience Solution',
    members: [
      {
        name: 'Howard Brook',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=33',
      },
      {
        name: 'Hank Howard',
        role: 'Design Lead',
        profileImg: 'https://i.pravatar.cc/150?img=52',
      },
      {
        name: 'Paul Molly',
        role: 'UX Designer',
        profileImg: 'https://i.pravatar.cc/150?img=21',
      },
    ],
  },
  {
    name: 'Tech & Tools',
    members: [
      {
        name: 'Alex Luis Felicio',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=57',
      },
      {
        name: 'Peter Paul Takil',
        role: 'Planning Lead',
        profileImg: 'https://i.pravatar.cc/150?img=59',
      },
      {
        name: 'Yousaf  Harps',
        role: 'Marketing',
        profileImg: 'https://i.pravatar.cc/150?img=32',
      },
    ],
  },
]
