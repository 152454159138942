import { WppIconApp, WppListItem } from '@platform-ui-kit/components-library-react'

import { BreadcrumbMenuAppItem } from 'layout/header/breadcrumbs/common/menuAppItem/BreadcrumbMenuAppItem'
import { useProjectBreadcrumbsApps } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { useData } from 'providers/data/DataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { ProjectCanvasApplicationShort } from 'types/projects/projectNavigation'
import { isValidHierarchyLevel } from 'utils/appInstances'

interface Props {
  canvasApp: ProjectCanvasApplicationShort
}

export const ProjectBreadcrumbsMenuAppItem = ({ canvasApp }: Props) => {
  const { navigationTree } = useData()
  const { appData } = useOsState()
  const { appsMap } = useProjectBreadcrumbsApps()

  const { app, activeWorkspaceId } = appData
  const projectApp = canvasApp.appInstanceId ? appsMap[canvasApp.appInstanceId] : null
  const canLaunch = isValidHierarchyLevel({
    app: projectApp,
    workspaceAzId: activeWorkspaceId,
    mapping: navigationTree.mapping,
  })

  if (projectApp && canLaunch) {
    const isChecked = projectApp.stableId === app?.stableId

    return (
      <div>
        <BreadcrumbMenuAppItem isChecked={isChecked} app={projectApp} customAppName={canvasApp.name} />
      </div>
    )
  }

  return (
    <div>
      <WppListItem disabled data-testid="breadcrumbs-menu-app-item">
        <WppIconApp slot="left" />
        <span slot="label">{canvasApp.name || projectApp?.name || canvasApp.externalAppName}</span>
      </WppListItem>
    </div>
  )
}
