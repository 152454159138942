import { lazy } from 'react'
import { Outlet, Route } from 'react-router-dom'

import { SuspenseLoader } from 'components/common/SuspenseLoader'
import { TENANT_NAME_WPP_PITCH_OS, TENANT_SUBDOMAIN_WPP } from 'constants/common'
import { TenantHostInfo } from 'types/tenants/tenant'

const PitchPlaybook = lazy(() => import('pages/wppPitchOsDashboard/pages/pitchPlaybook/PitchPlaybook'))
const NestleDemo = lazy(() => import('pages/wppOpenDashboard/pages/nestleDemo/NestleDemo'))
const VolkswagenDemo = lazy(() => import('pages/wppOpenDashboard/pages/volkswagenDemo/VolkswagenDemo'))
const BeiersdorfDemo = lazy(() => import('pages/wppOpenDashboard/pages/beiersdorfDemo/BeiersdorfDemo'))
const SpotifyDemo = lazy(() => import('pages/wppOpenDashboard/pages/spotifyDemo/SpotifyDemo'))
const MindshareDemo = lazy(() => import('pages/wppOpenDashboard/pages/mindshareDemo/MindshareDemo'))
const MSixDemo = lazy(() => import('pages/wppOpenDashboard/pages/mSixDemo/MSixDemo'))
const PitchSupport = lazy(() => import('pages/wppOpenDashboard/pages/pitchSupport/PitchSupport'))
const AldiDemo = lazy(() => import('pages/wppOpenDashboard/pages/aldi/AldiDemo'))
const AllianzDemo = lazy(() => import('pages/wppOpenDashboard/pages/allianz/AllianzDemo'))
const TcccDemo = lazy(() => import('pages/wppOpenDashboard/pages/tccc/TcccDemo'))

export const getHardCodedRoutes = ({ currentTenant }: TenantHostInfo) => {
  const { name, subdomain } = currentTenant

  // TODO [WPPLONOP-15160]: Hardcoded by tenant name
  const isWppPitchOsPageEnabled = name === TENANT_NAME_WPP_PITCH_OS
  const isWppOpenPageEnabled = subdomain === TENANT_SUBDOMAIN_WPP
  // NOTE: We don't care about inner routing calculation for AppRouter for this pages.
  // Child routes are hanled by <Routes> inside each page.
  return (
    <Route
      element={
        <SuspenseLoader>
          <Outlet />
        </SuspenseLoader>
      }
    >
      {isWppPitchOsPageEnabled && <Route path="pitch-playbook" element={<PitchPlaybook />} />}

      {isWppOpenPageEnabled && (
        <>
          <Route path="nestle/*" element={<NestleDemo />} />
          <Route path="volkswagen/*" element={<VolkswagenDemo />} />
          <Route path="beiersdorf/*" element={<BeiersdorfDemo />} />
          <Route path="spotify/*" element={<SpotifyDemo />} />
          <Route path="mindshare/*" element={<MindshareDemo />} />
          <Route path="msix/*" element={<MSixDemo />} />
          <Route path="pitch-support/*" element={<PitchSupport />} />
          <Route path="aldi/*" element={<AldiDemo />} />
          <Route path="allianz/*" element={<AllianzDemo />} />
          <Route path="tccc/*" element={<TcccDemo />} />
        </>
      )}
    </Route>
  )
}
