import { WppIconExternalLink, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { useWorkfrontAuthLink } from 'api/workfront/queries/auth/useFetchWorkfrontAuthLink'
import { useWorkfrontAuthRefresh } from 'api/workfront/queries/auth/useFetchWorkfrontAuthRefresh'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import IconWarnning from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/warnning-icon.svg'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import { useWorkfrontToken } from 'pages/home/components/pitch/playgroundDashboard/hooks/useWorkfrontToken'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SectionOauth = ({
  setWorkfrontToken,
  loading,
}: {
  setWorkfrontToken: (token: string) => void
  loading: boolean
}) => {
  const queryClient = useQueryClient()
  const { token, setAndSaveToken } = useWorkfrontToken()

  const [authLinkEnabled, setAuthLinkEnabled] = useState(false)
  const [refreshEnabled, setRefreshEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { response: responseLink } = useWorkfrontAuthLink({
    params: { redirectUri: `${window.location.origin}/user/profile/auth/workfront` },
    enabled: authLinkEnabled,
  })

  const { response: response } = useWorkfrontAuthRefresh({
    enabled: refreshEnabled,
  })

  useEffect(() => {
    if (!authLinkEnabled) return
    if (responseLink?.data.url) {
      window.open(responseLink.data.url, '_self')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseLink])

  useEffect(() => {
    if (!refreshEnabled) return
    if (response?.data.accessToken) {
      setAndSaveToken(response.data.accessToken)
      setWorkfrontToken(response.data.accessToken)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_PROJECTS] })
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const handleOauth = () => {
    if (isLoading) return
    if (token) {
      setRefreshEnabled(true)
      setIsLoading(true)
    } else {
      setAuthLinkEnabled(true)
      setIsLoading(true)
    }
  }

  return (
    <div>
      <Flex justify="center" align="center" className={clsx(stylesMain.pt150, stylesMain.mb150)}>
        <div>
          <Flex justify="center" align="center">
            <img src={IconWarnning} alt="" />
          </Flex>
          <Flex justify="center" align="center" className={clsx(stylesMain.blackH3Mobile, stylesMain.mt24)}>
            Nothing to show
          </Flex>
          <Flex justify="center" align="center" className={clsx(stylesMain.blackPMobile, stylesMain.mt5)}>
            Looks like your Workfront account isn’t connected to the Playground
          </Flex>
          <Flex justify="center" align="center" className={stylesMain.mt24}>
            <div onClick={() => handleOauth()}>
              <Button
                text="Connect to Workfront"
                variant="black"
                iconRight={isLoading || loading ? <WppSpinner color="white" /> : <WppIconExternalLink color="white" />}
              />
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
