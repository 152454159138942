import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import card1 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/cards/card1.png'
import card2 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/cards/card2.png'
import card3 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/cards/card3.png'
import card4 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/cards/card4.png'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/swiperCards/SwiperCards.module.scss'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SwiperCards = () => {
  const [realIndex, setRealIndex] = useState<number>(1)

  const cards = [
    {
      image: card1,
      category: 'INNOVATION',
      title: 'Coca-Cola’s AI Commercial Takes Internet by Storm',
      author: 'Bailey Parkerson',
    },
    {
      image: card2,
      category: 'ART',
      title: 'TikTok Establishes Youth Council',
      author: 'Benjy Hansen-Bundy',
    },
    {
      image: card3,
      category: 'ART',
      title: 'TikTok Establishes Youth Council',
      author: 'Benjy Hansen-Bundy',
    },
    {
      image: card4,
      category: 'TECH',
      title: 'Is Coca-Cola Vega',
      author: 'James Nerth',
    },
  ]

  const [cardsDisplayed, setCardsDisplayed] = useState<any[]>(cards)

  const classes = ['firstContainer', 'secondContainer', 'thirdContainer', 'fourthContainer']

  const handlePrev = () => {
    if (realIndex === 1) {
      setRealIndex(4)
    } else {
      setRealIndex(realIndex - 1)
    }
    const tmp = [...cardsDisplayed]
    const lastElement = tmp.pop()
    setCardsDisplayed([lastElement, ...tmp])
  }

  const handleNext = () => {
    if (realIndex === 4) {
      setRealIndex(1)
    } else {
      setRealIndex(realIndex + 1)
    }
    const tmp = [...cardsDisplayed]
    const firstElement = tmp.shift()
    tmp.push(firstElement)
    setCardsDisplayed(tmp)
  }

  return (
    <div>
      <Flex
        className={clsx(stylesMain.mt30, styles.flexBaseWidth, stylesMain.relative)}
        align="center"
        justify="between"
        gap={0}
      >
        {cardsDisplayed.map((card, index) => (
          <div key={index} className={styles[classes[index]]}>
            <img src={card.image} alt="" />
            <Flex className={styles.innerContainer} align="end">
              <div>
                <div className={styles.eyebrow}>{card.category}</div>
                <div className={clsx(stylesMain.whiteH2Desktop, stylesMain.preWrap, stylesMain.mt16)}>{card.title}</div>
                <div className={clsx(stylesMain.whiteAction, stylesMain.mt16)}>{card.author}</div>
              </div>
            </Flex>
          </div>
        ))}

        <img src={card1} alt="" className={styles.hidden} />
      </Flex>

      <Flex className={clsx(stylesMain.mt30, styles.flexBaseWidth)} align="center" justify="center" gap={27}>
        <div className={styles.arrowButton} onClick={handlePrev}>
          <WppIconArrow direction="left" color="white" />
        </div>

        <div className={stylesMain.whiteAction}>{realIndex}/4</div>

        <div className={styles.arrowButton} onClick={handleNext}>
          <WppIconArrow direction="right" color="white" />
        </div>
      </Flex>
    </div>
  )
}
