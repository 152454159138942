import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import imgSrc from 'pages/home/components/pitch/perrigoDashboard/components/capabilitiesWidget/assets/image.png'
import styles from 'pages/home/components/pitch/perrigoDashboard/components/capabilitiesWidget/CapabilitiesWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const CapabilitiesWidget = () => (
  <Link to="/process/custom">
    <WidgetCard className={styles.card}>
      <Flex gap={4} direction="column" className={styles.perrigoProcessCardHeader}>
        <WppTypography type="s-strong">Capabilities</WppTypography>
        <WppTypography type="s-body">Global Operating System</WppTypography>
      </Flex>
      <img src={imgSrc} className={styles.image} alt="" />
    </WidgetCard>
  </Link>
)
