import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { team } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/teamMock'
import styles from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/TeamWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const TeamWidget = () => (
  <WidgetCard className={styles.vwChinaTeamCard}>
    <WppTypography type="xl-heading">The Team</WppTypography>
    <div className={styles.vwChinaTeamsContainer}>
      <div className={styles.vwChinaTeamMembersGrid}>
        {team.map((teamMember, index) => (
          <Flex gap={6} direction="column" align="start" className={styles.vwChinaTeamMemberWrapper} key={index}>
            <WppTypography type="s-strong">{teamMember.name}</WppTypography>
            <Flex gap={12} align="start" className={styles.vwChinaTeamMemberContainer}>
              <img src={teamMember.profileImgSrc} alt="" className={styles.vwChinaTeamMemberImg} />
              <div className={styles.vwChinaTeamRole}>
                <WppTypography type="xs-body">{teamMember.role}</WppTypography>
              </div>
            </Flex>
          </Flex>
        ))}
      </div>
    </div>
  </WidgetCard>
)
