import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { useNewsApi } from 'api/news/queries/useNewsApi'
import styles from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch.module.scss'
import { SvgImageLoadError } from 'pages/home/components/pitch/svg/SvgImageLoadError'
import { NewsWidgetCardType } from 'pages/home/systemWidgets/newsWidget/constants'
import { NewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/NewsPieceCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const NewsWidgetPitch = ({
  newsCount,
  tenantId,
  inline,
}: {
  newsCount: number
  inline?: boolean
  tenantId?: string
}) => {
  const { currentTenant } = useTenantAndUserData()

  const {
    isLoading,
    error,
    data: news,
  } = useNewsApi({
    params: {
      tenantId: tenantId || currentTenant.azMeta.organizationsId,
    },
  })

  if (!news.length) {
    return (
      <WppCard className={styles.card}>
        {isLoading ? (
          <WppSpinner size="m" />
        ) : (
          <>
            <SvgImageLoadError />
            <WppTypography type="s-midi" data-testid="news-empty-message">
              {error ? 'Unable to display News' : 'No News found'}
            </WppTypography>
          </>
        )}
      </WppCard>
    )
  }

  return (
    <div className={clsx(styles.container, styles[`default-${newsCount}`], { [styles.inline]: inline })}>
      {news.slice(0, newsCount).map(newsPiece => (
        <NewsPieceCard key={newsPiece.guid} newsPiece={newsPiece} cardType={NewsWidgetCardType.STANDARD} />
      ))}
    </div>
  )
}
