import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import commonStyles from 'pages/home/common/common.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const FavouriteLinksWidgetCard = forwardRef<HTMLWppCardElement, ComponentPropsWithoutRef<typeof WidgetCard>>(
  ({ className, ...rest }, ref) => (
    <WidgetCard
      ref={ref}
      {...rest}
      className={clsx(commonStyles.listWidgetCard, className)}
      data-testid="favourite-links-widget"
    />
  ),
)
