import styles from 'pages/home/components/pitch/axaDashboard/AxaDashboard.module.scss'
import { ImageWidget } from 'pages/home/components/pitch/axaDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/axaDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/axaDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/axaDashboard/components/userWidget/UserWidget'
import { axaWidgets } from 'pages/home/components/pitch/axaDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { AxaWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | AxaWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case AxaWidgetType.IMAGE:
      return <ImageWidget />
    case AxaWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/06/26'),
              getText: () => 'Alice Smith invited you to  Media Campaign Summer 2023',
            },
            {
              getDate: () => new Date('2023/06/27'),
              getText: () => 'Hanna Heine assigned Prepare creative brief for Coppertone Summer to you',
            },
            {
              getDate: () => new Date('2023/06/29'),
              getText: () =>
                'Alice Smith invited you to Media Campaign Summer 2023\n\nAlice Smith updated task Review creatives for Summer 2023 status to “Approved”\n\nHannah Heine assigned Prepare creative brief for Seat France to you\n\nHannah Heine invited you to project Seat media workshop',
            },
          ]}
          clockOptions={[{ timeZone: 'Europe/Budapest', city: 'Wolfsburg' }]}
        />
      )
    case AxaWidgetType.PROCESS:
      return <ProcessWidget />
    case AxaWidgetType.USER:
      return <UserWidget />
    case AxaWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const AxaDashboard = () => (
  <div className={styles.container}>
    {axaWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
