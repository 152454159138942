import { UberWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type UberWidget = Omit<Widget, 'name'> & { name: UberWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'uber_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [UberWidgetType.BRAND]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [UberWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [UberWidgetType.CALENDAR]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [UberWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [UberWidgetType.TEAM]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const uberWidgets: UberWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'uber_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[UberWidgetType.BRAND],
    ...commonWidgetsConfig,
    name: UberWidgetType.BRAND,
    id: 'uber_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[UberWidgetType.USER],
    ...commonWidgetsConfig,
    name: UberWidgetType.USER,
    id: 'uber_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[UberWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: UberWidgetType.CALENDAR,
    id: 'uber_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[UberWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: UberWidgetType.PROCESS,
    id: 'uber_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[UberWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: UberWidgetType.TEAM,
    id: 'uber_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'uber_7',
    createdAt: '',
    updatedAt: '',
  },
]
