import { WppSelect, WppListItem, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import Post1ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/assets/post_1.png'
import Post2ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/assets/post_2.png'
import Post3ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/assets/post_3.png'
import Post4ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/assets/post_4.png'
import Post5ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/assets/post_5.png'
import styles from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/PostsWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const periodSelectionOptions = [
  { value: 'today', label: 'Today' },
  { value: 'this_month', label: 'This month' },
  { value: 'last_3_month', label: 'Last 3 month' },
  { value: 'last_year', label: 'Last year' },
]

const locationSelectionOptions = [
  { value: 'france', label: 'France' },
  { value: 'poland', label: 'Poland' },
  { value: 'spain', label: 'Spain' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'romania', label: 'Romania' },
]

export const PostsWidget = () => (
  <WidgetCard className={styles.postsCard}>
    <WppTypography type="m-strong">New final assets posted</WppTypography>
    <div className={styles.postsContainer}>
      <Flex gap={12}>
        <WppSelect onWppChange={() => {}} value="last_3_month">
          {periodSelectionOptions.map(option => (
            <WppListItem key={option.value} value={option.value}>
              <p slot="label">{option.label}</p>
            </WppListItem>
          ))}
        </WppSelect>
        <WppSelect onWppChange={() => {}} value="france">
          {locationSelectionOptions.map(option => (
            <WppListItem key={option.value} value={option.value}>
              <p slot="label">{option.label}</p>
            </WppListItem>
          ))}
        </WppSelect>
      </Flex>
      <div className={styles.postsGrid}>
        <img src={Post1ImgSrc} alt="" className={styles.postItemImg} />
        <img src={Post2ImgSrc} alt="" className={styles.postItemImg} />
        <img src={Post3ImgSrc} alt="" className={styles.postItemImg} />
        <img src={Post4ImgSrc} alt="" className={styles.postItemImg} />
        <img src={Post5ImgSrc} alt="" className={styles.postItemImg} />
      </div>
    </div>
  </WidgetCard>
)
