import { utils, Layout } from 'react-grid-layout'

import { DASHBOARD_COLS_COUNT, COMPACT_TYPE } from 'constants/homePage'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'

/**
 * Extend 3rd party '@types/react-grid-layout' package as it's not contain all library exports typings
 * Feel free to add more.
 *
 * You can find all possible utility functions here:
 * https://github.com/react-grid-layout/react-grid-layout/blob/master/lib/utils.js
 *  */
declare module 'react-grid-layout' {
  export const utils: {
    correctBounds: (layout: Layout[], bounds: { cols: number }) => Layout[]
    compact: (layout: Layout[], compactType: Exclude<CoreProps['compactType'], undefined>, cols: number) => Layout[]
  }
}

// Same autopositioning algorithm as component internals use for non overlaping layouts
// https://github.com/react-grid-layout/react-grid-layout/blob/master/lib/utils.js#L927C7-L927C51
export const compactLayout = (layout: Layout[]): Layout[] =>
  utils.compact(
    utils.correctBounds(layout, {
      cols: DASHBOARD_COLS_COUNT,
    }),
    COMPACT_TYPE,
    DASHBOARD_COLS_COUNT,
  )

export const useHasAccessToManageWidgets = () => {
  const { hasPermission } = useHasPermission()
  return hasPermission(Permission.OS_WIDGETS_ENTITIES_MANAGE)
}

export const isCompactEmptyOrErrorState = ({ width, height }: { width: number; height: number }) =>
  width === 1 || height === 1
