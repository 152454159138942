import {
  WppCard,
  WppFloatingButton,
  WppIconExtension,
  WppIconSymbols,
  WppIconService,
  WppIconQa,
  WppIconSparkle,
  WppTypography,
  WppButton,
} from '@platform-ui-kit/components-library-react'
// import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pitch/aboutProjectWidget/AboutProjectWidget.module.scss'

export const AboutProjectWidget = () => {
  const projectItems = [
    { description: 'Onboarded tour to better immerse you in the system', icon: <WppIconSparkle /> },
    { description: 'All tools you usually use are located in one place', icon: <WppIconService /> },
    { description: 'Seamless & effective communication for your team', icon: <WppIconQa /> },
    { description: 'Advanced customisation tailored for your brand', icon: <WppIconSymbols /> },
    { description: 'Perfect playground for creating scenarios to fit your needs', icon: <WppIconExtension /> },
  ]

  return (
    <WppCard className={styles.container}>
      <div className={styles.content}>
        <WppTypography type="3xl-heading">Try Open: Test Drive our Demo Site</WppTypography>
        <WppTypography type="m-body" className={styles.description}>
          Welcome to our Demo Site, where you can test drive Open and explore its capabilities! This is a generic site
          that allows you to see how the platform works and what it can do for your business.
        </WppTypography>
        <div className={styles.projectItems}>
          {projectItems.map(project => (
            <Flex direction="column" key={project.description} className={styles.projectItem}>
              <WppFloatingButton className={styles.icon}>{project.icon}</WppFloatingButton>
              <WppTypography type="m-midi" className={styles.projectDescription}>
                {project.description}
              </WppTypography>
            </Flex>
          ))}
        </div>
        <Flex direction="column" align="start" gap={10}>
          <WppTypography type="m-body">
            Go ahead see how Open can transform your business processes and help you achieve your goals.
          </WppTypography>
          {/* <Link to="https://demo.wppopen.com/"> */}
          <WppButton variant="secondary" className={styles.button} disabled>
            <WppTypography type="s-strong" className={styles.buttonLabel}>
              Go to Demo Site
            </WppTypography>
          </WppButton>
          {/* </Link> */}
        </Flex>
      </div>
    </WppCard>
  )
}
