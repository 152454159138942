import { WppIconDocument, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { CentreOfExcellenceLink } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/centreOfExcallenceDataMock'
import styles from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/components/centreOfExcellenceLinkCard/CentreOfExcellenceLinkCard.module.scss'

interface Props {
  link: CentreOfExcellenceLink
}

export const CentreOfExcellenceLinkCard = ({ link }: Props) => (
  <a href={link.url} className={styles.link}>
    <Flex align="center">
      <WppIconDocument color="--wpp-grey-color-800" />
      <WppTypography type="s-body" className={styles.linkLabel}>
        {link.label}
      </WppTypography>
    </Flex>
  </a>
)
