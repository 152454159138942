import { facadeApi } from 'api'
import { PaginationParams } from 'api/common/types'
import { Paginated } from 'types/common/utils'
import { WidgetProject } from 'types/projects/widgetProject'

type Params = PaginationParams<{
  includeHierarchy?: boolean
}>

export type WidgetPropjectsResponse = Paginated<WidgetProject>

export const fetchWidgetProjectsApi = ({ page, itemsPerPage, includeHierarchy }: Params) =>
  facadeApi.get<WidgetPropjectsResponse>('/widgets/projects/projects', {
    params: {
      page,
      itemsPerPage,
      includeHierarchy,
    },
  })
