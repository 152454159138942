import { WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'

import avatarImgSrc from 'assets/pitch/pfizer/avatar.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/ProjectManagementWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const workfrontOptions = [
  {
    label: 'Creative Workfront tutorial 2023',
    link: '#',
  },
  {
    label: 'Creative Summer 2023',
    link: '#',
  },
  {
    label: 'Planning winter campaign 2022',
    link: '#',
  },
  {
    label: 'Winter campaign 2022',
    link: '#',
  },
]

export const WorkfrontContent = () => (
  <WidgetCard className={styles.projectContent}>
    <Flex gap={15} direction="column" className={styles.listContainer}>
      {workfrontOptions.map((option, idx) => (
        <a key={idx} className={styles.link} href={option.link}>
          <img src={avatarImgSrc} alt="" />
          <WppTypography type="s-body">{option.label}</WppTypography>
        </a>
      ))}
    </Flex>

    <WppActionButton variant="primary" className={styles.actionButton}>
      Go to Workfront
    </WppActionButton>
  </WidgetCard>
)
