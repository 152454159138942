import clsx from 'clsx'
import { useMemo } from 'react'

import styles from 'pages/playground/components/progress/Progress.module.scss'
import stylesMain from 'pages/playground/Playground.module.scss'

const FULL_DASH_ARRAY = 283

interface Props {
  value: number
  variant?: 'small' | 'medium' | 'big'
  text?: string
  className?: string
}
// TODO if needed add size prop

enum VariantClasses {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

const ColorClasses = {
  green: styles.elapsedGreen,
  yellow: styles.elapsedYellow,
  orange: styles.elapsedOrange,
  red: styles.elapsedRed,
}

const styleVariants = {
  [VariantClasses.small]: {
    timer: styles.baseTimer,
    timerSvg: styles.baseTimerSvg,
    timerCircle: styles.baseTimerCircle,
    timerElapsed: styles.baseTimerElapsed,
    timerPathRemaining: styles.baseTimerPathRemaining,
    timerLabel: styles.baseTimerLabel,
  },
  [VariantClasses.medium]: {
    timer: styles.baseTimerMedium,
    timerSvg: styles.baseTimerMediumSvg,
    timerCircle: styles.baseTimerMediumCircle,
    timerElapsed: styles.baseTimerMediumElapsed,
    timerPathRemaining: styles.baseTimerMediumPathRemaining,
    timerLabel: styles.baseTimerMediumLabel,
  },
  [VariantClasses.big]: {
    timer: styles.baseTimerBig,
    timerSvg: styles.baseTimerBigSvg,
    timerCircle: styles.baseTimerBigCircle,
    timerElapsed: styles.baseTimerBigElapsed,
    timerPathRemaining: styles.baseTimerBigPathRemaining,
    timerLabel: styles.baseTimerBigLabel,
  },
}

export const Progress = ({ value, variant = 'small', text = '', className }: Props) => {
  const colorClass = useMemo(() => {
    return value >= 75 || variant === 'small'
      ? ColorClasses.green
      : value >= 50
        ? ColorClasses.yellow
        : value >= 25
          ? ColorClasses.orange
          : ColorClasses.red
  }, [value, variant])

  const number = useMemo(() => {
    return variant === 'small' ? value : 100 - value
  }, [value, variant])

  const progress = `${((number / 100) * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`

  const styleVariant = styleVariants[variant]

  return (
    <div className={clsx(styleVariant.timer, className)}>
      <svg className={styleVariant.timerSvg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g className={styleVariant.timerCircle}>
          <circle
            className={clsx(styleVariant.timerElapsed, variant !== 'small' && colorClass)}
            cx="50"
            cy="50"
            r="45"
          />
          <path
            strokeDasharray={progress}
            className={clsx(styleVariant.timerPathRemaining, variant === 'small' && colorClass)}
            d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
            "
          />
        </g>
      </svg>
      <div className={clsx(styleVariant.timerLabel)}>
        {variant === 'small' && <div className={stylesMain.whiteH4Bold}>{`${value}%`}</div>}
        {variant === 'medium' && (
          <div>
            <div className={styles.baseTimerMediumLabelPercent}>{`${value}%`}</div>
            <div className={clsx(stylesMain.baseBold, stylesMain.preWrap, stylesMain['text-center'])}>{text}</div>
          </div>
        )}
        {variant === 'big' && (
          <div>
            <div className={clsx(styles.baseTimerBigLabelPercent)}>{`${value}%`}</div>
            <div className={clsx(stylesMain.whiteH3, stylesMain.preWrap, stylesMain['text-center'])}>{text}</div>
          </div>
        )}
      </div>
    </div>
  )
}
