import { WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { CollaborationContent } from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/CollaborationContent'
import { JiraContent } from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/JiraContent'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/ProjectManagementWidget.module.scss'
import { WorkfrontContent } from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/WorkfrontContent'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

type ProjectKeys = 'workfront' | 'collaboration' | 'jira'

type Projects = {
  [key in ProjectKeys]: key
}

const projects: Projects = {
  workfront: 'workfront',
  collaboration: 'collaboration',
  jira: 'jira',
}

interface TabOption {
  value: ProjectKeys
  label: string
}

const tabOptions: TabOption[] = [
  {
    value: projects.workfront,
    label: 'Workfront',
  },
  {
    value: projects.collaboration,
    label: 'Collaboration',
  },
  {
    value: projects.jira,
    label: 'Jira',
  },
]

export const ProjectManagementWidget = () => {
  const [activeSegment, setActiveSegment] = useState<ProjectKeys>(projects.workfront)

  let ContentManagement

  switch (activeSegment) {
    case projects.workfront:
      ContentManagement = WorkfrontContent
      break
    case projects.collaboration:
      ContentManagement = CollaborationContent
      break
    default:
      ContentManagement = JiraContent
  }

  return (
    <WidgetCard className={styles.card}>
      <WppSegmentedControl
        size="m"
        value={activeSegment}
        onWppChange={({ detail }) => {
          setActiveSegment(detail.value as ProjectKeys)
        }}
      >
        {tabOptions.map(option => (
          <WppSegmentedControlItem key={option.value} value={option.value}>
            {option.label}
          </WppSegmentedControlItem>
        ))}
      </WppSegmentedControl>
      <ContentManagement />
    </WidgetCard>
  )
}
