import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Fragment, ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/appsTreeList/AppsTreeList.module.scss'
import { useGroupAppInstances } from 'components/treeList/appsTreeList/utils'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'
import { AppInstanceShort } from 'types/apps/appInstances'

interface Props {
  isLoading?: boolean
  appInstances: AppInstanceShort[]
  renderApp: (appInstance: AppInstanceShort) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
}

export const AppsTreeList = ({
  isLoading = false,
  appInstances,
  renderApp,
  emptyState = null,
  action = null,
}: Props) => {
  const { ungrouped, grouped } = useGroupAppInstances({ appInstances })

  if (isLoading) {
    return <LoadingTreeList />
  }

  return (
    <Flex direction="column" gap={8} data-testid="apps-tree-list">
      {!!ungrouped.length && (
        <Flex direction="column" gap={2} data-testid="ungrouped-apps">
          {ungrouped.map(appInstance => (
            <Fragment key={appInstance.id}>{renderApp(appInstance)}</Fragment>
          ))}
        </Flex>
      )}

      {grouped.map(({ group, appInstances }) => (
        <Flex key={group.id} className={styles.group} gap={6} direction="column" data-testid="app-group">
          <WppTypography className={styles.groupHeader} type="m-strong">
            {group.name}
          </WppTypography>

          <Flex gap={2} direction="column">
            {appInstances.map(appInstance => (
              <Fragment key={appInstance.id}>{renderApp(appInstance)}</Fragment>
            ))}
          </Flex>
        </Flex>
      ))}

      {!appInstances.length && emptyState}
      {action}
    </Flex>
  )
}
