import { faker } from '@faker-js/faker'
import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import * as echarts from 'echarts'
import { useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectTrackingWidget/overalProjectStatisticTrack/OveralProjectStatisticTrack.module.scss'

interface StatisticProps {
  active?: number
  completed?: number
  archived?: number
  total?: number
  isLoading: boolean
}

type EChartsOption = echarts.EChartsOption

export const OveralProjectStatisticTrack = ({
  active = 0,
  completed = 0,
  archived = 0,
  total = 0,
  isLoading = true,
}: StatisticProps) => {
  const elId = `overal-${faker.database.mongodbObjectId()}`
  const chartDom = document.getElementById(elId)!

  useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        const el = document.getElementById(elId)!
        const myChart = el && echarts.init(el)
        const option: EChartsOption = {
          color: ['#6295e4', '#a884df', '#e971a5'],
          series: [
            {
              type: 'pie',
              radius: ['65%', '95%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 40,
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: active, name: 'Active' },
                { value: completed, name: 'Completed' },
                { value: archived, name: 'Archived' },
              ],
            },
          ],
        }
        option && el && myChart.setOption(option)
        clearTimeout(delay)
      }, 1000)
    }
  }, [elId, chartDom, active, completed, archived, isLoading])

  return (
    <WppCard className={styles.container}>
      <Flex direction="column" gap={15} className={styles.content}>
        <WppTypography type="s-strong">Overall Project Statistics</WppTypography>
        <Flex direction="column" gap={6} className={isLoading ? styles.hide : undefined}>
          <Flex justify="center">
            <div id={elId} className={styles.chart} />
            <Flex align="center" justify="center" direction="column" className={styles.legend}>
              <WppTypography type="2xl-heading">{((completed / total) * 100).toFixed(0) ?? 0}%</WppTypography>
              <WppTypography type="s-body">Completed</WppTypography>
            </Flex>
          </Flex>
          <Flex gap={8}>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.active)} />
                <WppTypography type="s-midi" className={styles.description}>
                  Active
                </WppTypography>
                <WppTypography type="l-strong">
                  {active}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.completed)} />
                <WppTypography type="s-midi" className={styles.description}>
                  Completed
                </WppTypography>
                <WppTypography type="l-strong">
                  {completed}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.archived)} />
                <WppTypography type="s-midi" className={styles.description}>
                  Archived
                </WppTypography>
                <WppTypography type="l-strong">
                  {archived}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
          </Flex>
        </Flex>
        {isLoading && (
          <Flex align="center" justify="center" className={styles.loading}>
            <WppSpinner size="l" />
          </Flex>
        )}
      </Flex>
    </WppCard>
  )
}
