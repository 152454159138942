import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useFetchAppInstancesApi } from 'api/apps/queryFetchers/useFetchAppInstancesApi'
import { AppInstancesDefaults } from 'constants/appInstances'
import { Delay } from 'constants/delay'
import { PageSize } from 'constants/pageSize'
import { useStableCallback } from 'hooks/useStableCallback'
import { LegacyOpenAppHandler } from 'legacy/types/context'
import { useData } from 'providers/data/DataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useToast } from 'providers/toast/ToastProvider'
import { LegacyMicroAppConfig, MicroAppType } from 'types/apps/microApps'
import { mapAppInstanceToApp } from 'utils/appInstances'
import { simulateLinkClick } from 'utils/links'
import { getAppInstanceUrl } from 'utils/navigation'

export const useOpenLegacyApp = (): LegacyOpenAppHandler => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { appData } = useOsState()
  const { navigationTree } = useData()

  const handleFetchAppInstances = useFetchAppInstancesApi({
    gcTime: AppInstancesDefaults.LegacyAppLaunchListGCTime,
  })

  const { rootId } = navigationTree
  const { activeWorkspaceId, appInstance } = appData

  return useStableCallback(async (windowLibraryName, target = '_self', subPath = '') => {
    try {
      const {
        data: { data: appInstances },
      } = await handleFetchAppInstances({
        assignmentId: appInstance?.assignmentId || activeWorkspaceId || rootId,
        itemsPerPage: PageSize.All,
      })

      const apps = appInstances.map(appInstance => mapAppInstanceToApp(appInstance))
      const app = apps
        .filter((app): app is LegacyMicroAppConfig => app.type === MicroAppType.Legacy)
        .find(app => app.windowLibraryName === windowLibraryName)

      if (!app) {
        enqueueToast({
          duration: Delay.ToastLong,
          message: t('os.legacy_errors.open_app_not_found'),
          type: 'error',
        })

        return
      }

      const appUrl = getAppInstanceUrl({
        id: app.stableId,
        osRoute: app.osRoute,
      })
      const href = `${appUrl}${subPath}`

      if (target === '_blank') {
        simulateLinkClick({ href, target })
      } else {
        navigate(href)
      }
    } catch (error) {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    }
  })
}
