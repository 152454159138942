import draw1 from 'assets/pitch/drawing/draw_1.png'
import draw2 from 'assets/pitch/drawing/draw_2.png'
import draw3 from 'assets/pitch/drawing/draw_3.png'
import draw4 from 'assets/pitch/drawing/draw_4.png'
import draw5 from 'assets/pitch/drawing/draw_5.png'

export interface Drawing {
  title: string
  subtitle: string
  content: string
  subContent: string
  backgroundImage: string
}

export const drawingList: Drawing[] = [
  {
    title: 'This Saturday 27.05.2023',
    subtitle: 'LOTTO',
    content: 'It must be won!',
    subContent: '£9M',
    backgroundImage: draw1,
  },
  {
    title: 'Next Monday 29.05.2023',
    subtitle: 'SET FOR LIFE',
    content: '',
    subContent: 'WIN £10,000 EVERY MONTH FOR 30 YEARS',
    backgroundImage: draw4,
  },
  {
    title: 'This Friday 26.05.2023',
    subtitle: 'EUROMILLIONS',
    content: 'Jackpot',
    subContent: '£67M',
    backgroundImage: draw2,
  },
  {
    title: 'This Friday 26.05.2023',
    subtitle: 'THUNDERBALL',
    content: '£1 could win you',
    subContent: '£500K',
    backgroundImage: draw3,
  },
  {
    title: 'Featured game',
    subtitle: 'INSTANT WIN GAMES',
    content: 'Lucky Puck Shot Gold',
    subContent: 'Up to £300,000',
    backgroundImage: draw4,
  },
  {
    title: 'This Friday 26.05.2023',
    subtitle: 'HOTPICKS',
    content: 'Pick & match 3 balls, win',
    subContent: '£1M',
    backgroundImage: draw5,
  },
]
