import IconCamera from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/camera.svg'
import IconMessage from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/comment.svg'
import IconCookie from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/cookie.svg'
import IconHearth from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/heart.svg'
import IconMichrophone from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/mic.svg'
import IconPeople from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/people.svg'

export const firstRow = [
  {
    title: 'Socialize',
    points: 15,
    caption: 'Join us for Happy\nHour, RSVP Today!',
    img: IconPeople,
  },
  {
    title: 'Share',
    points: 10,
    caption: 'Share your pics from\nFoundation Day!',
    img: IconCamera,
  },
  {
    title: 'Praise',
    points: 10,
    caption: 'Give a shout out to\nyour team members.',
    img: IconMichrophone,
  },
]

export const secondRow = [
  {
    title: 'Meet',
    points: 15,
    caption: 'Come meet our new\nhires. RSVP Today!',
    img: IconCookie,
  },
  {
    title: 'Celebrate',
    points: 15,
    caption: 'Check out team\nBirthdays!',
    img: IconHearth,
  },
  {
    title: 'Participate',
    points: 15,
    caption: 'Fill out our annual\nMareteer survey.',
    img: IconMessage,
  },
]
