import { WppMenuContext, WppListItem, WppMenuGroup } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { ParseKeys } from 'i18next'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, Location, useNavigate } from 'react-router-dom'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import styles from 'layout/header/userProfileIconMenu/UserProfileIconMenu.module.scss'
import { useAuth } from 'providers/auth/AuthContext'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { trackAnalytics } from 'utils/analytics'
import { excludeFalsy, join } from 'utils/common'
import { EVENTS, getEventPayload } from 'utils/events'
import { routesManager } from 'utils/routesManager'

interface MenuItem {
  link: string
  label: ParseKeys
  disabled?: boolean
  permission?: string
  isActive?: (location: Location) => boolean
}

const adminSection: MenuItem[] = [
  {
    link: routesManager.admin.settings.url(),
    label: 'os.header.user_avatar_dropdown_menu.os_settings',
    permission: Permission.OS_ADMIN_SETTING_ACCESS,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.admin.root.url()),
  },
]

const youSection: MenuItem[] = [
  {
    link: routesManager.user.profile.url(),
    label: 'os.header.user_avatar_dropdown_menu.profile',
    permission: Permission.OS_USERDETAILS_APP_ACCESS,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.user.root.url()),
  },
]

export const UserProfileIconMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userDetails } = useTenantAndUserData()
  const { hasPermission } = useHasPermission()
  const { logout } = useAuth()
  const { t } = useTranslation()

  const { firstname, lastname, avatarOriginal, avatarThumbnail } = userDetails
  const keys = [avatarOriginal, avatarThumbnail].filter(excludeFalsy).map(({ key }) => key)

  const { data: avatarsDownloadInfo } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys },
    enabled: !!keys.length,
  })
  const [avatarOriginalInfo, avatarThumbnailInfo] = avatarsDownloadInfo
  const avatarUrl = avatarThumbnailInfo?.signed_url || avatarOriginalInfo?.signed_url

  const renderItems = (menuItems: MenuItem[]) =>
    menuItems
      .filter(({ permission }) => hasPermission(permission))
      .map(({ link, label, disabled = false, isActive = ({ pathname }) => !!matchPath(link, pathname) }) => {
        const translation = t(label) as string

        return (
          <WppListItem
            key={translation}
            data-testid={translation}
            disabled={disabled}
            className={clsx({
              [styles.active]: isActive(location),
            })}
            linkConfig={{
              href: link,
              onClick: e => {
                e.preventDefault()
                trackAnalytics({
                  type: AnalyticsActionType.page,
                  payload: EVENTS.ADMIN.PAGE.OS_SETTINGS,
                })
                trackAnalytics({
                  type: AnalyticsActionType.action,
                  payload: getEventPayload(EVENTS.ADMIN.ACTIONS.OPEN_TAB, 'Settings'),
                })
                navigate(link)
              },
            }}
          >
            <span slot="label" className={styles.listItemLabel}>
              {translation}
            </span>
          </WppListItem>
        )
      })

  const menuUserName = join([firstname, lastname?.[0]], ' ')
  const adminItems = renderItems(adminSection)

  return (
    <WppMenuContext listWidth="200px" data-testid="user-menu">
      <div slot="trigger-element" className={styles.avatarContainer}>
        <Avatar name={firstname} src={avatarUrl} size="s" className={styles.avatar} />
      </div>
      {!!adminItems.length && (
        <WppMenuGroup
          className={styles.menuGroup}
          header={t('os.header.user_avatar_dropdown_menu.admin_section_header')}
        >
          {adminItems}
        </WppMenuGroup>
      )}
      <WppMenuGroup className={styles.menuGroup} withDivider header={menuUserName}>
        {renderItems(youSection)}
      </WppMenuGroup>

      <WppListItem
        onWppChangeListItem={() => {
          logout()
        }}
      >
        <span slot="label">{t('os.header.user_avatar_dropdown_menu.log_out')}</span>
      </WppListItem>
    </WppMenuContext>
  )
}
