import { BavWidget } from 'pages/home/components/pitch/orangeDashboard/components/bavWidget/BavWidget'
import { BrandWidget } from 'pages/home/components/pitch/orangeDashboard/components/brandWidget/BrandWidget'
import { MilestonesWidget } from 'pages/home/components/pitch/orangeDashboard/components/milestonesWidget/MilestonesWidget'
import { PostsWidget } from 'pages/home/components/pitch/orangeDashboard/components/postsWidget/PostsWidget'
import { ProcessWidget } from 'pages/home/components/pitch/orangeDashboard/components/processWidget/ProcessWidget'
import { SocialActivityWidget } from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/SocialActivityWidget'
import { UserWidget } from 'pages/home/components/pitch/orangeDashboard/components/userWidget/UserWidget'
import styles from 'pages/home/components/pitch/orangeDashboard/OrangeDashboard.module.scss'
import { orangeWidgets } from 'pages/home/components/pitch/orangeDashboard/widgetsMock'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { OrangeWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | OrangeWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case OrangeWidgetType.BRAND:
      return <BrandWidget />
    case OrangeWidgetType.USER:
      return <UserWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case OrangeWidgetType.PROCESS:
      return <ProcessWidget />
    case OrangeWidgetType.MILESTONES:
      return <MilestonesWidget />
    case OrangeWidgetType.BAV:
      return <BavWidget />
    case OrangeWidgetType.POSTS:
      return <PostsWidget />
    case OrangeWidgetType.SOCIAL_ACTIVITY:
      return <SocialActivityWidget />
    default:
      return null
  }
}

export const OrangeDashboard = () => (
  <div className={styles.container}>
    {orangeWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
