import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectTrackingWidget/projectCreatedTrack/ProjectCreatedTrack.module.scss'

interface ProjectCreatedProps {
  count?: number
  total?: number
  isLoading: boolean
}

export const ProjectCreatedTrack = ({ count = 0, total = 0, isLoading = true }: ProjectCreatedProps) => {
  return (
    <WppCard className={styles.card}>
      <Flex direction="column" className={styles.content}>
        <WppTypography type="s-strong">Projects Created This Week</WppTypography>
        {(!isLoading && (
          <Flex direction="column" className={styles.summary}>
            <Flex align="center" justify="center" gap={24} direction="column" className={styles.contentBlock}>
              <WppTypography className={styles.counter}>{count}</WppTypography>
            </Flex>
            <Flex align="center" justify="center">
              <WppCard className={styles.totalAmount}>
                <Flex className={styles.totalAmountBlock} gap={8} align="center" justify="between">
                  <WppTypography type="m-body">Total amount of projects</WppTypography>
                  <WppTypography type="l-strong" className={styles.totalAmountCounter}>
                    {total}
                  </WppTypography>
                </Flex>
              </WppCard>
            </Flex>
          </Flex>
        )) || (
          <Flex align="center" justify="center" className={styles.loading}>
            <WppSpinner size="l" />
          </Flex>
        )}
      </Flex>
    </WppCard>
  )
}
