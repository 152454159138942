import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import styles from 'pages/home/systemWidgets/projectsWidget/projectsWidgetCard/ProjectsWidgetCard.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

interface Props extends ComponentPropsWithoutRef<typeof WidgetCard> {
  isHierarchyEnabled: boolean
  height: number
}

export const ProjectsWidgetCard = forwardRef<HTMLWppCardElement, Props>(
  ({ className, isHierarchyEnabled, height, ...rest }, ref) => (
    <WidgetCard
      ref={ref}
      {...rest}
      className={clsx(
        styles.root,
        {
          [styles.small]: height === 1 && !isHierarchyEnabled,
        },
        className,
      )}
      data-testid="projects-widget"
    />
  ),
)
