import AndrewDavisonImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Andrew_Davison.png'
import DelphineHernouxImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Delphine_Hernoux.png'
import DelphineTerriouxImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Delphine_Terrioux.png'
import MatthewMcIntyreImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Matthew_McIntyre.png'
import PaddyFlynnImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Paddy_Flynn.png'
import RichardHartellImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Richard_Hartell.png'
import RosannaReeveImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Rosanna_Reeve.png'
import RyanStorrarImgSrc from 'pages/home/components/pitch/uberDashboard/components/teamWidget/assets/members/Ryan_Storrar.png'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Andrew Davison',
    role: 'Chief Growth Officer',
    profileImgSrc: AndrewDavisonImgSrc,
  },
  {
    name: 'Ryan Storrar',
    role: 'Global Chief Media Solutions Officer',
    profileImgSrc: RyanStorrarImgSrc,
  },
  {
    name: 'Rosanna Reeve',
    role: 'Head of Google Planning',
    profileImgSrc: RosannaReeveImgSrc,
  },
  {
    name: 'Matthew McIntyre',
    role: 'Head of Programmatic',
    profileImgSrc: MatthewMcIntyreImgSrc,
  },
  {
    name: 'Delphine Hernoux',
    role: 'Chief Data and Analytics Officer, GroupM North America',
    profileImgSrc: DelphineHernouxImgSrc,
  },
  {
    name: 'Delphine Terrioux',
    role: 'Data Strategy & Insights Director',
    profileImgSrc: DelphineTerriouxImgSrc,
  },
  {
    name: 'Paddy Flynn',
    role: 'Global Head of Analytics & Insights',
    profileImgSrc: PaddyFlynnImgSrc,
  },
  {
    name: 'Richard Hartell',
    role: 'Global CEO MFG',
    profileImgSrc: RichardHartellImgSrc,
  },
]
