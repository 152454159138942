import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/home/components/pitch/orangeDashboard/components/milestonesWidget/MilestonesWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MilestonesWidget = () => (
  <WidgetCard className={styles.milestonesCard}>
    <div slot="header">
      <WppTypography type="m-strong" className={styles.headerTitle}>
        Milestones and Timelines
      </WppTypography>
    </div>
  </WidgetCard>
)
