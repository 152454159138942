import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/axaDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.card}>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.workgroupTitle}>
        Welcome to AXA OS
      </WppTypography>
      <Flex gap={4}>
        <WppTypography type="s-body">Market:</WppTypography>
        <WppTypography type="s-strong">Worldwide</WppTypography>
      </Flex>
      <WppTypography type="s-strong" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <div className={styles.axaNotificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-strong">Alice Smith&nbsp;</WppTypography>
            <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
            <WppTypography type="s-strong">Media&nbsp;</WppTypography>
            <WppTypography type="s-strong">Campaign Summer 2023</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:46
          </WppTypography>
        </div>
      </div>
      <div className={styles.axaNotificationCardNew}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-strong">Alice Smith&nbsp;</WppTypography>
            <WppTypography type="s-body">updated task&nbsp;</WppTypography>
            <WppTypography type="s-strong">Review&nbsp;</WppTypography>
            <WppTypography type="s-strong">creatives for Summer 2023&nbsp;</WppTypography>
            <WppTypography type="s-body">status&nbsp;</WppTypography>
            <WppTypography type="s-body">to “Approved”</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Hanna Heine&nbsp;</WppTypography>
        <WppTypography type="s-body">assigned&nbsp;</WppTypography>
        <WppTypography type="s-strong">Prepare creative&nbsp;</WppTypography>
        <WppTypography type="s-strong">brief for Coppertone Summer&nbsp;</WppTypography>
        <WppTypography type="s-body">to you</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:10
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Hanna Heine&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to project&nbsp;</WppTypography>
        <WppTypography type="s-strong">Coppertone media workshop</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:00
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Ben Aloe&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to project&nbsp;</WppTypography>
        <WppTypography type="s-strong">Media&nbsp;</WppTypography>
        <WppTypography type="s-strong">Campaign Winter 2023</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            15/06/2023 at 16:23
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
