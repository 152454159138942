import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/haleonDashboard/components/welcomeWidget/WelcomeWidget.module.scss'

export const WelcomeWidget = () => (
  <WppCard size="s" className={styles.haleonWelcomeContainer}>
    <Flex direction="column" justify="center" gap={35} className={styles.haleonWelcomeNote}>
      <WppTypography type="2xl-heading" className={styles.haleonWelcomeDescription}>
        Welcome!
      </WppTypography>
      <WppTypography type="s-body" className={styles.haleonWelcomeDescription}>
        Haleon hub is where we bring together people, processes and platforms to make our partnership more
        collaborative, more efficient and more fun! This is our home for onboarding our teams, navigating a delivery
        process and sharing learnings to ensure we‘re continuously improving. So go ahead - everything we have for you
        and we‘re all ears, tell us what you want and need and we‘ll make it happen.
      </WppTypography>
    </Flex>
  </WppCard>
)
