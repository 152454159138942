import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import trendAscendIconSrc from 'pages/home/components/pitch/oldElPasoDashboard/components/mediaEffectiveness/components/statisticCard/assets/trend-ascend.svg'
import trendDescendIconSrc from 'pages/home/components/pitch/oldElPasoDashboard/components/mediaEffectiveness/components/statisticCard/assets/trend-descend.svg'
import styles from 'pages/home/components/pitch/oldElPasoDashboard/components/mediaEffectiveness/components/statisticCard/StatisticCard.module.scss'

interface Props {
  title: string
  value: string
  prev30DaysValue: {
    changePercentage: number
    value: string
  }
}

export const StatisticCard = ({ title, value, prev30DaysValue }: Props) => (
  <div className={styles.card}>
    <WppTypography type="m-strong" className={styles.title}>
      {title}
    </WppTypography>
    <WppTypography type="3xl-heading" className={styles.title}>
      {value}
    </WppTypography>
    <Flex justify="center">
      <div className={styles.prev30DaysChangeWrapper}>
        <Flex gap={8}>
          <img src={prev30DaysValue.changePercentage >= 0 ? trendAscendIconSrc : trendDescendIconSrc} alt="" />
          <WppTypography
            type="s-midi"
            className={clsx(prev30DaysValue.changePercentage >= 0 ? styles.textGreen : styles.textRed)}
          >{`${prev30DaysValue.changePercentage >= 0 ? '+' : ''}${prev30DaysValue.changePercentage}%`}</WppTypography>
        </Flex>
        <WppTypography type="s-midi">change</WppTypography>
      </div>
      <div className={styles.prev30DaysValueWrapper}>
        <div>
          <WppTypography
            type="s-midi"
            className={clsx(prev30DaysValue.changePercentage >= 0 ? styles.textGreen : styles.textRed)}
          >
            {prev30DaysValue.value}
          </WppTypography>
        </div>
        <WppTypography type="s-midi">prev. 30d</WppTypography>
      </div>
    </Flex>
  </div>
)
