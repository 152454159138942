import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import introImgSrc from 'pages/home/components/pitch/pawsAndTailsDashboard/components/introWidget/assets/intro.png'
import styles from 'pages/home/components/pitch/pawsAndTailsDashboard/components/introWidget/IntroWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const IntroWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex className={styles.cardContent} justify="between">
      <div className={styles.textContentWrapper}>
        <WppTypography type="2xl-heading" className={styles.title}>
          Cultivating Happiness
        </WppTypography>
        <WppTypography type="s-body">
          Welcome to Paws & Tails, where meaningful connections and joyful relationships are our core values. Join us on
          an extraordinary journey celebrating the love and companionship that our four-legged friends bring into our
          lives. Let wagging tails and playful paws inspire pure joy, connection, and endless memories.
        </WppTypography>
      </div>
      <img src={introImgSrc} className={styles.introImg} alt="" />
    </Flex>
  </WidgetCard>
)
