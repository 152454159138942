import { AirFlowWidget } from 'pages/home/components/pitch/easyJetDashboard/components/airFlowWidget/AirFlowWidget'
import { TerminalWidget } from 'pages/home/components/pitch/easyJetDashboard/components/terminalWidget/TerminalWidget'
import styles from 'pages/home/components/pitch/easyJetDashboard/EasyJetDashboard.module.scss'
import { easyJetWidgets } from 'pages/home/components/pitch/easyJetDashboard/widgetMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { FavouriteLinksWidgetView } from 'pages/home/systemWidgets/favouriteLinksWidget/FavouriteLinksWidgetView'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { EasyJetWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | EasyJetWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case SystemWidget.FAVOURITE_LINKS:
      return <FavouriteLinksWidgetView widget={{ ...pageWidget, title: 'Favourites' } as any} />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    case EasyJetWidgetType.AIR_FLOW:
      return <AirFlowWidget />
    case EasyJetWidgetType.TERMINAL:
      return <TerminalWidget />
    default:
      return null
  }
}
export const EasyJetDashboard = () => {
  return (
    <div className={styles.container}>
      {easyJetWidgets.map(pageWidget => {
        const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
        return (
          <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
            <RenderWidget widgetType={name} pageWidget={pageWidget} />
          </div>
        )
      })}
    </div>
  )
}
