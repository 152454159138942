import styles from 'pages/home/components/pitch/svg/common.module.scss'

export const SvgImageLoadError = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="130" height="109" viewBox="0 0 130 109" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_f_36391_12049)">
      <ellipse cx="65.1244" cy="79.1648" rx="43.0999" ry="7.76486" className={styles.fillPrimary300} />
    </g>
    <path
      d="M67 96.6001C91.8528 96.6001 112 76.4529 112 51.6001C112 26.7473 91.8528 6.6001 67 6.6001C42.1472 6.6001 22 26.7473 22 51.6001C22 76.4529 42.1472 96.6001 67 96.6001Z"
      className={styles.fillPrimary200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.4 22.3715C97.1574 22.3715 97.7714 21.7575 97.7714 21.0001C97.7714 20.2427 97.1574 19.6287 96.4 19.6287C95.6426 19.6287 95.0286 20.2427 95.0286 21.0001C95.0286 21.7575 95.6426 22.3715 96.4 22.3715ZM96.4 23.4001C97.7255 23.4001 98.8 22.3256 98.8 21.0001C98.8 19.6746 97.7255 18.6001 96.4 18.6001C95.0745 18.6001 94 19.6746 94 21.0001C94 22.3256 95.0745 23.4001 96.4 23.4001Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M18.5791 78.0183C19.6351 77.8463 20.4889 78.9152 20.1074 79.9316L19.5903 81.3092C19.2126 82.3151 17.9056 82.5156 17.2311 81.6712L16.317 80.5268C15.6424 79.6823 16.1027 78.4218 17.1478 78.2515L18.5791 78.0183Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M107.24 58.0997C106.994 57.0846 107.925 56.1781 108.937 56.4481L110.973 56.9918C112.008 57.268 112.349 58.5627 111.584 59.308L110.042 60.8082C109.277 61.5535 107.987 61.182 107.735 60.1435L107.24 58.0997Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M64.0038 3.88845C64.323 2.70385 66.0769 2.70385 66.3962 3.88845L66.5635 4.50928C66.6794 4.93928 67.0362 5.27104 67.4879 5.36871L67.8362 5.44401C69.1212 5.72186 69.1212 7.47814 67.8362 7.75599L67.4879 7.83129C67.0362 7.92896 66.6794 8.26072 66.5635 8.69073L66.3962 9.31155C66.0769 10.4961 64.323 10.4961 64.0038 9.31155L63.8365 8.69072C63.7206 8.26072 63.3637 7.92896 62.912 7.83129L62.5638 7.75599C61.2787 7.47813 61.2787 5.72186 62.5638 5.44401L62.912 5.36871C63.3637 5.27104 63.7206 4.93928 63.8365 4.50927L64.0038 3.88845Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M22.0038 57.2883C22.323 56.1038 24.0769 56.1038 24.3962 57.2883L24.5635 57.9092C24.6794 58.3392 25.0362 58.6709 25.4879 58.7686L25.8362 58.8439C27.1212 59.1218 27.1212 60.878 25.8362 61.1559L25.4879 61.2312C25.0362 61.3289 24.6794 61.6606 24.5635 62.0906L24.3962 62.7115C24.0769 63.8961 22.323 63.896 22.0038 62.7115L21.8365 62.0906C21.7206 61.6606 21.3637 61.3289 20.912 61.2312L20.5638 61.1559C19.2787 60.878 19.2787 59.1218 20.5638 58.8439L20.912 58.7686C21.3637 58.6709 21.7206 58.3392 21.8365 57.9092L22.0038 57.2883Z"
      className={styles.fillPrimary400}
    />
    <rect
      x="23.5049"
      y="38.9482"
      width="51"
      height="51"
      rx="8"
      transform="rotate(-20 23.5049 38.9482)"
      className={styles.fillPrimary100}
    />
    <rect x="48" y="31" width="51" height="51" rx="4" className={styles.fillWhite} />
    <circle cx="61" cy="45" r="7" className={styles.fillPrimary400} />
    <path
      d="M59.7541 62.1242C60.5174 60.7584 62.4826 60.7584 63.2459 62.1242L69.3371 73.0244C70.0821 74.3575 69.1184 76 67.5913 76H55.4087C53.8816 76 52.9179 74.3575 53.6629 73.0244L59.7541 62.1242Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M77.2635 51.0388C78.0313 49.6952 79.9687 49.6952 80.7365 51.0389L93.2901 73.0077C94.052 74.341 93.0893 76 91.5536 76H66.4464C64.9107 76 63.948 74.341 64.7099 73.0077L77.2635 51.0388Z"
      className={styles.fillPrimary200}
    />
    <defs>
      <filter
        id="filter0_f_36391_12049"
        x="0.25148"
        y="49.6269"
        width="129.746"
        height="59.0758"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_36391_12049" />
      </filter>
    </defs>
  </svg>
)
