import { WppTypography, WppButton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import chart1 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart1.svg'
import chart2 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart2.svg'
import chart3 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart3.svg'
import chart4 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart4.jpg'
import chart5 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart5.jpg'
import chart6 from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/assets/chart6.svg'
import styles from 'pages/home/components/pitch/spotifyDashboard/components/metricsWidget/MetricsWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const metricksMock = [
  {
    column: 'Brand',
    metrics: [chart1, chart2],
  },
  {
    column: 'Performance',
    metrics: [chart3, chart4],
  },
  {
    column: 'The Scout Feed',
    metrics: [chart5, chart6],
  },
]

export const MetricsWidget = () => (
  <WidgetCard>
    <Flex align="center" slot="header" justify="between" className={styles.header}>
      <WppTypography type="m-strong">Real-time measurement feeds</WppTypography>
      <WppButton className={styles.activateBtn} disabled>
        Activate Fast MMM
      </WppButton>
    </Flex>

    <Flex gap={24}>
      {metricksMock.map(({ column, metrics }, index) => (
        <Flex gap={8} direction="column" key={index} className={styles.column}>
          <WppTypography type="s-strong" className={styles.columnTitle}>
            {column}
          </WppTypography>

          <Flex gap={24} direction="column">
            {metrics.map((chart, index) => (
              <img src={chart} alt="" key={index} className={styles.chartImg} />
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  </WidgetCard>
)
