import { WppTypography, WppActionButton, WppIconMore } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import wrikeColumn1ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-1.png'
import wrikeColumn2ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-2.png'
import wrikeColumn3ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-3.png'
import wrikeColumn4ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-4.png'
import wrikeColumn5ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-5.png'
import wrikeColumn6ImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/assets/wrike-column-6.png'
import styles from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/WrikeWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const WrikeLogo = () => (
  <svg width="118" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <mask id="b" maskUnits="userSpaceOnUse" x="0" y="-1" width="118" height="40">
        <path d="M0-1h117.037v40H0V-1Z" fill="#fff" />
      </mask>
      <g mask="url(#b)">
        <mask id="c" maskUnits="userSpaceOnUse" x="0" y="11" width="118" height="17">
          <path d="M0 11h117.037v16.2H0V11Z" fill="#fff" />
        </mask>
        <g mask="url(#c)">
          <path
            d="M73.62 25v-4c0-2.4 3.02-2.333 4.72-2.2v-2.667c-2.643-.2-4.248.267-4.909 1.267v-1.333h-3.68v9h3.775L73.62 25Zm15.574 0h2.265l3.87-3.333L98.632 25h4.342l-5.097-5.133 4.436-3.734h-4.341L92.875 20.6v-8.933h-3.776V25h.095Zm-42.19 0h3.02l4.436-5.667L58.519 25h2.925l6.607-8.933H63.71l-4.059 5.666-3.775-5.666h-3.21L48.609 21.8l-3.775-5.733h-4.248L47.004 25Zm36.243-10.2c1.227 0 2.266-.667 2.266-1.6 0-.867-.944-1.533-2.266-1.533-1.321 0-2.265.666-2.265 1.533 0 .933.944 1.6 2.265 1.6Zm1.794 1.333H81.36V25h3.775v-8.867h-.094Zm28.41 5.934c-.472.466-1.416 1.066-3.021 1.066-1.887 0-3.114-.733-3.398-1.8h10.005v-.666c0-2.667-2.643-4.667-6.607-4.667a9.149 9.149 0 0 0-2.644.276 7.447 7.447 0 0 0-2.274.993c-.654.437-1.174.964-1.53 1.548a3.526 3.526 0 0 0-.536 1.85c-.001.63.179 1.253.528 1.833.35.58.862 1.103 1.506 1.54a7.404 7.404 0 0 0 2.24.997 9.139 9.139 0 0 0 2.616.296c3.02 0 4.719-.866 5.946-1.933l-2.831-1.333Zm-3.304-4.134c1.699 0 2.832.667 3.304 1.6h-6.607c.377-.933 1.604-1.6 3.303-1.6Z"
            fill="#000"
          />
          <path
            d="M8.683 16.867a6.506 6.506 0 0 1 2.123.247c.671.196 1.27.5 1.747.886l6.89 4.8.378.267v.2c0 .133-.095.133-.378.333l-4.719 3.267-.377.2h-.284c-.094 0-.188 0-.377-.2L.189 17.534c-.378-.267-.189-.667.472-.667h8.022ZM28.221 11a6.527 6.527 0 0 0-2.174.232c-.69.197-1.302.506-1.79.902l-6.89 4.8-.283.266v.2c0 .067 0 .134.283.267L22.086 21l.378.2h.283c.189 0 .283-.066.472-.2l13.402-9.333C37 11.4 36.904 11 36.244 11H28.22Z"
            fill="#08CF65"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h117.037v40H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const WrikeWidget = () => (
  <WidgetCard className={styles.card}>
    <div slot="header" className={styles.header}>
      <Flex align="center" justify="between">
        <Flex align="center">
          <WrikeLogo />
          <WppTypography type="l-strong" className={styles.title}>
            Active Projects
          </WppTypography>
        </Flex>
        <WppActionButton variant="secondary" style={{ zIndex: 2 }} slot="trigger-element">
          <WppIconMore direction="horizontal" color="--wpp-grey-color-800" />
        </WppActionButton>
      </Flex>
    </div>
    <Flex className={styles.container}>
      <img src={wrikeColumn1ImgSrc} className={styles.wrikeColumnImg} alt="" />
      <img src={wrikeColumn2ImgSrc} className={styles.wrikeColumnImg} alt="" />
      <img src={wrikeColumn3ImgSrc} className={styles.wrikeColumnImg} alt="" />
      <img src={wrikeColumn4ImgSrc} className={styles.wrikeColumnImg} alt="" />
      <img src={wrikeColumn5ImgSrc} className={styles.wrikeColumnImg} alt="" />
      <img src={wrikeColumn6ImgSrc} className={styles.wrikeColumnImg} alt="" />
    </Flex>
  </WidgetCard>
)
