import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { AuthProvider } from 'providers/auth/AuthProvider'
import { getTargetUrl } from 'providers/auth/utils'
import { ProtectedApiProvider } from 'providers/protectedApi/ProtectedApiProvider'
import { SignInUserState } from 'types/auth/state'
import { routesManager } from 'utils/routesManager'

export const CheckAuth = () => {
  const { isAuthenticated, isLoading, events, error, signinSilent, removeUser } = useAuth()
  const [didTrySilentSignIn, setDidTrySilentSignIn] = useState(false)
  const location = useLocation()

  // Try silent sign in on page load if we don't have user data yet
  useEffect(() => {
    if (!didTrySilentSignIn && !isLoading) {
      if (isAuthenticated) {
        setDidTrySilentSignIn(true)
      } else {
        // The library may use an expired refresh token to sign in.
        // So if the user is not authenticated by this point,
        // just remove user info before trying silent sign in.
        // See: https://github.com/authts/oidc-client-ts/issues/644
        removeUser().finally(() => {
          signinSilent()
            .catch(error => {
              // Ignore the error. User is not signed in, we'll just redirect them to the login page.
              console.error('Silent sign in error', error)
            })
            .finally(() => {
              setDidTrySilentSignIn(true)
            })
        })
      }
    }
  }, [isAuthenticated, isLoading, didTrySilentSignIn, signinSilent, removeUser])

  // Watch for sign out to update the status
  useEffect(() => {
    const signedOutCallback = () => {
      removeUser()
    }

    events.addUserSignedOut(signedOutCallback)

    return () => {
      events.removeUserSignedOut(signedOutCallback)
    }
  }, [events, removeUser])

  // Handle 'react-oidc-context' errors
  useEffect(() => {
    if (error) {
      console.error('Authentication error:', error)
      removeUser()
    }
  }, [error, removeUser])

  if (!didTrySilentSignIn || isLoading) {
    return <LoadingPage />
  }

  return (
    <>
      {isAuthenticated && !error ? (
        <AuthProvider>
          <ProtectedApiProvider>
            <Outlet />
          </ProtectedApiProvider>
        </AuthProvider>
      ) : (
        <Navigate
          replace
          to={routesManager.login.url()}
          state={
            {
              targetUrl: getTargetUrl(location),
            } as SignInUserState
          }
        />
      )}
    </>
  )
}
