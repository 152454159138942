import clsx from 'clsx'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/widgetList/WidgetList.module.scss'

interface Props<T> extends ComponentPropsWithoutRef<typeof Flex> {
  items: T[]
  getItemId?: (item: T, index: number) => string | number
  renderItem: (item: T, index: number) => JSX.Element
}

export const WidgetList = <T,>({
  items,
  renderItem,
  getItemId = (_, index) => index,
  className,
  gap = 4,
  ...rest
}: Props<T>) => (
  <Flex {...rest} className={clsx(styles.list, className)} direction="column" gap={gap}>
    {items.map((item, index) => (
      <Fragment key={getItemId(item, index)}>{renderItem(item, index)}</Fragment>
    ))}
  </Flex>
)
