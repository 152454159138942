import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import Activity1ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/assets/activity_1.png'
import Activity2ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/assets/activity_2.png'
import Activity3ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/assets/activity_3.png'
import Activity4ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/assets/activity_4.png'
import Activity5ImgSrc from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/assets/activity_5.png'
import styles from 'pages/home/components/pitch/orangeDashboard/components/socialActivityWidget/SocialActivityWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const activitiesById = {
  activity1: {
    id: 'activity1',
    name: 'LinkedIn',
    count: '4302',
    link: 'https://www.linkedin.com/company/orange/',
    imageSrc: Activity4ImgSrc,
  },
  activity2: {
    id: 'activity2',
    name: 'Facebook',
    count: '248',
    link: 'https://www.facebook.com/Orange.France/?locale=fr_FR',
    imageSrc: Activity1ImgSrc,
  },
  activity3: {
    id: 'activity3',
    name: 'Instagram',
    count: '4372',
    link: 'https://www.instagram.com/orange/',
    imageSrc: Activity3ImgSrc,
  },
  activity4: {
    id: 'activity4',
    name: 'Twitter',
    count: '1203',
    link: 'https://twitter.com/orange',
    imageSrc: Activity2ImgSrc,
  },
  activity5: {
    id: 'activity5',
    name: 'Instagram',
    count: '1207',
    link: 'https://www.instagram.com/orange/',
    imageSrc: Activity5ImgSrc,
  },
}
const socialLinksList = [
  activitiesById.activity1,
  activitiesById.activity2,
  activitiesById.activity3,
  activitiesById.activity4,
]

const socialCardsList = [
  activitiesById.activity2,
  activitiesById.activity4,
  activitiesById.activity3,
  activitiesById.activity1,
  activitiesById.activity5,
]

export const SocialActivityWidget = () => (
  <WidgetCard className={styles.socialActivityCard}>
    <WppTypography className={styles.titleText} type="m-strong">
      Social Media Activity Monitoring
    </WppTypography>
    <div className={styles.socialActivitiesContainer}>
      <Flex gap={20}>
        <WppButton className={styles.allButton} variant="primary">
          All
        </WppButton>
        {socialLinksList.map(linkItem => (
          <Link key={linkItem.id} to={linkItem.link} className={styles.socialType}>
            <WppTypography className={styles.socialText} type="s-midi">
              {linkItem.name}&nbsp;
            </WppTypography>
            <WppTypography className={styles.socialText} type="s-body">
              ({linkItem.count})
            </WppTypography>
          </Link>
        ))}
      </Flex>
      <div className={styles.socialActivitiesGrid}>
        {socialCardsList.map(cardItem => (
          <Link key={cardItem.id} to={cardItem.link}>
            <img src={cardItem.imageSrc} alt="" className={styles.socialActivityItemImg} />
          </Link>
        ))}
      </div>
    </div>
  </WidgetCard>
)
