import { PermissionsRecord, NavigationTree } from '@wpp-open/core'
import { createContext, useContext } from 'react'

export interface DataContextValue {
  permissions: PermissionsRecord[]
  navigationTree: NavigationTree
}

export const DataContext = createContext<DataContextValue>(null!)

export const useData = () => useContext(DataContext)
