import { OldElPasoWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type OldElPasoWidget = Omit<Widget, 'name'> & { name: OldElPasoWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'old_el_paso_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [OldElPasoWidgetType.HERO_BANNER]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 2,
    gridColumnEnd: 17,
  },
  [OldElPasoWidgetType.INTRO]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridRowEnd: 4,
    gridColumnEnd: 9,
  },
  [OldElPasoWidgetType.MEDIA_EFFECTIVENESS]: {
    gridRowStart: 2,
    gridColumnStart: 9,
    gridRowEnd: 4,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 4,
    gridColumnStart: 1,
    gridRowEnd: 6,
    gridColumnEnd: 9,
  },
  [OldElPasoWidgetType.EXCELLENCE_HUB]: {
    gridRowStart: 4,
    gridColumnStart: 9,
    gridRowEnd: 6,
    gridColumnEnd: 17,
  },
  [OldElPasoWidgetType.MILESTONES_AND_TIMELINES]: {
    gridRowStart: 6,
    gridColumnStart: 1,
    gridRowEnd: 8,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 6,
    gridColumnStart: 9,
    gridRowEnd: 8,
    gridColumnEnd: 17,
  },
}

export const oldElPasoWidgets: OldElPasoWidget[] = [
  {
    ...gridConfig[OldElPasoWidgetType.HERO_BANNER],
    ...commonWidgetsConfig,
    name: OldElPasoWidgetType.HERO_BANNER,
    id: 'old_el_paso_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OldElPasoWidgetType.INTRO],
    ...commonWidgetsConfig,
    name: OldElPasoWidgetType.INTRO,
    id: 'old_el_paso_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OldElPasoWidgetType.MEDIA_EFFECTIVENESS],
    ...commonWidgetsConfig,
    name: OldElPasoWidgetType.MEDIA_EFFECTIVENESS,
    id: 'old_el_paso_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'old_el_paso_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OldElPasoWidgetType.EXCELLENCE_HUB],
    ...commonWidgetsConfig,
    name: OldElPasoWidgetType.EXCELLENCE_HUB,
    id: 'old_el_paso_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OldElPasoWidgetType.MILESTONES_AND_TIMELINES],
    ...commonWidgetsConfig,
    name: OldElPasoWidgetType.MILESTONES_AND_TIMELINES,
    id: 'old_el_paso_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'old_el_paso_7',
    createdAt: '',
    updatedAt: '',
  },
]
