import { ClientTenantWidget } from 'pages/home/components/pitch/demoDashboard/components/clientTenantWidget/ClientTenantWidget'
import { WelcomeWidget } from 'pages/home/components/pitch/demoDashboard/components/welcomeWidget/WelcomeWidget'
import { WppTenantWidget } from 'pages/home/components/pitch/demoDashboard/components/wppTenantWidget/WppTenantWidget'
import styles from 'pages/home/components/pitch/demoDashboard/DemoDashboard.module.scss'
import { demoWidgets } from 'pages/home/components/pitch/demoDashboard/widgetsMock'
import { DemoWidgetType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType }: { widgetType: SystemWidget | DemoWidgetType }) => {
  switch (widgetType) {
    case DemoWidgetType.WELCOME:
      return <WelcomeWidget />
    case DemoWidgetType.CLIENT_TENANT:
      return <ClientTenantWidget />
    case DemoWidgetType.WPP_TENANT:
      return <WppTenantWidget />
    default:
      return null
  }
}

export const DemoDashboard = () => {
  return (
    <div className={styles.container}>
      {demoWidgets.map(({ id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name }) => (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} />
        </div>
      ))}
    </div>
  )
}
