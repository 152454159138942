import { MayBeNull } from '@wpp-open/core'

import { useData } from 'providers/data/DataContext'
import { getLegacyLocalMicroAppData, getLocalMicroAppData } from 'providers/osState/utils/appData'
import { getInvalidResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppDataFromUrlType, LocalLegacyAppDataFromUrl, LocalAppDataFromUrl } from 'types/osState/appDataFromUrl'
import { getLocalAppUrl } from 'utils/navigation'
import { isWorkspaceIdValid } from 'utils/workspace'

interface Params {
  appDataShort: LocalAppDataFromUrl | LocalLegacyAppDataFromUrl
}

export const useLocalAppDataResolver = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree } = useData()

  if (params) {
    const { type, app, workspaceAzId = null } = params.appDataShort
    const isWorkspaceValid = isWorkspaceIdValid({
      workspaceAzId,
      navigationTree,
    })

    if (isWorkspaceValid) {
      const currentBaseUrl = getLocalAppUrl({
        workspaceAzId,
        osRoute: app.osRoute,
      }).slice(1)

      if (type === AppDataFromUrlType.LocalApp) {
        return {
          appData: getLocalMicroAppData({ app, currentBaseUrl, activeWorkspaceId: workspaceAzId }),
          sideEffectNode: null,
        }
      } else if (type === AppDataFromUrlType.LocalLegacyApp) {
        return {
          appData: getLegacyLocalMicroAppData({ app, currentBaseUrl, activeWorkspaceId: workspaceAzId }),
          sideEffectNode: null,
        }
      }
    }

    return getInvalidResolvedAppData()
  }

  return null
}
