import { WppInput, WppIconChevron, WppPopover, WppListItem } from '@platform-ui-kit/components-library-react'
import { useRef, useState } from 'react'

const OPTIONS_LIST = ['Remove Tag', 'Resolved', 'Follow Up']

interface Props {
  options?: string[]
}

const CustomDropdown = ({ options = OPTIONS_LIST }: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>(options[0])
  const [isActive, setIsActive] = useState<boolean>(false)
  const popoverRef = useRef<HTMLWppPopoverElement>(null)

  const handleOptionSelect = (selectedOption: string) => {
    setSelectedOption(selectedOption)
    popoverRef.current?.closePopover()
  }

  const style: Record<string, string> = { '--wpp-list-item-width': '200px' }

  return (
    <WppPopover
      ref={popoverRef}
      config={{
        onShow: () => setIsActive(true),
        onHide: () => setIsActive(false),
        triggerElementWidth: true,
      }}
    >
      <WppInput value={selectedOption} slot="trigger-element" placeholder="Mark as" labelConfig={{ text: '' }} required>
        <WppIconChevron slot="icon-end" direction={isActive ? 'up' : 'down'} />
      </WppInput>
      <div style={style}>
        {/* Any other content here */}
        {options.map(option => (
          <WppListItem
            key={option}
            checked={selectedOption === option}
            onWppChangeListItem={() => handleOptionSelect(option)}
          >
            <p slot="label">{option}</p>
          </WppListItem>
        ))}
      </div>
    </WppPopover>
  )
}

export default CustomDropdown
