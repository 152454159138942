import { VolkswagenWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type VolkswagenWidget = Omit<Widget, 'name'> & { name: VolkswagenWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'volkswagen_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [VolkswagenWidgetType.IMAGE]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [VolkswagenWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [VolkswagenWidgetType.CALENDAR]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [VolkswagenWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [VolkswagenWidgetType.TEAM]: {
    gridRowStart: 7,
    gridColumnStart: 1,
    gridRowEnd: 9,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 7,
    gridColumnStart: 9,
    gridRowEnd: 9,
    gridColumnEnd: 17,
  },
  [VolkswagenWidgetType.WRIKE]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const volkswagenWidgets: VolkswagenWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'volkswagen_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.IMAGE],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.IMAGE,
    id: 'volkswagen_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.USER],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.USER,
    id: 'volkswagen_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.CALENDAR,
    id: 'volkswagen_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.PROCESS,
    id: 'volkswagen_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.TEAM,
    id: 'volkswagen_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'volkswagen_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenWidgetType.WRIKE],
    ...commonWidgetsConfig,
    name: VolkswagenWidgetType.WRIKE,
    id: 'volkswagen_8',
    createdAt: '',
    updatedAt: '',
  },
]
