import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pitch/welcomeWidget/WelcomeWidget.module.scss'

export const WelcomeWidget = () => {
  return (
    <WppCard className={styles.container}>
      <div className={styles.content}>
        <WppTypography type="5xl-display">Welcome to WPP Open</WppTypography>
        <WppTypography type="m-body" className={styles.description}>
          Welcome to WPP Open, a platform where we showcase the best data, products and partnerships from across WPP.
          Here you`ll find out how to use the data and technology tools to build intelligent and innovative campaigns
          for your clients.
        </WppTypography>
        <Flex gap={16}>
          <Link to="/benefits">
            <WppButton>Learn more about Open</WppButton>
          </Link>
          {/* <Link to="https://demo.wppopen.com/" > */}
          {/* <WppButton variant="secondary">Open Demo Site</WppButton>e */}
          {/* </Link> */}
        </Flex>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.video} />
      </div>
    </WppCard>
  )
}
