import { CentralWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type CentralWidget = Omit<Widget, 'name'> & { name: CentralWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'central_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [CentralWidgetType.WELCOME]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 2,
    gridColumnEnd: 17,
  },
  [CentralWidgetType.EXPLORE_PROCESS]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
  [CentralWidgetType.PROJECT]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 4,
  },
  [CentralWidgetType.ABOUT_PROJECT]: {
    gridRowStart: 3,
    gridColumnStart: 4,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
}

export const centralWidgets: CentralWidget[] = [
  {
    ...gridConfig[CentralWidgetType.WELCOME],
    ...commonWidgetsConfig,
    name: CentralWidgetType.WELCOME,
    id: 'central_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CentralWidgetType.EXPLORE_PROCESS],
    ...commonWidgetsConfig,
    name: CentralWidgetType.EXPLORE_PROCESS,
    id: 'central_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CentralWidgetType.ABOUT_PROJECT],
    ...commonWidgetsConfig,
    name: CentralWidgetType.ABOUT_PROJECT,
    id: 'central_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CentralWidgetType.PROJECT],
    ...commonWidgetsConfig,
    name: CentralWidgetType.PROJECT,
    id: 'central_4',
    createdAt: '',
    updatedAt: '',
  },
]
