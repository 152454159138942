import { AppsWidget } from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/AppsWidget'
import { TeamsWidget } from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/TeamsWidget'
import { UserWidget } from 'pages/home/components/pitch/haleonDashboard/components/userWidget/UserWidget'
import { WelcomeWidget } from 'pages/home/components/pitch/haleonDashboard/components/welcomeWidget/WelcomeWidget'
import styles from 'pages/home/components/pitch/haleonDashboard/HaleonDashboard.module.scss'
import { haleonWidgets } from 'pages/home/components/pitch/haleonDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { HaleonWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | HaleonWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case HaleonWidgetType.WELCOME:
      return <WelcomeWidget />
    case HaleonWidgetType.USER:
      return <UserWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case HaleonWidgetType.APPS:
      return <AppsWidget />
    case HaleonWidgetType.TEAMS:
      return <TeamsWidget />
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const HaleonDashboard = () => (
  <div className={styles.container}>
    {haleonWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
