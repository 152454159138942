import { PfizerWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type PfizerWidget = Omit<Widget, 'name'> & { name: PfizerWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'pfizer_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [PfizerWidgetType.PROJECT_MANAGEMENT]: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
  [PfizerWidgetType.VIDEO]: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 5,
    gridColumnEnd: 13,
  },
  [PfizerWidgetType.DASHBOARDS]: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 13,
    gridColumnEnd: 17,
  },
  [PfizerWidgetType.OS_ASSISTANT]: {
    gridRowStart: 3,
    gridRowEnd: 5,
    gridColumnStart: 1,
    gridColumnEnd: 9,
  },
  [PfizerWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridRowEnd: 5,
    gridColumnStart: 9,
    gridColumnEnd: 17,
  },
  [PfizerWidgetType.UPCOMING_DATES]: {
    gridRowStart: 5,
    gridRowEnd: 7,
    gridColumnStart: 1,
    gridColumnEnd: 9,
  },
  [PfizerWidgetType.GLOBAL_DATA]: {
    gridRowStart: 5,
    gridRowEnd: 7,
    gridColumnStart: 9,
    gridColumnEnd: 17,
  },
  [PfizerWidgetType.TEAM]: {
    gridRowStart: 7,
    gridRowEnd: 9,
    gridColumnStart: 1,
    gridColumnEnd: 17,
  },
}

export const pfizerWidgets: PfizerWidget[] = [
  {
    ...gridConfig[PfizerWidgetType.PROJECT_MANAGEMENT],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.PROJECT_MANAGEMENT,
    id: 'pfizer_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.VIDEO],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.VIDEO,
    id: 'pfizer_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.DASHBOARDS],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.DASHBOARDS,
    id: 'pfizer_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.OS_ASSISTANT],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.OS_ASSISTANT,
    id: 'pfizer_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.PROCESS,
    id: 'pfizer_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.UPCOMING_DATES],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.UPCOMING_DATES,
    id: 'pfizer_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.GLOBAL_DATA],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.GLOBAL_DATA,
    id: 'pfizer_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PfizerWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: PfizerWidgetType.TEAM,
    id: 'pfizer_8',
    createdAt: '',
    updatedAt: '',
  },
]
