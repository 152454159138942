import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/volkswagenJapanDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.card}>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.vwJapanWorkgroupTitle}>
        Your workgroup details:
      </WppTypography>
      <Flex gap={4}>
        <WppTypography type="s-body">Market:</WppTypography>
        <WppTypography type="s-strong">Japan</WppTypography>
      </Flex>
      <Flex gap={4}>
        <WppTypography type="s-body">Brand:</WppTypography>
        <WppTypography type="s-strong">Volkswagen group Japan</WppTypography>
      </Flex>
      <WppTypography type="s-strong" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <div className={styles.notificationCard}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-strong">Michael Estifa&nbsp;</WppTypography>
            <WppTypography type="s-body">updated task&nbsp;</WppTypography>
            <WppTypography type="s-strong">VW Client Visit&nbsp;</WppTypography>
            <WppTypography type="s-body">status to &quot;completed&quot;</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Kenshi Arai&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">Porsche&nbsp;</WppTypography>
        <WppTypography type="s-body">project</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:05
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Agency&nbsp;</WppTypography>
        <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">Audi Fast Start&nbsp;</WppTypography>
        <WppTypography type="s-body">workshop</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:10
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-strong">Agency&nbsp;</WppTypography>
        <WppTypography type="s-body">updated task&nbsp;</WppTypography>
        <WppTypography type="s-strong">Commercial Strategy&nbsp;</WppTypography>
        <WppTypography type="s-body">for&nbsp;</WppTypography>
        <WppTypography type="s-strong">VW Commercial Vehicles&nbsp;</WppTypography>
        <WppTypography type="s-body">status to&nbsp;</WppTypography>
        <WppTypography type="s-body">&quot;Pending Client feedback&quot;</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            09/08/2023 at 13:37
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
