import FootfallChart from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/assets/footfallChart.svg'
import SalesChart from 'pages/home/components/pitch/valueRetailDashboard/components/mediaEffectiveness/assets/salesChart.svg'

export interface IDataItem {
  title: string
  subtitle: string
  value: string
  isGrayValue?: boolean
}

export interface IData {
  chart: string
  data: IDataItem[][]
}

export const dataMock: Record<string, IData> = {
  footfall: {
    chart: FootfallChart,
    data: [
      [
        { title: 'Bicester Village', subtitle: '(London)', value: '+10%' },
        { title: 'La Valle', subtitle: '(Paris)', value: '+2%' },
        { title: 'Las Rozas Village', subtitle: '(Madrid)', value: '+4%' },
      ],
      [
        { title: 'La Rocca Village', subtitle: '(Barcelona)', value: '+7%' },
        { title: 'Wertheim Village', subtitle: '(Munich)', value: '+5%' },
        { title: 'Ingolstadt Village', subtitle: '(Frankfurt)', value: '+5%' },
      ],
      [
        { title: 'Fidenza Village', subtitle: '(Milan)', value: '+11%' },
        { title: 'Kildare Village', subtitle: '(Dublin)', value: '+3%' },
        { title: 'Maasmenschen V.', subtitle: '(Brussels)', value: '+8%' },
      ],
      [{ title: 'Bellmont Park', subtitle: '(US)', value: 'coming soon -Q3 2024', isGrayValue: true }],
    ],
  },
  sales: {
    chart: SalesChart,
    data: [
      [
        { title: 'Bicester Village', subtitle: '(London)', value: '+10%' },
        { title: 'La Valle', subtitle: '(Paris)', value: '+1%' },
        { title: 'Las Rozas Village', subtitle: '(Madrid)', value: '+2%' },
      ],
      [
        { title: 'La Rocca Village', subtitle: '(Barcelona)', value: '+5%' },
        { title: 'Wertheim Village', subtitle: '(Munich)', value: '+3%' },
        { title: 'Ingolstadt Village', subtitle: '(Frankfurt)', value: '+2%' },
      ],
      [
        { title: 'Fidenza Village', subtitle: '(Milan)', value: '+9%' },
        { title: 'Kildare Village', subtitle: '(Dublin)', value: '+6%' },
        { title: 'Maasmenschen V.', subtitle: '(Brussels)', value: '+4%' },
      ],
      [{ title: 'Bellmont Park', subtitle: '(US)', value: 'coming soon -Q3 2024', isGrayValue: true }],
    ],
  },
}
