import { AnalyticsActionType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SvgNoFavouriteLinks } from 'components/svg/widgets/SvgNoFavouriteLinks'
import { SvgWidgetsErrorState } from 'components/svg/widgets/SvgWidgetsErrorState'
import { reloadFavouriteLinks } from 'pages/home/systemWidgets/favouriteLinksWidget/utils'
import { useHasAccessToManageWidgets } from 'pages/home/utils'
import { WidgetEmptyState } from 'pages/home/widgetEmptyState/WidgetEmptyState'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { routesManager } from 'utils/routesManager'

interface Props {
  isError: boolean
  isCompact?: boolean
}

export const FavouriteLinksWidgetEmptyState = ({ isError, isCompact }: Props) => {
  const { t } = useTranslation()

  const hasAccessToManageWidgets = useHasAccessToManageWidgets()

  return (
    <WidgetEmptyState
      isCompact={isCompact}
      text={
        isError
          ? t('os.home.cannot_load', { entity: t('os.home.favourite_links_widget.title').toLocaleLowerCase() })
          : t('os.home.favourite_links_widget.empty_state_text')
      }
      action={
        <>
          {hasAccessToManageWidgets && !isError && (
            <Link
              to={routesManager.links.url()}
              onClick={() =>
                trackAnalytics({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.HOME_PAGE.ACTIONS.HOME_FAVORITE_LINKS_WIDGET_ADD_LINK,
                })
              }
            >
              <WidgetEmptyState.AddButton>
                {t('os.home.favourite_links_widget.add_button_text')}
              </WidgetEmptyState.AddButton>
            </Link>
          )}

          {isError && <WidgetEmptyState.TryAgainButton onClick={reloadFavouriteLinks} />}
        </>
      }
      image={isError ? <SvgWidgetsErrorState /> : <SvgNoFavouriteLinks />}
    />
  )
}
