import { useCallback } from 'react'
import { useError } from 'react-use'

export enum RenderErrorType {
  OsIsNotAvailable = 'OS is not available',
  DataIsNotAvailable = 'Data is not available',
  ForbiddenPage = 'Forbidden page',
  ForbiddenOSAccess = 'Forbidden OS access',
  NotFound = 'Not found',
  CriticalError = 'Critical error',
}

export class RenderError extends Error {
  cause!: RenderErrorType

  constructor(type: RenderErrorType) {
    super(`RenderError: ${type}`, { cause: type })
  }
}

export const mapToRenderError = (error: unknown) =>
  error instanceof RenderError ? error : new RenderError(RenderErrorType.OsIsNotAvailable)

export const useDispatchRenderError = () => {
  const dispatchError = useError()

  return useCallback(
    (type: RenderErrorType) => {
      dispatchError(new RenderError(type))
    },
    [dispatchError],
  )
}
