import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconLeft } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/profile/circle-left.svg'
import { ReactComponent as IconRight } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/profile/circle-right.svg'
import { ReactComponent as IconCircles } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/profile/circles.svg'
import { ReactComponent as IconNotifications } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/profile/notification.svg'
import circleLeft from 'pages/home/components/pitch/playgroundDashboard/assets/images/profile/circle-left.png'
import circleRight from 'pages/home/components/pitch/playgroundDashboard/assets/images/profile/circle-right.png'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProfile/SectionProfile.module.scss'
import {
  achievements,
  growths,
} from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProfile/sectionProfileMockData'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'
import { Progress } from 'pages/playground/components/progress/Progress'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const SectionProfile = ({ avatarUrl }: { avatarUrl: string }) => {
  const { userDetails } = useTenantAndUserData()

  const { firstname } = userDetails
  return (
    <div className={styles.container}>
      {/* Black edges */}
      <img className={styles.imageLeft} src={circleLeft} alt="" />
      <img className={styles.imageRight} src={circleRight} alt="" />
      <Flex className={clsx(styles.flexBaseWidth)} align="center" gap={0}>
        <IconLeft className={stylesMain.hFull} />
        {/* Content */}
        <Flex className={clsx(styles.flexBaseWidth, stylesMain.wFull)} align="center" gap={66}>
          <div className={stylesMain['w-3/5']}>
            {/* Left side */}
            <div>
              <div className={clsx(stylesMain.header, stylesMain.weirdSpacing)}>You at Coke</div>
              <Flex className={clsx(styles.flexBaseWidth, stylesMain.mt50)} align="center" gap={28}>
                <div className={stylesMain.avatarBorder}>
                  <Avatar size="l" name={firstname || ''} src={avatarUrl} />
                </div>
                <Flex
                  className={clsx(styles.notificationsContainer, styles.flexBaseWidth, stylesMain.relative)}
                  align="center"
                  gap={0}
                >
                  <Flex className={clsx(styles.notificationContainerInner)} align="center" gap={0}>
                    <IconNotifications />
                  </Flex>
                  <div className={styles.rainboxLine} />
                  <Flex
                    className={clsx(styles.notificationContainerInner, stylesMain.baseNormal)}
                    align="center"
                    gap={8}
                  >
                    You Have 2 New Projects
                    <div className={stylesMain.mt5}>
                      <WppIconArrow direction="right" color="white" />
                    </div>
                  </Flex>
                </Flex>
              </Flex>

              {/* Cards */}
              <Flex className={clsx(styles.flexBaseWidth, stylesMain.wFull, stylesMain.mt50)} align="center" gap={40}>
                <div className={stylesMain['w-1/2']}>
                  <div className={stylesMain.baseBold}>Latest Achievements</div>
                  <div className={clsx(styles.innerContainer, stylesMain.mt16)}>
                    {achievements.slice(0, 3).map((el, index) => (
                      <Flex
                        key={index}
                        className={clsx(
                          styles.flexBaseWidth,
                          styles.innerContainerrow,
                          index === 0 && styles.roundedTop,
                        )}
                        align="center"
                        gap={16}
                      >
                        <div>
                          <img src={el.icon} alt="" />
                        </div>
                        <div className={clsx(stylesMain.whiteCaptionBold, stylesMain.preWrap)}>{el.text}</div>
                      </Flex>
                    ))}
                    <Flex
                      className={clsx(styles.roundedBottom, stylesMain['text-center'], stylesMain.whiteCaptionBold)}
                      align="center"
                      justify="center"
                      gap={8}
                    >
                      Explore Learning
                      <div>
                        <WppIconArrow direction="right" color="white" />
                      </div>
                    </Flex>
                  </div>
                </div>
                <div className={stylesMain['w-1/2']}>
                  <div className={stylesMain.baseBold}>Active Growth</div>
                  <div className={clsx(styles.innerContainer, stylesMain.mt16)}>
                    {growths.slice(0, 3).map((el, index) => (
                      <Flex
                        key={index}
                        className={clsx(styles.flexBaseWidth, styles.innerContainerRowRight, styles.roundedTop)}
                        align="center"
                        gap={16}
                      >
                        <Progress value={el.value} variant="small" />
                        <div className={clsx(stylesMain.whiteCaptionBold, stylesMain.preWrap)}>{el.text}</div>
                      </Flex>
                    ))}
                    <Flex
                      className={clsx(styles.roundedBottom, stylesMain['text-center'], stylesMain.whiteCaptionBold)}
                      align="center"
                      justify="center"
                      gap={8}
                    >
                      View Experience Profile
                      <div>
                        <WppIconArrow direction="right" color="white" />
                      </div>
                    </Flex>
                  </div>
                </div>
              </Flex>
            </div>
          </div>
          <div className={stylesMain['w-2/5']}>
            <div className={stylesMain.wFull}>
              <IconCircles />
            </div>
          </div>
        </Flex>
        <IconRight className={stylesMain.hFull} />
      </Flex>
    </div>
  )
}
