import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/components/teamMember/TeamMember.module.scss'
import { Member } from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/teamMock'

interface Props {
  member: Member
}

export const TeamMember = ({ member }: Props) => (
  <Flex align="center">
    <img src={member.profileImg} alt="team member" className={styles.memberImage} />
    <div>
      <div>
        <WppTypography type="s-strong">{member.name}</WppTypography>
      </div>
      <div>
        <WppTypography type="xs-body">{member.role}</WppTypography>
      </div>
    </div>
  </Flex>
)
