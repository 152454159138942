import { DemoWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type DemoWidget = Omit<Widget, 'name'> & { name: DemoWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'demo_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [DemoWidgetType.WELCOME]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 6,
    gridColumnEnd: 17,
  },
  [DemoWidgetType.CLIENT_TENANT]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 6,
    gridColumnEnd: 9,
  },
  [DemoWidgetType.WPP_TENANT]: {
    gridRowStart: 3,
    gridColumnStart: 9,
    gridRowEnd: 6,
    gridColumnEnd: 17,
  },
}

export const demoWidgets: DemoWidget[] = [
  {
    ...gridConfig[DemoWidgetType.WELCOME],
    ...commonWidgetsConfig,
    name: DemoWidgetType.WELCOME,
    id: 'demo_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[DemoWidgetType.CLIENT_TENANT],
    ...commonWidgetsConfig,
    name: DemoWidgetType.CLIENT_TENANT,
    id: 'demo_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[DemoWidgetType.WPP_TENANT],
    ...commonWidgetsConfig,
    name: DemoWidgetType.WPP_TENANT,
    id: 'demo_3',
    createdAt: '',
    updatedAt: '',
  },
]
