import { WppCard, WppListItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/toyotaDashboard/components/waysOfWorkingWidget/WaysOfWorkingWidget.module.scss'

const ListItem = ({ text, linkTo }: { text: string; linkTo: string }) => (
  <Link to={linkTo}>
    <WppListItem>
      <WppTypography type="s-body" slot="label">
        {text}
      </WppTypography>
    </WppListItem>
  </Link>
)

export const WaysOfWorkingWidget = () => (
  <WppCard className={styles.widget}>
    <WppTypography type="m-strong">Ways of working</WppTypography>
    <ListItem text="Brief template" linkTo="/brief-template" />
    <ListItem text="Project Workbook template" linkTo="/project-work-tmpl" />
    <ListItem text="Comms Strategy" linkTo="/comms-strategy" />
    <ListItem text="Asset list" linkTo="/asset-tracking" />
  </WppCard>
)
