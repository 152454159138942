import { excludeFalsy } from '@wpp-open/content-builder'
import clsx from 'clsx'
import { useState } from 'react'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useWorkfrontAssignmentsApi } from 'api/workfront/queries/useFetchWorkfrontAssignmentsApi'
import { useWorkfrontProjectsApi } from 'api/workfront/queries/useFetchWorkfrontProjectsApi'
import { Overlay } from 'pages/home/components/pitch/playgroundDashboard/components/general/overlay/OverlayManager'
import { SectionGrowth } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionGrowth/SectionGrowth'
import { SectionNews } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionNews/SectionNews'
import { SectionOauth } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionOauth/SectionOauth'
import { SectionProfile } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProfile/SectionProfile'
import { SectionProjectHealth } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjectHealth/SectionProjectHealth'
import { SectionProjects } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjects/SectionProjects'
import { SectionSupport } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionSupport/SectionSupport'
import { SectionWorkday } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionWorkday/SectionWorkday'
import { TechBrief } from 'pages/home/components/pitch/playgroundDashboard/components/techBrief/TechBrief'
import { OverlayProvider } from 'pages/home/components/pitch/playgroundDashboard/contexts/OverlayContext'
import { useWorkfrontToken } from 'pages/home/components/pitch/playgroundDashboard/hooks/useWorkfrontToken'
import styles from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const PlaygroundDashboard = () => {
  const { userDetails } = useTenantAndUserData()
  const { token } = useWorkfrontToken()

  const [workfrontToken, setWorkfrontToken] = useState(token)

  const {
    data: workfrontProjects,
    error: errorProjects,
    isLoading: isLoadingProjects,
  } = useWorkfrontProjectsApi({
    params: { token: workfrontToken },
  })

  const {
    data: assignments,
    error: errorAssignments,
    isLoading: isLoadingAssignments,
  } = useWorkfrontAssignmentsApi({
    params: { token: workfrontToken },
  })

  const { avatarOriginal, avatarThumbnail } = userDetails
  const keys = [avatarOriginal, avatarThumbnail].filter(excludeFalsy).map(({ key }) => key)

  const { data: avatarsDownloadInfo } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys },
    enabled: !!keys.length,
  })
  const [avatarOriginalInfo, avatarThumbnailInfo] = avatarsDownloadInfo
  const avatarUrl = avatarThumbnailInfo?.signed_url || avatarOriginalInfo?.signed_url

  const [overlayVisible, setOverlayVisible] = useState(false)
  const overlayValues = {
    visible: overlayVisible,
    setVisible: () => {
      setOverlayVisible(prev => !prev)
    },
  }

  return (
    <div id="modals">
      <OverlayProvider value={overlayValues}>
        <Overlay
          width={1200}
          onClose={() => {
            setOverlayVisible(false)
          }}
          content={<TechBrief />}
          isVisible={overlayVisible}
        />

        <div className={styles['news-bg']}>
          <div className={styles.container}>
            <SectionNews avatarUrl={avatarUrl} />
          </div>
        </div>

        {(errorProjects || errorAssignments || isLoadingProjects || isLoadingAssignments) && (
          <div className={clsx(styles.container, styles.mt150)}>
            <SectionOauth setWorkfrontToken={setWorkfrontToken} loading={isLoadingAssignments || isLoadingProjects} />
          </div>
        )}

        {!errorProjects && !isLoadingProjects && (
          <div className={clsx(styles.container, styles.mt150)}>
            <SectionProjectHealth projects={workfrontProjects} />
          </div>
        )}

        {!errorAssignments && !isLoadingAssignments && (
          <div className={clsx(styles.container, styles.mt75)}>
            <SectionProjects assignments={assignments} />
          </div>
        )}

        <div className={clsx(styles['profile-bg'], styles.mt75)}>
          <div className={clsx(styles.container)}>
            <SectionProfile avatarUrl={avatarUrl} />
          </div>
        </div>

        <div className={clsx(styles['growth-bg'])}>
          <div className={clsx(styles.container)}>
            <SectionGrowth />
          </div>
        </div>

        <div className={clsx(styles.container, styles.mt88)}>
          <SectionWorkday />
        </div>

        <div className={clsx(styles['support-bg'], styles.mt75)}>
          <div className={clsx(styles.container, styles.hFull)}>
            <SectionSupport />
          </div>
        </div>
      </OverlayProvider>
    </div>
  )
}
