import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { centreOfExcellenceSections } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/centreOfExcallenceDataMock'
import styles from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/CentreOfExcellenceWidget.module.scss'
import { CentreOfExcellenceSection } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/components/centreOfExcellenceSection/CentreOfExcellenceSection'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const CentreOfExcellenceWidget = () => (
  <WidgetCard className={styles.card}>
    <WppTypography type="l-strong" className={styles.title}>
      DACH Centre of Excellence
    </WppTypography>
    <Flex wrap="wrap" className={styles.flexGap}>
      {centreOfExcellenceSections.map((section, index) => (
        <CentreOfExcellenceSection title={section.title} links={section.links} key={index} />
      ))}
    </Flex>
  </WidgetCard>
)
