import { NewsWidgetSizesMap } from 'pages/home/systemWidgets/newsWidget/types'
import { NewsWidgetConfig } from 'types/widgets/widget'

export const MAX_NEWS_AMOUNT = 5

export enum NewsWidgetCardType {
  STANDARD = 'STANDARD',
  TITLE_BELOW = 'TITLE_BELOW',
  SNIPPET = 'SNIPPET',
}

export enum WidgetSize {
  S = 'S',
  hM = 'hM',
  vM = 'vM',
  L = 'L',
}

export const widgetSizeMap = {
  [WidgetSize.S]: { width: 8, height: 2 },
  [WidgetSize.hM]: { width: 16, height: 2 },
  [WidgetSize.vM]: { width: 8, height: 4 },
  [WidgetSize.L]: { width: 16, height: 4 },
}

export enum NewsWidgetOrientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
  default = 'default',
}

export const orientationMap = {
  [WidgetSize.S]: NewsWidgetOrientation.default,
  [WidgetSize.vM]: NewsWidgetOrientation.vertical,
  [WidgetSize.hM]: NewsWidgetOrientation.horizontal,
  [WidgetSize.L]: NewsWidgetOrientation.default,
} satisfies Record<WidgetSize, NewsWidgetOrientation>

export const newsWidgetSizesMap = {
  [NewsWidgetCardType.STANDARD]: [
    { ...widgetSizeMap[WidgetSize.S], newsAmount: 3 },
    { ...widgetSizeMap[WidgetSize.hM], newsAmount: 4 },
    { ...widgetSizeMap[WidgetSize.vM], newsAmount: 4 },
    { ...widgetSizeMap[WidgetSize.L], newsAmount: 5 },
  ],
  [NewsWidgetCardType.TITLE_BELOW]: [
    { ...widgetSizeMap[WidgetSize.S], newsAmount: 1 },
    { ...widgetSizeMap[WidgetSize.hM], newsAmount: 2 },
    { ...widgetSizeMap[WidgetSize.vM], newsAmount: 2 },
    { ...widgetSizeMap[WidgetSize.L], newsAmount: 4 },
  ],
  [NewsWidgetCardType.SNIPPET]: [
    { ...widgetSizeMap[WidgetSize.hM], newsAmount: 2 },
    { ...widgetSizeMap[WidgetSize.L], newsAmount: 4 },
  ],
} satisfies NewsWidgetSizesMap

export const defaultNewsWidgetConfig = {
  cardType: NewsWidgetCardType.STANDARD,
  sourceId: [],
  isFilteredByCountry: false,
  isFilteredByPrimaryKey: false,
} satisfies NewsWidgetConfig
