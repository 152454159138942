import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { SvgImageLoadError } from 'components/svg/SvgImageLoadError'
import { useDateFormat } from 'hooks/useDateFormat'
import styles from 'pages/home/systemWidgets/newsWidget/newsPieceCard/newsPieceCard/NewsPieceCard.module.scss'

export const NewsPieceCard = ({ className, children, ...rest }: ComponentPropsWithoutRef<typeof WppCard>) => (
  <WppCard {...rest} className={clsx(styles.card, className)}>
    {children}
  </WppCard>
)

const Image = ({ src }: { src?: string }) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)

  if (!src || isImageLoadError) {
    return (
      <Flex justify="center" align="center">
        <SvgImageLoadError />
      </Flex>
    )
  }

  return (
    <div className={styles.imageContainer}>
      <img src={src} className={styles.image} onError={() => setIsImageLoadError(true)} alt="news_piece_image" />
    </div>
  )
}

const CreatedAt = ({ createdAt }: { createdAt: string }) => {
  const { formatDate } = useDateFormat()

  return (
    <WppTypography type="xs-body" className={styles.date}>
      {formatDate(createdAt)}
    </WppTypography>
  )
}

NewsPieceCard.Image = Image
NewsPieceCard.CreatedAt = CreatedAt
