import { WppCard, WppDivider, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectTrackingWidget/deadlineManagmentTrack/DeadlineManagmentTrack.module.scss'

interface DeadlineProps {
  tasks?: { name: string; projectName: string; endDate: string; projectId: string }[]
  isLoading: boolean
}

export const DeadlineManagmentTrack = ({ tasks = [], isLoading = true }: DeadlineProps) => {
  const deadlineDate = (endDate: string) => {
    const deadlineDate = new Date(endDate)
    const yyyy = deadlineDate.getFullYear()
    const mm = deadlineDate.getMonth() + 1
    const dd = deadlineDate.getDate() - 1
    return `${dd}.${mm}.${yyyy}`
  }
  return (
    <WppCard className={styles.container}>
      <WppTypography type="s-strong">Deadline Managment</WppTypography>
      {!isLoading && tasks.length > 0 && (
        <Flex className={styles.table} direction="column" gap={4}>
          <Flex className={styles.header} justify="between">
            <WppTypography type="s-strong" className={styles.th}>
              Task Project
            </WppTypography>
            <WppTypography type="s-strong">Due date</WppTypography>
          </Flex>
          <WppDivider />
          <Flex direction="column" gap={8}>
            {tasks.map(({ name, projectName, endDate, projectId }) => (
              <Link to={`/orchestration/project/${projectId}/workflow`} key={name}>
                <Flex className={styles.item} align="center" justify="between">
                  <Flex direction="column" className={styles.tr}>
                    <WppTypography type="s-body">{name}</WppTypography>
                    <WppTypography type="s-body" className={styles.itemDescription}>
                      {projectName}
                    </WppTypography>
                  </Flex>
                  <WppTypography className={styles.itemDate}>{deadlineDate(endDate)}</WppTypography>
                </Flex>
              </Link>
            ))}
          </Flex>
        </Flex>
      )}
      {!isLoading && tasks.length === 0 && (
        <Flex className={styles.table} align="center" justify="center">
          <WppTypography>Deadlines not found!</WppTypography>
        </Flex>
      )}
      {isLoading && (
        <Flex align="center" justify="center" className={styles.loading}>
          <WppSpinner size="l" />
        </Flex>
      )}
    </WppCard>
  )
}
