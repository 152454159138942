import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseQuery<F extends QueryFetcher, S, P = QueryFetcherParams<F>, R = QueryFetcherResponse<F>>({
  queryKey,
  fetcher,
  selector,
}: {
  queryKey: string
  fetcher: F
  selector: (response?: R) => S
}) {
  return function useCustomQuery(
    options: { params?: P } & Omit<
      UseQueryOptions<R, AxiosError>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select'
    > = {},
  ) {
    const { params = {}, enabled = true, ...queryOptions } = options

    const { data, isPending, ...rest } = useQuery({
      queryKey: [queryKey, params],
      queryFn: ({ signal }) => fetcher(params)(signal) as R,
      enabled,
      ...queryOptions,
    })

    return {
      ...rest,
      isLoading: enabled && isPending,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}
