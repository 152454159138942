import clsx from 'clsx'
import { ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button.module.scss'

interface Props {
  text: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'black'
  small?: boolean
}
export const Button = ({ text, iconLeft, iconRight, variant = 'primary', small = false }: Props) => {
  return (
    <Flex className={clsx(styles.flexBaseWidth, styles[variant], small && styles.small)} inline align="center" gap={8}>
      {iconLeft}
      <div>{text}</div>
      {iconRight}
    </Flex>
  )
}
