import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/easyJetDashboard/components/terminalWidget/TerminalWidget.module.scss'

export const TerminalWidget = () => {
  return (
    <WppCard size="s" className={styles.container}>
      <Flex direction="column" justify="center" gap={24} className={styles.note}>
        <WppTypography type="2xl-heading" className={styles.white}>
          terminalSix
        </WppTypography>
        <WppTypography type="s-body" className={styles.white}>
          terminalSix is bespoke to easyJet and is where we bring together our people, processes and platforms to make
          our partnership more collaborative, more efficient and more fun! This is our home for onboarding our teams,
          navigating Air Flow planning and sharing learnings to ensure we&lsquo;re continuously improving. So go ahead -
          everything we have for you and we&lsquo;re all ears, tell us what you want and need and we&lsquo;ll make it
          happen.
        </WppTypography>
      </Flex>
    </WppCard>
  )
}
