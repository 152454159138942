import { WppTypography, WppTag, WppIconCalendar } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import event0 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event0.svg'
import event1 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event1.svg'
import event2 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event2.svg'
import event3 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event3.svg'
import event4 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event4.svg'
import event5 from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/assets/event5.jpg'
import { Event, IEvent } from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/Event'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export interface IEventMock {
  date: string
  data: IEvent[]
}

const eventsMock: IEventMock[] = [
  {
    date: 'February 2023',
    data: [{ title: 'Brit Awards', startDate: '11/02/2023', endDate: '', banner: event0 }],
  },
  {
    date: 'March 2023',
    data: [
      { title: 'Oscars', startDate: '10/03/2023', endDate: '', banner: event1 },
      { title: 'Ultra in Miami', startDate: '20/03/2023', endDate: '24/03/2023', banner: event2 },
    ],
  },
  {
    date: 'April 2023',
    data: [{ title: 'Coachella', startDate: '24/04/2023', endDate: '29/04/2023', banner: event3 }],
  },
  {
    date: 'June 2023',
    data: [
      { title: 'Borneo Rainforest Music Festival', startDate: '17/06/2023', endDate: '19/06/2023', banner: event4 },
    ],
  },
  {
    date: 'July 2023',
    data: [{ title: 'Japan Fuji Rock Festival', startDate: '28/07/2023', endDate: '30/07/2023', banner: event5 }],
  },
]

export const CulturalDigestWidget = () => (
  <WidgetCard>
    <WppTypography slot="header" type="m-strong">
      Cultural digest
    </WppTypography>

    <Flex gap={12} direction="column">
      {eventsMock.map(({ date, data }, index) => (
        <Flex gap={8} key={index} direction="column" align="start">
          <WppTag label={date} variant="positive">
            <WppIconCalendar slot="icon-start" />
          </WppTag>

          {data.map((event, index) => (
            <Event event={event} key={index} />
          ))}
        </Flex>
      ))}
    </Flex>
  </WidgetCard>
)
