import { AnalyticsActionType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SvgNoSourceFound } from 'components/svg/widgets/SvgNoSourceFound'
import { SvgWidgetsErrorState } from 'components/svg/widgets/SvgWidgetsErrorState'
import { reloadWidgetProjects } from 'pages/home/systemWidgets/projectsWidget/utils'
import { useHasAccessToManageWidgets } from 'pages/home/utils'
import { WidgetEmptyState } from 'pages/home/widgetEmptyState/WidgetEmptyState'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { routesManager } from 'utils/routesManager'

interface Props {
  isError?: boolean
  isCompact?: boolean
}

export const ProjectsWidgetEmptyState = ({ isError, isCompact }: Props) => {
  const { t } = useTranslation()
  const hasAccessToManageWidgets = useHasAccessToManageWidgets()

  return (
    <WidgetEmptyState
      isCompact={isCompact}
      text={
        isError
          ? t('os.home.cannot_load', { entity: t('os.home.projects_widget.title').toLocaleLowerCase() })
          : t('os.home.projects_widget.empty_state_text')
      }
      action={
        <>
          {hasAccessToManageWidgets && !isError && (
            <Link
              to={routesManager.systemApps.orchestration.projects()}
              onClick={() =>
                trackAnalytics({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.HOME_PAGE.ACTIONS.HOME_PROJECTS_WIDGET_ADD_PROJECT,
                })
              }
            >
              <WidgetEmptyState.AddButton>{t('os.home.projects_widget.add_button_text')}</WidgetEmptyState.AddButton>
            </Link>
          )}

          {isError && <WidgetEmptyState.TryAgainButton onClick={reloadWidgetProjects} />}
        </>
      }
      image={isError ? <SvgWidgetsErrorState /> : <SvgNoSourceFound />}
    />
  )
}
