import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import hearth from 'pages/home/components/pitch/playgroundDashboard/assets/images/growth/hearth.svg'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionGrowth/SectionGrowth.module.scss'
import { pathToAdvancement } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionGrowth/sectionGrowthMockData'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'
import { ImageCard } from 'pages/playground/components/imageCard/ImageCard'

export const SectionGrowth = () => {
  return (
    <div className={stylesMain.relative}>
      <div className={styles.imageAbsolute}>
        <img src={hearth} alt="hearth icon" />
      </div>

      <div>
        <span className={styles.rainbowText}>Learn It, Live It, Love It</span>{' '}
      </div>
      <div className={stylesMain.black2xl}>Your Growth</div>
      <div className={clsx(stylesMain.blackH4, stylesMain.preWrap, stylesMain.mt24)}>
        {
          'Dive into the latest opportunities to learn and grow, become a \nmentor, or find colleagues with similar goals and interests.'
        }
      </div>
      <div className={stylesMain.mt24}>
        <Button
          text="Learn How to Advance"
          variant="secondary"
          iconRight={<WppIconArrow direction="right" color="white" />}
        />
      </div>

      <Flex className={stylesMain.mt60} align="start" gap={40}>
        {pathToAdvancement.map((item, index) => (
          <ImageCard key={index} img={item.img} text={item.text} className={stylesMain['w-1/3']} />
        ))}
      </Flex>
    </div>
  )
}
