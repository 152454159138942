import { ComponentPropsWithoutRef } from 'react'

import styles from 'components/svg/common.module.scss'

export const SvgNoSourceFound = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-testid="widget-no-source-image"
  >
    <g clipPath="url(#clip0_8434_264)">
      <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
      <path
        d="M10.004 52.6755C10.3365 51.4415 12.1635 51.4415 12.496 52.6755L12.6703 53.3222C12.791 53.7701 13.1627 54.1157 13.6333 54.2174L13.9961 54.2958C15.3346 54.5853 15.3346 56.4147 13.9961 56.7042L13.6333 56.7826C13.1627 56.8843 12.791 57.2299 12.6703 57.6778L12.496 58.3245C12.1635 59.5585 10.3365 59.5585 10.004 58.3245L9.8297 57.6778C9.70898 57.2299 9.33725 56.8843 8.8667 56.7826L8.50395 56.7042C7.16535 56.4147 7.16535 54.5853 8.50395 54.2958L8.8667 54.2174C9.33725 54.1157 9.70898 53.7701 9.8297 53.3222L10.004 52.6755Z"
        className={styles.fillPrimary400}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106 66.5577C107.381 66.5577 108.5 65.4384 108.5 64.0578C109.881 64.0578 111 65.177 111 66.5577C111 67.9384 109.881 69.0576 108.5 69.0576C107.119 69.0576 106 67.9384 106 66.5577ZM106 66.5577C106 65.177 107.119 64.0578 108.5 64.0578C108.5 62.6771 107.381 61.5579 106 61.5579C104.619 61.5579 103.5 62.6771 103.5 64.0578C103.5 65.4384 104.619 66.5577 106 66.5577Z"
        className={styles.fillPrimary200}
      />
      <rect x="36" y="51" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <rect x="36" y="61.5" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <rect x="36" y="72" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <rect x="36" y="82.5" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <path
        d="M58.7541 105.118C59.0866 103.884 60.9136 103.884 61.2462 105.118L61.4205 105.764C61.5412 106.212 61.9129 106.558 62.3835 106.66L62.7463 106.738C64.0849 107.027 64.0849 108.857 62.7463 109.146L62.3835 109.225C61.9129 109.326 61.5412 109.672 61.4205 110.12L61.2462 110.767C60.9136 112.001 59.0866 112.001 58.7541 110.767L58.5798 110.12C58.4591 109.672 58.0873 109.326 57.6167 109.225L57.254 109.146C55.9153 108.857 55.9153 107.027 57.254 106.738L57.6167 106.66C58.0873 106.558 58.4591 106.212 58.5798 105.764L58.7541 105.118Z"
        className={styles.fillPrimary300}
      />
      <path
        opacity="0.8"
        d="M22.5 39C22.5 35.6863 25.1863 33 28.5 33H91.5C94.8137 33 97.5 35.6863 97.5 39V90C97.5 93.3137 94.8137 96 91.5 96H28.5C25.1863 96 22.5 93.3137 22.5 90V39Z"
        className={styles.fillPrimary500}
      />
      <g filter="url(#filter0_b_8434_264)">
        <rect x="33.75" y="21" width="52.5" height="75" className={styles.fillWhite} fillOpacity="0.85" />
      </g>
      <g filter="url(#filter1_bd_8434_264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5 42C25.1863 42 22.5 44.6863 22.5 48V51V90C22.5 93.3137 25.1863 96 28.5 96H91.5C94.8137 96 97.5 93.3137 97.5 90V57C97.5 53.6863 94.8137 51 91.5 51H58.5L54.2812 44.6718C53.1684 43.0026 51.295 42 49.2889 42H28.5Z"
          className={styles.fillWhite}
        />
      </g>
      <ellipse cx="60.9804" cy="8.76929" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
      <circle cx="57.75" cy="12.5193" r="1.5" className={styles.fillPrimary400} />
      <path
        d="M54 90C54 89.1716 54.6716 88.5 55.5 88.5C56.3284 88.5 57 89.1716 57 90C57 90.8284 56.3284 91.5 55.5 91.5C54.6716 91.5 54 90.8284 54 90Z"
        className={styles.fillPrimary500}
      />
      <path
        d="M58.5 90C58.5 89.1716 59.1716 88.5 60 88.5C60.8284 88.5 61.5 89.1716 61.5 90C61.5 90.8284 60.8284 91.5 60 91.5C59.1716 91.5 58.5 90.8284 58.5 90Z"
        className={styles.fillPrimary500}
      />
      <path
        d="M63 90C63 89.1716 63.6716 88.5 64.5 88.5C65.3284 88.5 66 89.1716 66 90C66 90.8284 65.3284 91.5 64.5 91.5C63.6716 91.5 63 90.8284 63 90Z"
        className={styles.fillPrimary500}
      />
      <rect x="37.5" y="63.75" width="45" height="4.5" rx="1" className={styles.fillPrimary200} />
      <rect x="42" y="71.25" width="36" height="4.5" rx="1" className={styles.fillPrimary100} />
    </g>
    <defs>
      <filter
        id="filter0_b_8434_264"
        x="18.75"
        y="6"
        width="82.5"
        height="105"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8434_264" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8434_264" result="shape" />
      </filter>
      <filter
        id="filter1_bd_8434_264"
        x="10.5"
        y="34"
        width="99"
        height="78"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8434_264" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_8434_264" result="effect2_dropShadow_8434_264" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_8434_264" result="shape" />
      </filter>
      <clipPath id="clip0_8434_264">
        <rect width="120" height="120" rx="60" className={styles.fillWhite} />
      </clipPath>
    </defs>
  </svg>
)
