import {
  WppIconCalendar3Days,
  WppIconFile,
  WppIconPeople,
  WppIconVideoClip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { ProjectType } from '@wpp-open/core'
import { clsx } from 'clsx'
import { ParseKeys } from 'i18next'
import { ComponentPropsWithoutRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectsWidget/projectTypeTag/ProjectTypeTag.module.scss'

type Props = ComponentPropsWithoutRef<'div'> & {
  type: ProjectType
}

const projectTypePreset = {
  [ProjectType.Blank]: {
    value: ProjectType.Blank,
    icon: <WppIconFile />,
    title: 'os.home.projects_widget.project_tag_text.blank',
  },
  [ProjectType.Pitch]: {
    value: ProjectType.Pitch,
    icon: <WppIconVideoClip />,
    title: 'os.home.projects_widget.project_tag_text.pitch',
  },
  [ProjectType.Workshop]: {
    value: ProjectType.Workshop,
    icon: <WppIconPeople />,
    title: 'os.home.projects_widget.project_tag_text.workshop',
  },
  [ProjectType.Campaign]: {
    value: ProjectType.Campaign,
    icon: <WppIconCalendar3Days />,
    title: 'os.home.projects_widget.project_tag_text.campaign',
  },
} satisfies {
  [key in ProjectType]: {
    value: ProjectType
    icon: ReactNode
    title: ParseKeys
  }
}

export const ProjectTypeTag = ({ type, className, ...rest }: Props) => {
  const { t } = useTranslation()
  const { icon, title } = projectTypePreset[type] || {}

  return (
    <Flex {...rest} className={clsx(styles.tag, styles[type], className)} inline gap={6} align="center">
      {icon}
      <WppTypography type="s-midi" className={styles[type]} data-testid="project-type-tag">
        {t(title)}
      </WppTypography>
    </Flex>
  )
}
