import { ExcellenceHub } from 'pages/home/components/pitch/oldElPasoDashboard/components/excellenceHub/ExcellenceHub'
import { HeroWidget } from 'pages/home/components/pitch/oldElPasoDashboard/components/heroWidget/HeroWidget'
import { IntroWidget } from 'pages/home/components/pitch/oldElPasoDashboard/components/introWidget/IntroWidget'
import { MediaEffectiveness } from 'pages/home/components/pitch/oldElPasoDashboard/components/mediaEffectiveness/MediaEffectiveness'
import { MilestonesAndTimelinesWidget } from 'pages/home/components/pitch/oldElPasoDashboard/components/milestonesAndTimelinesWidget/MilestonesAndTimelinesWidget'
import styles from 'pages/home/components/pitch/oldElPasoDashboard/OldElPasoDashboard.module.scss'
import { oldElPasoWidgets } from 'pages/home/components/pitch/oldElPasoDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { OldElPasoWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | OldElPasoWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case OldElPasoWidgetType.HERO_BANNER:
      return <HeroWidget />
    case OldElPasoWidgetType.INTRO:
      return <IntroWidget />
    case OldElPasoWidgetType.MEDIA_EFFECTIVENESS:
      return <MediaEffectiveness />
    case OldElPasoWidgetType.EXCELLENCE_HUB:
      return <ExcellenceHub />
    case OldElPasoWidgetType.MILESTONES_AND_TIMELINES:
      return <MilestonesAndTimelinesWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const OldElPasoDashboard = () => (
  <div className={styles.container}>
    {oldElPasoWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
