import { PerrigoWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type PerrigoWidget = Omit<Widget, 'name'> & { name: PerrigoWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'perrigo_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [PerrigoWidgetType.BRAND]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [PerrigoWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [PerrigoWidgetType.CONTROL]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [PerrigoWidgetType.CAPABILITIES]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 9,
  },
  [PerrigoWidgetType.COLLABORATION]: {
    gridRowStart: 3,
    gridColumnStart: 9,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [PerrigoWidgetType.TEAM]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const perrigoWidgets: PerrigoWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'perrigo_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.BRAND],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.BRAND,
    id: 'perrigo_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.USER],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.USER,
    id: 'perrigo_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.CONTROL],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.CONTROL,
    id: 'perrigo_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.CAPABILITIES],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.CAPABILITIES,
    id: 'perrigo_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.COLLABORATION],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.COLLABORATION,
    id: 'perrigo_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[PerrigoWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: PerrigoWidgetType.TEAM,
    id: 'perrigo_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'perrigo_8',
    createdAt: '',
    updatedAt: '',
  },
]
