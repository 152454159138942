import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/AppsWidget.module.scss'
import activationImgSrc from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/assets/activation.png'
import ideationImgSrc from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/assets/ideation.png'
import planningImgSrc from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/assets/planning.png'
import strategyImgSrc from 'pages/home/components/pitch/haleonDashboard/components/appsWidget/assets/strategy.png'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const apps = [
  {
    name: 'Strategy',
    imgSrc: strategyImgSrc,
    scale: 70,
    top: 0,
    link: 'https://haleon.wppopen.com/occasions-strategy',
  },
  {
    name: 'Planning',
    imgSrc: planningImgSrc,
    scale: 75,
    top: 10,
    link: 'https://haleon.wppopen.com/occasions-activation',
  },
  { name: 'Ideation', imgSrc: ideationImgSrc, scale: 75, top: 10, link: 'https://haleon.wppopen.com/imagine' },
  {
    name: 'Activation',
    imgSrc: activationImgSrc,
    scale: 30,
    top: 0,
    external: true,
    link: 'https://haleon.experienceog.com/#/login',
  },
]

export const AppsWidget = () => (
  <WidgetCard className={styles.haleonAppsCard}>
    <div className={styles.haleonAppsMainContainer}>
      {apps.map(app => (
        <Link key={app.name} to={app.link} target={app?.external ? '_blank' : '_self'}>
          <div className={styles.appContainer}>
            <WppTypography type="m-strong">{app.name}</WppTypography>
            <img
              src={app.imgSrc}
              style={{ width: `${app.scale}%`, paddingTop: `${app.top}px` }}
              className={styles.haleonAppImage}
              alt=""
            />
          </div>
        </Link>
      ))}
    </div>
  </WidgetCard>
)
