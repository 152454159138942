import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import activity0 from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/assets/activity0.png'
import activity1 from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/assets/activity1.png'
import activity2 from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/assets/activity2.png'
import activity3 from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/assets/activity3.png'
import styles from 'pages/home/components/pitch/spotifyDashboard/components/topCreativesWidget/TopCreativesWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const activitiesMock = [activity0, activity1, activity2, activity3]

const tabs: { name: string; count: number }[] = [
  { name: 'My Channels', count: 8 },
  { name: 'Publisher Feed', count: 5 },
  { name: 'Influencers', count: 12 },
  { name: 'Mentions', count: 3 },
]

export const TopCreativesWidget = () => (
  <WidgetCard className={styles.socialActivityCard}>
    <WppTypography className={styles.titleText} type="m-strong">
      Industry Content Scraping
    </WppTypography>

    <div className={styles.socialActivitiesContainer}>
      <Flex gap={20}>
        <WppButton className={styles.allButton} variant="primary">
          All
        </WppButton>
        {tabs.map(({ name, count }, index) => (
          <span key={index} className={styles.socialType}>
            <WppTypography className={styles.socialText} type="s-midi">
              {name}&nbsp;
            </WppTypography>
            <WppTypography className={styles.socialText} type="s-body">
              ({count})
            </WppTypography>
          </span>
        ))}
      </Flex>

      <div className={styles.socialActivitiesGrid}>
        {activitiesMock.map((activity, index) => (
          <img src={activity} alt="" className={styles.socialActivityItemImg} key={index} />
        ))}
      </div>
    </div>
  </WidgetCard>
)
