import BelleCartwrightImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/belle-cartwright.png'
import BonnieHuImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Bonnie-Hu.png'
import ChaosHeImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Chaos-He.jpeg'
import DanHuiImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Dan-Hui.png'
import FabianKeitzmannImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/fabian-keitzmann.png'
import LilyYuImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Lily-Yu.png'
import MandyHouImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Mandy-Hou.png'
import MebrulinFranciscoImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Mebrulin_Francisco.png'
import RichardMooneyImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/richard-mooney.png'
import TingYuanImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Ting-Yuan.png'
import YanjinQuImgSrc from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/assets/Yanjin-Qu.jpeg'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Dan Hui',
    role: 'Beijing Office Head',
    profileImgSrc: DanHuiImgSrc,
  },
  {
    name: 'Mandy Hou',
    role: 'China CPO',
    profileImgSrc: MandyHouImgSrc,
  },
  {
    name: 'Lily Yu',
    role: 'Trading Commercial Head',
    profileImgSrc: LilyYuImgSrc,
  },
  {
    name: 'Yanjin Qu',
    role: 'Planning Head',
    profileImgSrc: YanjinQuImgSrc,
  },
  {
    name: 'Bonnie Hu',
    role: 'Strategy Head',
    profileImgSrc: BonnieHuImgSrc,
  },

  {
    name: 'Ting Yuan',
    role: 'Data and Tech Lead',
    profileImgSrc: TingYuanImgSrc,
  },
  {
    name: 'Chaos He',
    role: 'MediaX strategy Planning',
    profileImgSrc: ChaosHeImgSrc,
  },
  {
    name: 'Fabian Kietzmann',
    role: 'Global Account Lead',
    profileImgSrc: FabianKeitzmannImgSrc,
  },
  {
    name: 'Richard Mooney',
    role: 'Chief Data and Tech Officer',
    profileImgSrc: RichardMooneyImgSrc,
  },
  {
    name: 'Belle Cartwright',
    role: 'Global Tech Acceleration Lead',
    profileImgSrc: BelleCartwrightImgSrc,
  },
  {
    name: 'Mebrulin Francisco',
    role: 'Global Data Strat & Mar Tech Lead',
    profileImgSrc: MebrulinFranciscoImgSrc,
  },
]
