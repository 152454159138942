import { Flex } from 'components/common/flex/Flex'
import backgroundImgSrc from 'pages/home/components/pitch/generalMillsDashboard/components/heroWidget/assets/banner.png'
import heartImgSrc from 'pages/home/components/pitch/generalMillsDashboard/components/heroWidget/assets/heart.svg'
import logoImgSrc from 'pages/home/components/pitch/generalMillsDashboard/components/heroWidget/assets/logo.png'
import styles from 'pages/home/components/pitch/generalMillsDashboard/components/heroWidget/HeroWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const HeroWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex className={styles.flexContainer}>
      <div className={styles.leftContent}>
        <img src={logoImgSrc} className={styles.logo} alt="" />
      </div>
      <img src={backgroundImgSrc} className={styles.rightBackroundImg} alt="" />
    </Flex>
    <img src={heartImgSrc} className={styles.heartImg} alt="" />
  </WidgetCard>
)
