import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import milestonesAndTimelinesImgSrc from 'pages/home/components/pitch/generalMillsDashboard/components/milestonesAndTimelinesWidget/assets/milestones-and-timelines.svg'
import styles from 'pages/home/components/pitch/generalMillsDashboard/components/milestonesAndTimelinesWidget/MilestonesAndTimelinesWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MilestonesAndTimelinesWidget = () => (
  <Link to="#">
    <WidgetCard className={styles.card}>
      <WppTypography slot="header" type="m-strong" className={styles.title}>
        Milestones and Timelines
      </WppTypography>
      <Flex align="center" className={styles.imageContainer}>
        <img src={milestonesAndTimelinesImgSrc} className={styles.image} alt="" />
      </Flex>
    </WidgetCard>
  </Link>
)
