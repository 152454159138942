import {
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { StatisticCard } from 'pages/home/components/pitch/illyDashboard/components/mediaEffectiveness/components/statisticCard/StatisticCard'
import styles from 'pages/home/components/pitch/illyDashboard/components/mediaEffectiveness/MediaEffectiveness.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MediaEffectiveness = () => (
  <WidgetCard className={styles.card}>
    <Flex direction="column" className={styles.contentWrapper}>
      <Flex justify="between" className={styles.header}>
        <WppSegmentedControl value="meta">
          <WppSegmentedControlItem value="meta">Meta</WppSegmentedControlItem>
          <WppSegmentedControlItem value="youTube">YouTube</WppSegmentedControlItem>
          <WppSegmentedControlItem value="instagram">Instagram</WppSegmentedControlItem>
          <WppSegmentedControlItem value="twitter">Twitter</WppSegmentedControlItem>
          <WppSegmentedControlItem value="linkedin">LinkedIn</WppSegmentedControlItem>
        </WppSegmentedControl>
        <WppActionButton variant="secondary">All Dashboards</WppActionButton>
      </Flex>
      <Flex direction="row" gap={16} className={styles.statisticsWrapper}>
        <StatisticCard title="Cost" value="$58.375" prev30DaysValue={{ changePercentage: 24, value: '$12.212' }} />
        <StatisticCard title="Clicks" value="69.534" prev30DaysValue={{ changePercentage: 21, value: '18.176' }} />
        <StatisticCard
          title="Total Conversions"
          value="7.972"
          prev30DaysValue={{ changePercentage: -5, value: '1.565' }}
        />
      </Flex>
    </Flex>
  </WidgetCard>
)
