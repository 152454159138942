import News1 from 'assets/pitch/news-1.svg'
import News2 from 'assets/pitch/news-2.svg'
import { LeanAppWidget } from 'pages/home/components/pitch/toyotaDashboard/components/leanAppWidget/LeanAppWidget'
import { NewsWidget } from 'pages/home/components/pitch/toyotaDashboard/components/newsWidget/NewsWidget'
import { ProjectTimelineWidget } from 'pages/home/components/pitch/toyotaDashboard/components/projectTimelineWidget/ProjectTimelineWidget'
import { WaysOfWorkingWidget } from 'pages/home/components/pitch/toyotaDashboard/components/waysOfWorkingWidget/WaysOfWorkingWidget'
import styles from 'pages/home/components/pitch/toyotaDashboard/ToyotaDashboard.module.scss'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'

export const ToyotaDashboard = () => (
  <div className={styles.container}>
    <ProjectTimelineWidget />

    <div className={styles.calendarWidget}>
      <CalendarWidget
        events={[
          {
            getDate: () => new Date('2023/03/16'),
            getText: () => 'Process workshop in London',
          },
          {
            getDate: () => new Date('2023/03/17'),
            getText: () => 'Process workshop in London',
          },
          {
            getDate: () => new Date('2023/03/23'),
            getText: () => 'CHR EQ',
          },
          {
            getDate: () => new Date('2023/03/29'),
            getText: () => 'Core group in Brussels',
          },
          {
            getDate: () => new Date('2023/03/30'),
            getText: () => 'Core group in Brussels',
          },
        ]}
        clockOptions={[
          { timeZone: 'Europe/London', city: 'London' },
          { timeZone: 'Europe/Brussels', city: 'Brussels' },
        ]}
      />
    </div>

    <div className={styles.newsWidget1}>
      <NewsWidget
        title="First glimpse: Toyota collaborates with cult fashion label UNDERCOVER"
        url="https://media.toyota.co.uk/first-glimpse-toyota-collaborates-with-cult-fashion-label-undercover/"
        imageSrc={News1}
      />
    </div>

    <div className={styles.newsWidget2}>
      <NewsWidget
        imageSrc={News2}
        title="Electric car enthusiasts tantalized by new idea: converting old vehicles"
        url="https://www.reddit.com/r/EVConversion/comments/112ydfc/electric_car_enthusiasts_tantalized_by_new_idea/"
      />
    </div>

    <div className={styles.mediaInvestmentWidget}>
      <LeanAppWidget title="Media investment reporting" buttonText="Go to reporting" leanAppUrl="/mir" />
    </div>

    <div className={styles.trackingDashboardsWidget}>
      <LeanAppWidget title="Tracking dashboards" buttonText="Go to dashboards" leanAppUrl="/tr-dashboards" />
    </div>

    <div className={styles.projectsWidget}>
      <ProjectsWidgetView
        widget={{ gridRowStart: 2, gridColumnStart: 3, gridRowEnd: 4, gridColumnEnd: 5, title: 'Projects' } as any}
      />
    </div>

    <div className={styles.waysOfWorkingWidget}>
      <WaysOfWorkingWidget />
    </div>
  </div>
)
