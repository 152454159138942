import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import mapDashboard from 'pages/home/components/pitch/illyDashboard/components/mapDashboard/assets/mapDashboard.png'
import styles from 'pages/home/components/pitch/illyDashboard/components/mapDashboard/MapDashboard.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const MapDashboard = () => (
  <WidgetCard className={styles.card}>
    <WppTypography slot="header" type="m-strong">
      MAP Dashboard
    </WppTypography>
    <WppTypography slot="header" type="s-body">
      MAP is a web platform for recording and managing media activity and budgets
    </WppTypography>
    <Link to="/map">
      <img src={mapDashboard} alt="" className={styles.image} />
    </Link>
  </WidgetCard>
)
