import { createUseQuery } from 'api/common/createUseQuery'
import { fetchWorkfrontProjectsApi } from 'api/workfront/fetchers/fetchWorkfrontProjectsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectDto } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjectHealth/sectionProjectHealthMockData'

export const useWorkfrontProjectsApi = createUseQuery({
  queryKey: ApiQueryKeys.WORKFRONT_PROJECTS,
  fetcher: fetchWorkfrontProjectsApi,
  selector: res => res?.data?.data || ([] as ProjectDto[]),
})
