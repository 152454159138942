import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconLink } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/link.svg'
import { ReactComponent as IconLocation } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/workday/location.svg'
import mark from 'pages/home/components/pitch/playgroundDashboard/assets/images/workday/mark.png'
import neakita from 'pages/home/components/pitch/playgroundDashboard/assets/images/workday/neakita.png'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/workdayConnect/WorkdayConnect.module.scss'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const WorkdayConnect = () => {
  return (
    <div>
      <div className={styles.topContainer}>
        <IconLink />
        <div className={stylesMain.whiteH3}>
          Connect
          <span>
            <WppIconArrow direction="right" color="white" className={styles.arrowClass} />
          </span>
        </div>
        <div className={clsx(stylesMain.baseNormal, stylesMain.mt10)}>Meet new colleagues and build a network</div>
        <div className={stylesMain.mt16}>
          <span className={styles.borderedCircle}>+15 Points</span>
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <Flex align="center" gap={27}>
          <div>
            <img src={mark} alt="person" />
          </div>
          <div>
            <div className={stylesMain.whiteH3Mobile}>Connect With Mark Watson</div>
            <div className={stylesMain.baseNormal}>Senior Project Manager</div>
            <div className={stylesMain.baseNormal}>
              <IconLocation />
              <span>Atlanta, Georgia</span>
            </div>
          </div>
        </Flex>
        <div className={clsx(stylesMain.baseNormal, stylesMain.mt30)}>
          “I had the pleasure of working with Mark Watson as a Project Manager on several large-scale projects. Mark
          consistently demonstrated exceptional leadership skills. He’s a great mentor who delivers projects on time and
          within budget ... ”
        </div>

        <Flex className={stylesMain.mt30} align="center" gap={27}>
          <div>
            <img src={neakita} alt="person" />
          </div>
          <div>
            <div className={stylesMain.whiteActionBold}>Neakita Waldrop</div>
            <div className={clsx(stylesMain.whiteAction, stylesMain.mt8)}>Project Lead</div>
          </div>
        </Flex>

        <Flex className={stylesMain.mt30} align="center" gap={27}>
          <div className={styles.arrowButton}>
            <WppIconArrow direction="left" color="white" />
          </div>

          <div className={stylesMain.whiteAction}>1/5</div>

          <div className={styles.arrowButton}>
            <WppIconArrow direction="right" color="white" />
          </div>
        </Flex>
      </div>
    </div>
  )
}
