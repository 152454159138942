import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/kentDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.kentCard}>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.kentWorkgroupTitle}>
        Welcome to Kent OS
      </WppTypography>
      <Flex gap={4} direction="column">
        <Flex gap={4}>
          <WppTypography type="s-body">Market:</WppTypography>
          <WppTypography type="s-strong">Worldwide</WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppTypography type="s-body">Brands:</WppTypography>
          <WppTypography type="s-strong">KENT</WppTypography>
        </Flex>
      </Flex>
      <WppTypography type="s-strong" className={styles.kentRecentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <Flex gap={14} direction="column">
        <div className={styles.kentNotificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Alice Smith&nbsp;</WppTypography>
              <WppTypography type="s-body">invited you to&nbsp;</WppTypography>
              <WppTypography type="s-strong">Media Campaign Summer 2023</WppTypography>
            </div>
            <div className={styles.kentNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:46
            </WppTypography>
          </div>
        </div>
        <div className={styles.kentNotificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Alice Smith&nbsp;</WppTypography>
              <WppTypography type="s-body">updated task&nbsp;</WppTypography>
              <WppTypography type="s-strong">Review creatives for Summer 2023&nbsp;</WppTypography>
              <WppTypography type="s-body">status to &quot;Approved&quot;</WppTypography>
            </div>
            <div className={styles.kentNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:34
            </WppTypography>
          </div>
        </div>
        <div className={styles.kentNotificationCardOpened}>
          <WppTypography type="s-strong">Hanna Heine&nbsp;</WppTypography>
          <WppTypography type="s-body">assigned&nbsp;</WppTypography>
          <WppTypography type="s-strong">Prepare creative brief for KENT Summer&nbsp;</WppTypography>
          <WppTypography type="s-body">to you</WppTypography>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 11:10
            </WppTypography>
          </div>
        </div>
        <div className={styles.kentNotificationCardOpened}>
          <WppTypography type="s-strong">Hanna Heine&nbsp;</WppTypography>
          <WppTypography type="s-body">invited you to project&nbsp;</WppTypography>
          <WppTypography type="s-strong">KENT media workshop</WppTypography>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 11:00
            </WppTypography>
          </div>
        </div>
        <div className={styles.kentNotificationCardOpened}>
          <WppTypography type="s-strong">Ben Aloe&nbsp;</WppTypography>
          <WppTypography type="s-body">invited you to project&nbsp;</WppTypography>
          <WppTypography type="s-strong">Media Campaign Winter 2023</WppTypography>
        </div>
      </Flex>
    </WidgetCard>
  )
}
