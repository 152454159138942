import { BrandWidget } from 'pages/home/components/pitch/perrigoDashboard/components/brandWidget/BrandWidget'
import { CapabilitiesWidget } from 'pages/home/components/pitch/perrigoDashboard/components/capabilitiesWidget/CapabilitiesWidget'
import { CollaborationWidget } from 'pages/home/components/pitch/perrigoDashboard/components/collaborationWidget/CollaborationWidget'
import { ControlWidget } from 'pages/home/components/pitch/perrigoDashboard/components/controlWidget/ControlWidget'
import { TeamWidget } from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/perrigoDashboard/components/userWidget/UserWidget'
import styles from 'pages/home/components/pitch/perrigoDashboard/PerrigoDashboard.module.scss'
import { perrigoWidgets } from 'pages/home/components/pitch/perrigoDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { PerrigoWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | PerrigoWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case PerrigoWidgetType.BRAND:
      return <BrandWidget />
    case PerrigoWidgetType.CONTROL:
      return <ControlWidget />
    case PerrigoWidgetType.CAPABILITIES:
      return <CapabilitiesWidget />
    case PerrigoWidgetType.COLLABORATION:
      return <CollaborationWidget />
    case PerrigoWidgetType.USER:
      return <UserWidget />
    case PerrigoWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const PerrigoDashboard = () => (
  <div className={styles.container}>
    {perrigoWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
