import styles from 'pages/home/components/pitch/allwynDashboard/AllwynDashboard.module.scss'
import { NextDrawingWidget } from 'pages/home/components/pitch/allwynDashboard/components/nextDrawingWidget/NextDrawingWidget'
import { allwynWidgets } from 'pages/home/components/pitch/allwynDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { AllwynWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | AllwynWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case AllwynWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/04/11'),
              getText: () => 'In-person agency briefings in Allwyn London offices',
            },
            {
              getDate: () => new Date('2023/04/12'),
              getText: () => 'In-person agency briefings in Allwyn London offices',
            },
            {
              getDate: () => new Date('2023/04/13'),
              getText: () => 'Initial response from agencies and Q&A in Allwyn London offices',
            },
            {
              getDate: () => new Date('2023/04/14'),
              getText: () => 'Initial response from agencies and Q&A in Allwyn London offices',
            },
            {
              getDate: () => new Date('2023/04/17'),
              getText: () => 'Return of the commercial grids to Ebiquity',
            },
            {
              getDate: () => new Date('2023/04/25'),
              getText: () => 'Final pitch presentations',
            },
            {
              getDate: () => new Date('2023/04/26'),
              getText: () => 'Final pitch presentations',
            },
          ]}
          clockOptions={[{ timeZone: 'Europe/London', city: 'London' }]}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={3} />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    default:
      return null
  }
}

export const AllwynDashboard = () => (
  <div className={styles.container}>
    <div className={styles.gridContainer}>
      {allwynWidgets.map(pageWidget => {
        const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
        return (
          <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
            <RenderWidget widgetType={name} pageWidget={pageWidget} />
          </div>
        )
      })}
    </div>
    <NextDrawingWidget />
  </div>
)
