import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Logo } from 'pages/home/components/pitch/spotifyDashboard/components/processWidget/assets/Rectangle.svg'
import styles from 'pages/home/components/pitch/spotifyDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = ({ isDisabled }: { isDisabled?: boolean }) => (
  <Link to="/process/custom" className={clsx({ [styles.disabled]: isDisabled })}>
    <WidgetCard className={styles.processCard}>
      <Flex slot="header" align="center" justify="between" gap={12} className={styles.processHead}>
        <WppTypography type="m-strong" className={styles.processTitle}>
          Spotify Planning Process
        </WppTypography>
        <Logo />
      </Flex>

      <div className={styles.content} />

      <WppTypography type="s-body" className={styles.processDescription}>
        <WppTypography type="s-strong">Spotify Planning Process</WppTypography> is a powerful and integrated approach
        that leverages data and insights to drive content development and media activation.
      </WppTypography>
    </WidgetCard>
  </Link>
)
