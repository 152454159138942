import { workfrontApi } from 'api'

export const fetchWorkfrontAuthLink = ({
  tenantId = '40c0ccbc-e69f-461d-93cf-cf5e08cf9e3c',
  redirectUri,
}: {
  tenantId?: string
  redirectUri: string
}) =>
  workfrontApi.get<{ url: string }>(`/auth/link?redirectUri=${redirectUri}`, {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
