import { WppIconWarning } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ProjectHealthCard } from 'pages/home/components/pitch/playgroundDashboard/components/projectHealthCard/ProjectHealthCard'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjectHealth/SectionProjectHealth.module.scss'
import { ProjectDto } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjectHealth/sectionProjectHealthMockData'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SectionProjectHealth = ({ projects }: { projects: ProjectDto[] }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <div>
      <div className={stylesMain.blackTitle}>Your Project Health</div>

      {/* Tabs */}
      <Flex className={clsx(styles.flexBaseWidth, styles.borderTabs)} align="center" gap={0}>
        {projects.map((el, index) => {
          return (
            <div
              key={index}
              className={clsx(
                styles.tab,
                stylesMain.blackActionBold,
                selectedTabIndex === index && styles['tab-active'],
                el.projectHealth.riskIdentified > 0 && styles.iconRisk,
              )}
              onClick={() => setSelectedTabIndex(index)}
            >
              {el.name}
              {el.projectHealth.riskIdentified > 0 && (
                <div>
                  <WppIconWarning color="var(--wpp-text-color-danger)" />
                </div>
              )}
            </div>
          )
        })}
      </Flex>

      {projects.length > 0 && <ProjectHealthCard project={projects[selectedTabIndex]} />}

      {/* <div>
        {ProjectHealthData[selectedTabIndex].risks.length > 0 && (
          <div className={stylesMain.mt30}>
            <div className={styles.title}>Risks Identified</div>
            <div className={clsx(styles.container, stylesMain.mt5)}>
              {ProjectHealthData[selectedTabIndex].risks.map((risk, index) => (
                <ProjectRisk
                  key={index}
                  project={risk}
                  roundedTop={index === 0}
                  roundedBottom={index === ProjectHealthData[selectedTabIndex].risks.length - 1}
                  borderBottom={index !== ProjectHealthData[selectedTabIndex].risks.length - 1}
                />
              ))}
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}
