import { VolkswagenChinaWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type VolkswagenChinaWidget = Omit<Widget, 'name'> & { name: VolkswagenChinaWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'volkswagen_china_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [VolkswagenChinaWidgetType.IMAGE]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [VolkswagenChinaWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [VolkswagenChinaWidgetType.CALENDAR]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [VolkswagenChinaWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [VolkswagenChinaWidgetType.TEAM]: {
    gridRowStart: 8,
    gridColumnStart: 1,
    gridRowEnd: 10,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 8,
    gridColumnStart: 9,
    gridRowEnd: 10,
    gridColumnEnd: 17,
  },
  [VolkswagenChinaWidgetType.WRIKE]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 8,
    gridColumnEnd: 17,
  },
}

export const volkswagenChinaWidgets: VolkswagenChinaWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'volkswagen_china_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.IMAGE],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.IMAGE,
    id: 'volkswagen_china_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.USER],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.USER,
    id: 'volkswagen_china_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.CALENDAR],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.CALENDAR,
    id: 'volkswagen_china_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.PROCESS,
    id: 'volkswagen_china_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.TEAM],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.TEAM,
    id: 'volkswagen_china_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'volkswagen_china_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[VolkswagenChinaWidgetType.WRIKE],
    ...commonWidgetsConfig,
    name: VolkswagenChinaWidgetType.WRIKE,
    id: 'volkswagen_china_8',
    createdAt: '',
    updatedAt: '',
  },
]
