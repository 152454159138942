import { WppIconLink } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { NewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/newsPieceCard/NewsPieceCard'
import styles from 'pages/home/systemWidgets/newsWidget/newsPieceCard/titleBelowNewsPieceCard/TitleBelowNewsPieceCard.module.scss'
import { NewsPiece } from 'types/news'

export const TitleBelowNewsPieceCard = ({ newsPiece }: { newsPiece: NewsPiece }) => {
  const { banner_image: bannerImage, title, created_at: createdAt } = newsPiece

  return (
    <NewsPieceCard size="l">
      <div className={styles.content}>
        <NewsPieceCard.Image src={bannerImage} />

        <Flex direction="column" justify="between" className={styles.textContent}>
          {!!title && (
            <Truncate lines={4} type="m-strong" className={styles.title}>
              {title}
            </Truncate>
          )}

          {!!createdAt && (
            <Flex justify="between">
              <NewsPieceCard.CreatedAt createdAt={createdAt} />
              <WppIconLink />
            </Flex>
          )}
        </Flex>
      </div>
    </NewsPieceCard>
  )
}
