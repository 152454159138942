import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { useWorkfrontAuthLink } from 'api/workfront/queries/auth/useFetchWorkfrontAuthLink'
import { Flex } from 'components/common/flex/Flex'
import iconEye from 'pages/home/components/pitch/playgroundDashboard/assets/images/support/eye.png'
import iconMap from 'pages/home/components/pitch/playgroundDashboard/assets/images/support/map.png'
import iconNetwork from 'pages/home/components/pitch/playgroundDashboard/assets/images/support/network.png'
import iconScreen from 'pages/home/components/pitch/playgroundDashboard/assets/images/support/screen.png'
import iconSpeak from 'pages/home/components/pitch/playgroundDashboard/assets/images/support/speak.png'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionSupport/SectionSupport.module.scss'
import { useWorkfrontToken } from 'pages/home/components/pitch/playgroundDashboard/hooks/useWorkfrontToken'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SectionSupport = () => {
  const { removeToken } = useWorkfrontToken()
  const [authLinkEnabled, setAuthLinkEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { response: responseLink } = useWorkfrontAuthLink({
    params: { redirectUri: `${window.location.origin}/user/profile/auth/workfront` },
    enabled: authLinkEnabled,
  })

  useEffect(() => {
    if (!authLinkEnabled) return
    if (responseLink?.data.url) {
      window.open(responseLink.data.url, '_self')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseLink])

  const handleOauth = () => {
    if (isLoading) return
    removeToken()
    setAuthLinkEnabled(true)
    setIsLoading(true)
  }

  return (
    <div className={stylesMain.hFull}>
      <Flex className={clsx(styles.flexBaseWidth, stylesMain.hFull)} align="center" gap={100}>
        <div>
          <div className={styles.title}>Support</div>
          <div className={styles.button} onClick={() => handleOauth()}>
            <Button
              text="Reconnect Workfront"
              iconRight={<WppIconArrow direction="right" color="black" className={styles.buttonArrow} />}
            />
          </div>
        </div>
        {/* Icons */}
        <Flex className={clsx(styles.flexBaseWidth, stylesMain.hFull)} align="center" gap={40}>
          <div className={clsx(stylesMain['w-1/5'], stylesMain['text-center'])}>
            <img src={iconNetwork} alt="icon network" />
            <div className={styles.caption}>Get Help With Wifi</div>
          </div>
          <div className={clsx(stylesMain['w-1/5'], stylesMain['text-center'])}>
            <img src={iconScreen} alt="icon card" />
            <div className={styles.caption}>Create an IT Ticket</div>
          </div>
          <div className={clsx(stylesMain['w-1/5'], stylesMain['text-center'])}>
            <img src={iconMap} alt="icon map" />
            <div className={styles.caption}>Revisit Onboarding</div>
          </div>
          <div className={clsx(stylesMain['w-1/5'], stylesMain['text-center'])}>
            <img src={iconSpeak} alt="icon speak" />
            <div className={styles.caption}>Connect With HR</div>
          </div>
          <div className={clsx(stylesMain['w-1/5'], stylesMain['text-center'])}>
            <img src={iconEye} alt="icon eye" />
            <div className={styles.caption}>Change Password</div>
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
