import { SpotifyWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type SpotifyWidget = Omit<Widget, 'name'> & { name: SpotifyWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'spotify_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [SpotifyWidgetType.PROCESS]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [SpotifyWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [SpotifyWidgetType.CULTURAL_DIGEST]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 9,
    gridColumnEnd: 5,
  },
  [SpotifyWidgetType.METRICS]: {
    gridRowStart: 5,
    gridColumnStart: 5,
    gridRowEnd: 9,
    gridColumnEnd: 17,
  },
  [SpotifyWidgetType.TOP_CREATIVES]: {
    gridRowStart: 9,
    gridColumnStart: 1,
    gridRowEnd: 11,
    gridColumnEnd: 17,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 11,
    gridColumnStart: 1,
    gridRowEnd: 12,
    gridColumnEnd: 17,
  },
}

export const spotifyWidgets: SpotifyWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'spotify_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SpotifyWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: SpotifyWidgetType.PROCESS,
    id: 'spotify_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SpotifyWidgetType.USER],
    ...commonWidgetsConfig,
    name: SpotifyWidgetType.USER,
    id: 'spotify_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SpotifyWidgetType.CULTURAL_DIGEST],
    ...commonWidgetsConfig,
    name: SpotifyWidgetType.CULTURAL_DIGEST,
    id: 'spotify_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SpotifyWidgetType.METRICS],
    ...commonWidgetsConfig,
    name: SpotifyWidgetType.METRICS,
    id: 'spotify_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SpotifyWidgetType.TOP_CREATIVES],
    ...commonWidgetsConfig,
    name: SpotifyWidgetType.TOP_CREATIVES,
    id: 'spotify_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'spotify_8',
    createdAt: '',
    updatedAt: '',
  },
]
