import { GeneralMillsWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type GeneralMillsWidget = Omit<Widget, 'name'> & { name: GeneralMillsWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'general_mills_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [GeneralMillsWidgetType.HERO_BANNER]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 2,
    gridColumnEnd: 17,
  },
  [GeneralMillsWidgetType.INTRO]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridRowEnd: 4,
    gridColumnEnd: 9,
  },
  [GeneralMillsWidgetType.MEDIA_EFFECTIVENESS]: {
    gridRowStart: 2,
    gridColumnStart: 9,
    gridRowEnd: 4,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 4,
    gridColumnStart: 1,
    gridRowEnd: 6,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 4,
    gridColumnStart: 9,
    gridRowEnd: 8,
    gridColumnEnd: 17,
  },
  [GeneralMillsWidgetType.MILESTONES_AND_TIMELINES]: {
    gridRowStart: 6,
    gridColumnStart: 1,
    gridRowEnd: 8,
    gridColumnEnd: 9,
  },
}

export const generalMillsWidgets: GeneralMillsWidget[] = [
  {
    ...gridConfig[GeneralMillsWidgetType.HERO_BANNER],
    ...commonWidgetsConfig,
    name: GeneralMillsWidgetType.HERO_BANNER,
    id: 'general_mills_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[GeneralMillsWidgetType.INTRO],
    ...commonWidgetsConfig,
    name: GeneralMillsWidgetType.INTRO,
    id: 'general_mills_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[GeneralMillsWidgetType.MEDIA_EFFECTIVENESS],
    ...commonWidgetsConfig,
    name: GeneralMillsWidgetType.MEDIA_EFFECTIVENESS,
    id: 'general_mills_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'general_mills_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'general_mills_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[GeneralMillsWidgetType.MILESTONES_AND_TIMELINES],
    ...commonWidgetsConfig,
    name: GeneralMillsWidgetType.MILESTONES_AND_TIMELINES,
    id: 'general_mills_6',
    createdAt: '',
    updatedAt: '',
  },
]
