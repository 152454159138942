import { KentWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type KentWidget = Omit<Widget, 'name'> & { name: KentWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'kent_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [SystemWidget.PROJECTS]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 5,
  },
  [KentWidgetType.BRAND]: {
    gridRowStart: 1,
    gridColumnStart: 5,
    gridRowEnd: 3,
    gridColumnEnd: 13,
  },
  [KentWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 13,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [KentWidgetType.INNOVATION]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 5,
  },
  [KentWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 5,
    gridRowEnd: 5,
    gridColumnEnd: 13,
  },
  [KentWidgetType.POSTS]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 8,
    gridColumnEnd: 17,
  },
}

export const kentWidgets: KentWidget[] = [
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'kent_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[KentWidgetType.BRAND],
    ...commonWidgetsConfig,
    name: KentWidgetType.BRAND,
    id: 'kent_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[KentWidgetType.USER],
    ...commonWidgetsConfig,
    name: KentWidgetType.USER,
    id: 'kent_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[KentWidgetType.INNOVATION],
    ...commonWidgetsConfig,
    name: KentWidgetType.INNOVATION,
    id: 'kent_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[KentWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: KentWidgetType.PROCESS,
    id: 'kent_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[KentWidgetType.POSTS],
    ...commonWidgetsConfig,
    name: KentWidgetType.POSTS,
    id: 'kent_6',
    createdAt: '',
    updatedAt: '',
  },
]
