import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/haleonDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.haleonCard}>
      <div className={styles.haleonCardHeader}>
        <Flex justify="between" align="center">
          <WppTypography
            className={styles.headerText}
            type="m-strong"
          >{`Hello ${userDetails.firstname}!`}</WppTypography>
          <Link to="#">
            <WppTypography className={styles.headerText} type="s-strong">
              View all updates
            </WppTypography>
          </Link>
        </Flex>
        <WppTypography type="s-strong" className={styles.haleonWorkgroupTitle}>
          Welcome to Haleon OS
        </WppTypography>
        <Flex gap={4}>
          <WppTypography className={styles.headerText} type="s-body">
            Market:
          </WppTypography>
          <WppTypography className={styles.headerText} type="s-strong">
            Worldwide
          </WppTypography>
        </Flex>
      </div>
      <WppTypography type="s-strong" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <Flex gap={15} direction="column" justify="between">
        <div className={styles.notificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Quentin Delobelle&nbsp;</WppTypography>
              <WppTypography type="s-body">required a review for&nbsp;</WppTypography>
              <WppTypography type="s-strong">Voltaren: Spot the strain</WppTypography>
            </div>
            <div className={styles.haleonNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:46
            </WppTypography>
          </div>
        </div>
        <div className={styles.notificationCard}>
          <Flex gap={15} justify="between">
            <div className={styles.notificationMessage}>
              <WppTypography type="s-strong">Flora Njamfa&nbsp;</WppTypography>
              <WppTypography type="s-body">required a review for&nbsp;</WppTypography>
              <WppTypography type="s-strong">Review Creatives&nbsp;</WppTypography>
              <WppTypography type="s-body">for Project&nbsp;</WppTypography>
              <WppTypography type="s-strong">Panadol: Beat the pain at Music Festivals</WppTypography>
            </div>
            <div className={styles.haleonNotificationDot} />
          </Flex>
          <div>
            <WppTypography type="xs-body" className={styles.dateText}>
              Today at 14:34
            </WppTypography>
          </div>
        </div>
      </Flex>
    </WidgetCard>
  )
}
