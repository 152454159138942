import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import { PermittedAccess } from 'components/common/PermittedAccess'
import { SuspenseLoader } from 'components/common/SuspenseLoader'
import { RenderErrorBoundaryHeight, RenderErrorType, RouteRenderErrorBoundary } from 'components/renderError'
import {
  LOCAL_APP_BASE_URL,
  LOCAL_APP_DEFAULT_URL_CONFIG,
  LOCAL_LEGACY_APP_BASE_URL,
  LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG,
} from 'constants/apps'
import { Permission } from 'constants/permission'
import { GENERIC_TENANT_DEV_ROUTE } from 'constants/routes'
import { PageContainer } from 'layout/pageContainer/PageContainer'
import { SecureLayout } from 'layout/SecureLayout'
import { AppsNavigation } from 'pages/appsNavigation/AppsNavigation'
import { getHomeRoutes } from 'pages/home/homeRoutes'
import { MiroAuthCallback } from 'pages/miroAuth/MiroAuthCallback'
import { DataProvider } from 'providers/data/DataProvider'
import { MicroAppsProvider } from 'providers/microApps/MicroAppsProvider'
import { DevResetQueries } from 'providers/osQueryClient/DevResetQueries'
import { OsStateProvider } from 'providers/osState/OsStateProvider'
import { getHardCodedRoutes } from 'routes/specificTenantRoutes/hardcodedRoutes'
import { PitchRoutes } from 'routes/specificTenantRoutes/securedRoutesContainer/PitchRoutes'
import { SecuredRoutesContainer } from 'routes/specificTenantRoutes/securedRoutesContainer/SecuredRoutesContainer'
import { TenantHostInfo } from 'types/tenants/tenant'
import { routesManager } from 'utils/routesManager'

const NetworkRoutes = lazy(() => import('pages/network/NetworkRoutes'))
const LinksRoutes = lazy(() => import('pages/links/LinksRoutes'))
const UserRoutes = lazy(() => import('pages/user/UserRoutes'))
const AdminRoutes = lazy(() => import('pages/admin/AdminRoutes'))

export const getSpecificTenantRoutes = (hostInfo: TenantHostInfo) => (
  <Route
    element={
      <DataProvider>
        <OsStateProvider>
          <MicroAppsProvider>
            {/* Secured routes ModalProvider */}
            <NiceModalProvider>
              <SecureLayout />
            </NiceModalProvider>
          </MicroAppsProvider>
        </OsStateProvider>
      </DataProvider>
    }
  >
    <Route
      element={<SecuredRoutesContainer />}
      errorElement={
        /* Content-related issues boundary. */
        <RouteRenderErrorBoundary
          catchErrors={[
            RenderErrorType.DataIsNotAvailable,
            RenderErrorType.ForbiddenPage,
            RenderErrorType.NotFound,
            RenderErrorType.CriticalError,
          ]}
          height={RenderErrorBoundaryHeight.FullPage}
        />
      }
    >
      <Route element={<PageContainer />}>
        <Route index element={<Navigate replace to={routesManager.home.root.url()} />} />
        <Route path={routesManager.miroAuthCallback.routePath} element={<MiroAuthCallback />} />
        {PitchRoutes()}
        {getHomeRoutes(hostInfo)}
        {getHardCodedRoutes(hostInfo)}

        <Route
          path={`${routesManager.network.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.NETWORK_APP_ACCESS}>
              <SuspenseLoader>
                <NetworkRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.links.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_APPS_ACCESS}>
              <SuspenseLoader>
                <LinksRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.user.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_USERDETAILS_APP_ACCESS}>
              <SuspenseLoader>
                <UserRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.admin.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_ADMIN_SETTING_ACCESS}>
              <SuspenseLoader>
                <AdminRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />
      </Route>
      <Route path={LOCAL_APP_BASE_URL} element={<Navigate replace to={LOCAL_APP_DEFAULT_URL_CONFIG} />} />

      <Route path={LOCAL_LEGACY_APP_BASE_URL} element={<Navigate replace to={LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG} />} />

      {/**
       * Enable generic tenant workflow simulation. The generic tenant route will only be rendered when the loaded data
       * is related to specific tenant instead of generic. This happens during auth callback or when the user clicks a
       * browser's back button.
       */}
      {process.env.DEV && <Route path={`${GENERIC_TENANT_DEV_ROUTE}/*`} element={<DevResetQueries />} />}

      <Route path="*" element={<AppsNavigation />} />
    </Route>
  </Route>
)
