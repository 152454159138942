import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ImageWidget } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/userWidget/UserWidget'
import { WrikeWidget } from 'pages/home/components/pitch/volkswagenChinaDashboard/components/wrikeWidget/WrikeWidget'
import styles from 'pages/home/components/pitch/volkswagenChinaDashboard/VolkswagenChinaDashboard.module.scss'
import { volkswagenChinaWidgets } from 'pages/home/components/pitch/volkswagenChinaDashboard/widgetsMock'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { VolkswagenChinaWidgetType, SystemWidget, ProjectsWidgetDetailsType } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | VolkswagenChinaWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case VolkswagenChinaWidgetType.IMAGE:
      return <ImageWidget />
    case VolkswagenChinaWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/08/10'),
              getText: () => 'Client Demo Presentation',
            },
            {
              getDate: () => new Date('2023/08/22'),
              getText: () => "Chinese Valentine's Day",
            },
          ]}
          clockOptions={[
            { timeZone: 'Asia/Shanghai', city: 'Shanghai' },
            { timeZone: 'Europe/Budapest', city: 'Wolfsburg' },
          ]}
        />
      )
    case VolkswagenChinaWidgetType.PROCESS:
      return <ProcessWidget />
    case VolkswagenChinaWidgetType.USER:
      return <UserWidget />
    case VolkswagenChinaWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    case VolkswagenChinaWidgetType.WRIKE:
      return <WrikeWidget />
    default:
      return null
  }
}

export const VolkswagenChinaDashboard = () => (
  <div className={styles.container}>
    {volkswagenChinaWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
