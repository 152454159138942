import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/uberDashboard/components/userWidget/UserWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const UserWidget = () => {
  const { userDetails } = useTenantAndUserData()

  return (
    <WidgetCard className={styles.uberCard}>
      <Flex justify="between" align="center">
        <WppTypography type="m-strong">{`Hello ${userDetails.firstname}!`}</WppTypography>
        <Link to="#">
          <WppTypography type="s-strong">View all updates</WppTypography>
        </Link>
      </Flex>
      <WppTypography type="s-strong" className={styles.workgroupTitle}>
        Your workgroup details:
      </WppTypography>
      <Flex gap={4}>
        <WppTypography type="s-body">Market:</WppTypography>
        <WppTypography type="s-strong">Worldwide</WppTypography>
      </Flex>
      <Flex gap={4}>
        <WppTypography type="s-body">Brand:</WppTypography>
        <WppTypography type="s-strong">Uber and Uber Eats</WppTypography>
      </Flex>
      <WppTypography type="s-strong" className={styles.recentUpdatesTitle}>
        Your recent updates:
      </WppTypography>
      <div className={styles.notificationCard}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-body">Kelly Geer updated task Media strategy for&nbsp;</WppTypography>
            <WppTypography type="s-strong">Uber One - Eat and Ride&nbsp;</WppTypography>
            <WppTypography type="s-body">status to &quot;Completed&quot;</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 14:34
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCard}>
        <Flex gap={15} justify="between">
          <div className={styles.notificationMessage}>
            <WppTypography type="s-body">Rob Frost assigned Publisher brief for&nbsp;</WppTypography>
            <WppTypography type="s-strong">Eats - Get anything&nbsp;</WppTypography>
            <WppTypography type="s-body">to you</WppTypography>
          </div>
          <div className={styles.notificationDot} />
        </Flex>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 13:52
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-body">Jess Lenehan invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">Earner Mobility&nbsp;</WppTypography>
        <WppTypography type="s-body">project</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 12:11
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-body">Juan Bazan invited you to&nbsp;</WppTypography>
        <WppTypography type="s-strong">Eats - Get anything&nbsp;</WppTypography>
        <WppTypography type="s-body">workshop</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            Today at 11:10
          </WppTypography>
        </div>
      </div>
      <div className={styles.notificationCardOpened}>
        <WppTypography type="s-body">James Reynolds updated task Invoicing for&nbsp;</WppTypography>
        <WppTypography type="s-strong">Earner Mobility&nbsp;</WppTypography>
        <WppTypography type="s-body">status to &quot;Completed&quot;</WppTypography>
        <div>
          <WppTypography type="xs-body" className={styles.dateText}>
            06/07/2023 at 16:23
          </WppTypography>
        </div>
      </div>
    </WidgetCard>
  )
}
