import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { useWrikeMetricsApi } from 'api/wrike/queries/useFetchWrikeMetricsApi'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/nestleDashboard/components/projectStatistic/ProjectStatistic.module.scss'
import { DeadlineManagmentTrack } from 'pages/home/systemWidgets/projectTrackingWidget/deadlineManagmentTrack/DeadlineManagmentTrack'
import { OveralProjectStatisticTrack } from 'pages/home/systemWidgets/projectTrackingWidget/overalProjectStatisticTrack/OveralProjectStatisticTrack'
import { ProjectCreatedTrack } from 'pages/home/systemWidgets/projectTrackingWidget/projectCreatedTrack/ProjectCreatedTrack'
import { RiskAssessmentTrack } from 'pages/home/systemWidgets/projectTrackingWidget/riskAssessmentTrack/RiskAssessmentTrack'
import { TaskProgressTrack } from 'pages/home/systemWidgets/projectTrackingWidget/taskProgressTrack/TaskProgressTrack'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const ProjectStatistic = () => {
  const { currentTenant } = useTenantAndUserData()
  const { data, isLoading } = useWrikeMetricsApi({
    params: currentTenant.id,
    enabled: !!currentTenant.id,
  })

  return (
    <WppCard className={styles.container}>
      <Flex direction="column" gap={24}>
        <WppTypography type="l-strong">Projects Progress Tracking</WppTypography>
        <Flex gap={24} className={styles.wrap}>
          <ProjectCreatedTrack
            total={data?.totalAmountOfProjects}
            count={data?.numberOfRecentlyCreatedProjects}
            isLoading={isLoading}
          />
          <OveralProjectStatisticTrack
            active={data?.numberOfActiveProjects}
            completed={data?.numberOfCompletedProjects}
            archived={data?.numberOfArchivedProjects}
            total={data?.totalAmountOfProjects}
            isLoading={isLoading}
          />
          <TaskProgressTrack
            toDo={data?.numberOfToDoTasks}
            inProgress={data?.numberOfInProgressTasks}
            archived={data?.numberOfArchivedTasks}
            completed={data?.numberOfCompletedTasks}
            isLoading={isLoading}
          />
          <RiskAssessmentTrack
            assign={data?.numberOfTasksWithoutAssignee}
            deadline={data?.numberOfTasksWithoutEndData}
            onTrack={data?.numberOfTasksWithEndDateAndAssignee}
            total={data?.totalAmountOfTasks}
            isLoading={isLoading}
          />
          <DeadlineManagmentTrack tasks={data?.tasksOverdue} isLoading={isLoading} />
        </Flex>
      </Flex>
    </WppCard>
  )
}
