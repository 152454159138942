import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ImageWidget } from 'pages/home/components/pitch/volkswagenJapanDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/volkswagenJapanDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/volkswagenJapanDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/volkswagenJapanDashboard/components/userWidget/UserWidget'
import { WrikeWidget } from 'pages/home/components/pitch/volkswagenJapanDashboard/components/wrikeWidget/WrikeWidget'
import styles from 'pages/home/components/pitch/volkswagenJapanDashboard/VolkswagenJapanDashboard.module.scss'
import { volkswagenJapanWidgets } from 'pages/home/components/pitch/volkswagenJapanDashboard/widgetsMock'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { VolkswagenJapanWidgetType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | VolkswagenJapanWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case VolkswagenJapanWidgetType.IMAGE:
      return <ImageWidget />
    case VolkswagenJapanWidgetType.CALENDAR:
      return (
        <CalendarWidget
          dateFormat="d/M/yyyy"
          events={[
            {
              getDate: () => new Date('2023/08/11'),
              getText: () => 'Mountain Day',
            },
          ]}
          clockOptions={[{ timeZone: 'Asia/Tokyo', city: 'Japan/Tokyo' }]}
        />
      )
    case VolkswagenJapanWidgetType.PROCESS:
      return <ProcessWidget />
    case VolkswagenJapanWidgetType.USER:
      return <UserWidget />
    case VolkswagenJapanWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return <ProjectsWidgetView widget={{ ...pageWidget, title: 'Projects' } as any} />
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    case VolkswagenJapanWidgetType.WRIKE:
      return <WrikeWidget />
    default:
      return null
  }
}

export const VolkswagenJapanDashboard = () => (
  <div className={styles.container}>
    {volkswagenJapanWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
