import CindyWalter from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Cindy_Walter.jpeg'
import DanBrown from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Dan_Brown.jpeg'
import FabianKrietzmann from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Fabian_Kietzmann.jpeg'
import JimKensicki from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Jim_Kensicki.jpeg'
import LindaWallner from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Linda_Wallner.jpeg'
import NilsChristmann from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Nils_Christmann.jpeg'
import NinaNelson from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Nina_Nelson.jpeg'
import SamHoffmann from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Sam_Hoffmann.jpeg'
import ShaunFrazao from 'pages/home/components/pitch/beiersdorfDashboard/components/teamWidget/assets/Shaun_Frazao.jpeg'

export interface Member {
  name: string
  role: string
  profileImg: string
}

export interface Team {
  name: string
  members: Member[]
}

export const teams: Team[] = [
  {
    name: 'EUNA Client & CX Planning Team',
    members: [
      {
        name: 'Cindy Walter',
        role: 'EUNA Client Lead',
        profileImg: CindyWalter,
      },
      {
        name: 'Shaun Frazao',
        role: 'EUNA CX Planning Lead',
        profileImg: ShaunFrazao,
      },
      {
        name: 'Dan Brown',
        role: 'EUNA CX Planning Lead',
        profileImg: DanBrown,
      },
    ],
  },
  {
    name: 'EU ABCTeam',
    members: [
      {
        name: 'Fabian Kietzmann',
        role: 'EU ABC Lead',
        profileImg: FabianKrietzmann,
      },
      {
        name: 'Nils Christmann',
        role: 'DE Programmatic Lead',
        profileImg: NilsChristmann,
      },
      {
        name: 'Linda Wallner',
        role: 'DE Ad Ops Lead',
        profileImg: LindaWallner,
      },
    ],
  },
  {
    name: 'NA ABC Team',
    members: [
      {
        name: 'Jim Kensicki',
        role: 'NA ABC Lead',
        profileImg: JimKensicki,
      },
      {
        name: 'Sam Hoffmann',
        role: 'US Programmatic Lead',
        profileImg: SamHoffmann,
      },
      {
        name: 'Nina Nelson',
        role: 'US Ad Ops Lead',
        profileImg: NinaNelson,
      },
    ],
  },
]
