import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import {
  teamsList,
  groupedTeamMembers,
} from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/teamMembersMock'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/TeamWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const TeamWidget = () => {
  return (
    <Flex className={styles.widgetContainer}>
      {teamsList.map((team, teamIdx) => (
        <WidgetCard key={teamIdx} className={styles.card}>
          <div className={styles.container}>
            {groupedTeamMembers[team.id].map(({ name, position, imageSrc }, idx) => (
              <div className={styles.teamMemberContainer} key={idx}>
                <img className={styles.avatar} src={imageSrc} alt={name} />
                <div className={styles.teamMemberInfo}>
                  <WppTypography type="s-strong" className={styles.teamMemberName}>
                    {name}
                  </WppTypography>
                  {/* <WppTypography type="s-body" className={styles.emailLink}>
                    {email}
                  </WppTypography> */}
                  <WppTypography type="xs-body" className={styles.teamMemberPosition}>
                    {position}
                  </WppTypography>
                </div>
              </div>
            ))}
          </div>
        </WidgetCard>
      ))}
    </Flex>
  )
}
