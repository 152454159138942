import TeamMember1ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_1.png'
import TeamMember2ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_2.png'
import TeamMember3ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_3.png'
import TeamMember4ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_4.png'
import TeamMember5ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_5.png'
import TeamMember6ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_6.png'
import TeamMember7ImgSrc from 'pages/home/components/pitch/perrigoDashboard/components/teamWidget/assets/members/teamMember_7.png'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Jamie Willard',
    role: 'Global Client Lead',
    profileImgSrc: TeamMember1ImgSrc,
  },
  {
    name: 'Brendan Ashe',
    role: 'Digital Excellence Lead',
    profileImgSrc: TeamMember2ImgSrc,
  },
  {
    name: 'Rita Harnett',
    role: 'Global eCommerce Lead',
    profileImgSrc: TeamMember3ImgSrc,
  },
  {
    name: 'Chris Worrell',
    role: 'Global Strategy Lead',
    profileImgSrc: TeamMember4ImgSrc,
  },
  {
    name: 'Caroline Banquet ',
    role: 'Division Strategy Lead: OTC',
    profileImgSrc: TeamMember5ImgSrc,
  },
  {
    name: 'Inna Mekina',
    role: 'Division Strategy Lead: Women’s Health',
    profileImgSrc: TeamMember6ImgSrc,
  },
  {
    name: 'Francis Turner',
    role: 'Division Strategy Lead: Skincare',
    profileImgSrc: TeamMember7ImgSrc,
  },
]
