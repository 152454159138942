import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/home/components/pitch/nestleDashboard/components/teamWidget/components/teamCard/TeamCard.module.scss'
import { TeamMember } from 'pages/home/components/pitch/nestleDashboard/components/teamWidget/components/teamMember/TeamMember'
import { Team } from 'pages/home/components/pitch/nestleDashboard/components/teamWidget/teamMock'

interface Props {
  team: Team
}

export const TeamCard = ({ team }: Props) => (
  <div className={styles.card}>
    <WppTypography type="m-strong" className={styles.title}>
      {team.country}
    </WppTypography>
    <div>
      {team.members.map((member, id) => (
        <TeamMember member={member} key={id} />
      ))}
    </div>
  </div>
)
