import clock from 'pages/playground/pages/profile/assets/images/growth/clock.png'
import hand from 'pages/playground/pages/profile/assets/images/growth/hand.png'
import human from 'pages/playground/pages/profile/assets/images/growth/human.png'

export const pathToAdvancement = [
  {
    img: clock,
    text: 'Manage Your Time and Your Opportunities',
  },
  {
    img: hand,
    text: 'How to be Selected for the Project You Want',
  },
  {
    img: human,
    text: 'Getting the Most Out of Your Experience Profile',
  },
]
