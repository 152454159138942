import { WppTypography } from '@platform-ui-kit/components-library-react'

import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import { useGetDevHubLogoUrl } from 'components/hierarchyAppsManagement/utils'
import { AppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/appMenuItem/AppNavigationMenuItem'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { AppInstanceShort } from 'types/apps/appInstances'

interface Props {
  appInstances: AppInstanceShort[]
}

export const AppGroupAppsNavigationMenuColumn = ({ appInstances }: Props) => {
  const getDevHubLogoUrl = useGetDevHubLogoUrl(appInstances)
  const group = appInstances.at(0)!.group!
  const visibleAppInstances = appInstances.filter(app => app.flags.visible)

  return (
    <NavigationMenuTreeListColumn data-testid="apps-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="apps-navigation-menu-column-title">
          {group.name}
        </WppTypography>
      </NavigationMenuColumnHeader>

      <Flex as={FadeIn} direction="column" gap={2}>
        {visibleAppInstances.map(appInstance => (
          <AppNavigationMenuItem
            key={appInstance.id}
            appInstance={appInstance}
            logoUrl={getDevHubLogoUrl(appInstance.devhubMetadata.logoMeta?.thumbnail.key)}
          />
        ))}
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}
