import { Link } from 'react-router-dom'

import { FavouriteLinksWidgetCard } from 'pages/home/systemWidgets/favouriteLinksWidget/FavouriteLinksWidgetCard'
import { FavouriteLinksWidgetEmptyState } from 'pages/home/systemWidgets/favouriteLinksWidget/FavouriteLinksWidgetEmptyState'
import {
  FavouriteLinksWidgetList,
  LoadingFavouriteLinksWidgetList,
} from 'pages/home/systemWidgets/favouriteLinksWidget/FavouriteLinksWidgetList'
import {
  useHasAccessToFavouriteLinks,
  useFavouriteLinks,
  handleTrackFavouriteLinksViewAll,
} from 'pages/home/systemWidgets/favouriteLinksWidget/utils'
import { isCompactEmptyOrErrorState } from 'pages/home/utils'
import { ViewAllButton } from 'pages/home/ViewAllButton'
import { WidgetTitle } from 'pages/home/WidgetTitle'
import { FavouriteLinksWidgetDTO } from 'types/widgets/widget'
import { routesManager } from 'utils/routesManager'

interface Props {
  widget: FavouriteLinksWidgetDTO
}

export const FavouriteLinksWidgetView = ({ widget }: Props) => {
  const { data: favouriteLinks, isError, isLoading } = useFavouriteLinks()
  const hasAccess = useHasAccessToFavouriteLinks()

  const { title, width, height } = widget

  return (
    <FavouriteLinksWidgetCard>
      <WidgetTitle>{title}</WidgetTitle>

      {!isError && hasAccess && (
        <Link slot="actions" onClick={handleTrackFavouriteLinksViewAll} to={routesManager.links.url()}>
          <ViewAllButton />
        </Link>
      )}

      {isLoading ? (
        <LoadingFavouriteLinksWidgetList width={width} height={height} />
      ) : (
        <>
          {!favouriteLinks.length ? (
            <FavouriteLinksWidgetEmptyState
              isError={isError}
              isCompact={isCompactEmptyOrErrorState({ width, height })}
            />
          ) : (
            <FavouriteLinksWidgetList favouriteLinks={favouriteLinks} width={width} height={height} />
          )}
        </>
      )}
    </FavouriteLinksWidgetCard>
  )
}
