import { lazy } from 'react'

import { AldiDashboard } from 'pages/home/components/pitch/aldiDashboard/AldiDashboard'
import { AllwynDashboard } from 'pages/home/components/pitch/allwynDashboard/AllwynDashboard'
import { AxaDashboard } from 'pages/home/components/pitch/axaDashboard/AxaDashboard'
import { BeiersdorfDashboard } from 'pages/home/components/pitch/beiersdorfDashboard/BeiersdorfDashboard'
import { CentralDashboard } from 'pages/home/components/pitch/centralDashboard/CentralDashboard'
import { CheeriosDashboard } from 'pages/home/components/pitch/cheeriosDashboard/CheeriosDashboard'
import { DemoDashboard } from 'pages/home/components/pitch/demoDashboard/DemoDashboard'
import { EasyJetDashboard } from 'pages/home/components/pitch/easyJetDashboard/EasyJetDashboard'
import { GeneralMillsDashboard } from 'pages/home/components/pitch/generalMillsDashboard/GeneralMillsDashboard'
import { HaleonDashboard } from 'pages/home/components/pitch/haleonDashboard/HaleonDashboard'
import { IllyDashboard } from 'pages/home/components/pitch/illyDashboard/IllyDashboard'
import { KentDashboard } from 'pages/home/components/pitch/kentDashboard/KentDashboard'
import { NestleDashboard } from 'pages/home/components/pitch/nestleDashboard/NestleDashboard'
import { OldElPasoDashboard } from 'pages/home/components/pitch/oldElPasoDashboard/OldElPasoDashboard'
import { OrangeDashboard } from 'pages/home/components/pitch/orangeDashboard/OrangeDashboard'
import { PawsAndTailsDashboard } from 'pages/home/components/pitch/pawsAndTailsDashboard/PawsAndTailsDashboard'
import { PerrigoDashboard } from 'pages/home/components/pitch/perrigoDashboard/PerrigoDashboard'
import { PfizerDashboard } from 'pages/home/components/pitch/pfizerDashboard/PfizerDashboard'
import { PlaygroundDashboard } from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard'
import { SpotifyDashboard } from 'pages/home/components/pitch/spotifyDashboard/SpotifyDashboard'
import { ToyotaDashboard } from 'pages/home/components/pitch/toyotaDashboard/ToyotaDashboard'
import { UberDashboard } from 'pages/home/components/pitch/uberDashboard/UberDashboard'
import { ValueRetailDashboard } from 'pages/home/components/pitch/valueRetailDashboard/ValueRetailDashboard'
import { VolkswagenChinaDashboard } from 'pages/home/components/pitch/volkswagenChinaDashboard/VolkswagenChinaDashboard'
import { VolkswagenDashboard } from 'pages/home/components/pitch/volkswagenDashboard/VolkswagenDashboard'
import { VolkswagenJapanDashboard } from 'pages/home/components/pitch/volkswagenJapanDashboard/VolkswagenJapanDashboard'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

const Dashboard = lazy(() => import('pages/home/dashboard/Dashboard'))

export enum Tenants {
  PawsAndTails = 'Paws and Tails',
  Pfizer = 'Pfizer',
  Allwyn = 'Allwyn',
  Demo = 'Demo',
  Nestle = 'Nestle',
  EasyJet = 'EasyJet',
  Central = 'Central',
  Toyota = 'Toyota',
  GeneralMills = 'General Mills',
  OldElPaso = 'Old El Paso',
  Cheerios = 'Cheerios',
  ValueRetail = 'Value Retail Group',
  Volkswagen = 'Volkswagen',
  VolkswagenChina = 'VW China',
  VolkswagenJapan = 'VW Japan',
  Beiersdorf = 'Beiersdorf',
  AXA = 'AXA',
  Uber = 'Uber',
  Orange = 'Orange',
  Aldi = 'Aldi',
  Haleon = 'Haleon',
  Perrigo = 'Perrigo',
  Kent = 'Kent',
  Illy = 'Illy',
  Spotify = 'Spotify',
  Playground = 'Playground',
}

export const TenantDashboard = () => {
  const { currentTenant } = useTenantAndUserData()

  switch (currentTenant.name) {
    case Tenants.Allwyn:
      return <AllwynDashboard />
    case Tenants.Demo:
      return <DemoDashboard />
    case Tenants.PawsAndTails:
      return <PawsAndTailsDashboard />
    case Tenants.Pfizer:
      return <PfizerDashboard />
    case Tenants.Nestle:
      return <NestleDashboard />
    case Tenants.EasyJet:
      return <EasyJetDashboard />
    case Tenants.Central:
      return <CentralDashboard />
    case Tenants.Toyota:
      return <ToyotaDashboard />
    case Tenants.GeneralMills:
      return <GeneralMillsDashboard />
    case Tenants.OldElPaso:
      return <OldElPasoDashboard />
    case Tenants.Cheerios:
      return <CheeriosDashboard />
    case Tenants.ValueRetail:
      return <ValueRetailDashboard />
    case Tenants.Volkswagen:
      return <VolkswagenDashboard />
    case Tenants.VolkswagenChina:
      return <VolkswagenChinaDashboard />
    case Tenants.VolkswagenJapan:
      return <VolkswagenJapanDashboard />
    case Tenants.Beiersdorf:
      return <BeiersdorfDashboard />
    case Tenants.AXA:
      return <AxaDashboard />
    case Tenants.Uber:
      return <UberDashboard />
    case Tenants.Orange:
      return <OrangeDashboard />
    case Tenants.Aldi:
      return <AldiDashboard />
    case Tenants.Haleon:
      return <HaleonDashboard />
    case Tenants.Perrigo:
      return <PerrigoDashboard />
    case Tenants.Kent:
      return <KentDashboard />
    case Tenants.Illy:
      return <IllyDashboard />
    case Tenants.Spotify:
      return <SpotifyDashboard />
    case Tenants.Playground:
      return <PlaygroundDashboard />
    default:
      return <Dashboard />
  }
}
