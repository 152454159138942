import { MayBeNull } from '@wpp-open/core'
import qs from 'qs'

import { newsApi } from 'api'
import { NewsPiece } from 'types/news'

type NewsWidgetFilters = MayBeNull<{
  countryAlpha2Codes?: string | string[]
  tenantCredentialsIds?: string[]
  primary: boolean
}>
interface Params {
  tenantId: string
  filter?: NewsWidgetFilters
}

export const fetchNewsApi = ({ tenantId, filter }: Params) =>
  newsApi.get<NewsPiece[]>('/news', {
    params: {
      tenant_id: tenantId,
      filter,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
