import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/components/teamCard/TeamCard.module.scss'
import { TeamMember } from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/components/teamMember/TeamMember'
import { Team } from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/teamMock'

interface Props {
  team: Team
  viewAll?: boolean
}

export const TeamCard = ({ team, viewAll }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.aldiTeamCard}>
      <WppTypography type="m-strong" className={styles.aldiTitle}>
        {team.name}
      </WppTypography>
      <Flex direction="column" gap={20}>
        {team.members.map((member, id) => (
          <TeamMember member={member} key={id} />
        ))}
      </Flex>
      {viewAll && (
        <Link to="#">
          <WppTypography type="xs-body" className={styles.viewAllTeamLinkText}>
            {t('os.home.view_all')}
          </WppTypography>
        </Link>
      )}
    </div>
  )
}
