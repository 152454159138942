import { MicroAppCustomProps } from '@wpp-open/core'
import { useEffect } from 'react'

import { environment } from 'environment'
import { useMicroAppsContext } from 'providers/microApps/MicroAppsContext'
import { MicroAppLibraryType } from 'types/apps/microApps'
import { AppConfig } from 'types/common/singleSpa'
import { registerApp, unregisterApp } from 'utils/singleSpa'

const APP_CODE = 'OS-TECH-BRIEF'

const getAppConfig = (customProps: MicroAppCustomProps): AppConfig => ({
  stableId: APP_CODE,
  libraryType: MicroAppLibraryType.SystemJS,
  windowLibraryName: null,
  bundleUrl: environment.TECH_BRIEF_BUNDLE_URL,
  activeWhen: () => true,
  customProps,
})

export const TechBrief = () => {
  const { getMicroAppCustomProps } = useMicroAppsContext()

  useEffect(() => {
    const customProps: MicroAppCustomProps = {
      ...getMicroAppCustomProps({ stableId: APP_CODE }),
      domElementGetter: () => document.getElementById('tech-brief')!,
    }

    registerApp(getAppConfig(customProps))

    return () => {
      unregisterApp({ stableId: APP_CODE })
    }
  }, [getMicroAppCustomProps])

  return <div id="tech-brief" style={{ height: 'calc(100% - 30px)' }} />
}
