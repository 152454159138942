import { WppActionButton, WppIconAdd, WppIconRefresh, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/widgetEmptyState/WidgetEmptyState.module.scss'

interface Props {
  text: string
  action?: ReactNode
  image?: ReactNode
  className?: string
  isCompact?: boolean
}

export const WidgetEmptyState = ({
  text,
  action,
  image,

  className,
  isCompact = false,
}: Props) => (
  <Flex
    className={clsx(styles.root, className)}
    direction="column"
    align="center"
    justify="center"
    gap={isCompact ? 4 : 20}
    data-testid="widget-empty-state"
  >
    {!isCompact && image}

    <WppTypography type="m-strong" className={styles.text} data-testid="widget-empty-state-text">
      {text}
    </WppTypography>

    {action}
  </Flex>
)

const AddButton = ({ children, onClick }: PropsWithChildren<{ onClick?: () => void }>) => (
  <WppActionButton onClick={onClick} data-testid="widget-add-button">
    <WppIconAdd slot="icon-start" />
    {children}
  </WppActionButton>
)

const TryAgainButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <WppActionButton onClick={onClick} data-testid="try-again-button">
      <WppIconRefresh slot="icon-start" />
      {t('os.home.try_again')}
    </WppActionButton>
  )
}

WidgetEmptyState.AddButton = AddButton
WidgetEmptyState.TryAgainButton = TryAgainButton
