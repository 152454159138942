import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import styles from 'pages/home/components/pitch/playgroundDashboard/components/workdayCard/WorkdayCard.module.scss'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  points: number
  title: string
  caption: string
  children?: ReactNode
}

export const WorkdayCard = ({ points, title, caption, children }: Props) => {
  return (
    <div className={clsx(styles.container)}>
      {children}
      <div className={styles.title}>
        {title}
        <span>
          <WppIconArrow direction="right" color="black" className={styles.arrowClass} />
        </span>
      </div>
      <div className={clsx(stylesMain.preWrap, stylesMain.captionregular, stylesMain.mt10)}>{caption}</div>
      <div className={stylesMain.mt20}>
        <span className={styles.borderedCircle}> {'+' + points + ' Points'}</span>{' '}
      </div>
    </div>
  )
}
