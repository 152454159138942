import { CheeriosWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type CheeriosWidget = Omit<Widget, 'name'> & { name: CheeriosWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'cheerios_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [CheeriosWidgetType.HERO_BANNER]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 2,
    gridColumnEnd: 17,
  },
  [CheeriosWidgetType.INTRO]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridRowEnd: 4,
    gridColumnEnd: 9,
  },
  [CheeriosWidgetType.MEDIA_EFFECTIVENESS]: {
    gridRowStart: 2,
    gridColumnStart: 9,
    gridRowEnd: 4,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 4,
    gridColumnStart: 1,
    gridRowEnd: 6,
    gridColumnEnd: 9,
  },
  [CheeriosWidgetType.EXCELLENCE_HUB]: {
    gridRowStart: 4,
    gridColumnStart: 9,
    gridRowEnd: 6,
    gridColumnEnd: 17,
  },
  [CheeriosWidgetType.MILESTONES_AND_TIMELINES]: {
    gridRowStart: 6,
    gridColumnStart: 1,
    gridRowEnd: 8,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 6,
    gridColumnStart: 9,
    gridRowEnd: 8,
    gridColumnEnd: 17,
  },
}

export const cheeriosWidgets: CheeriosWidget[] = [
  {
    ...gridConfig[CheeriosWidgetType.HERO_BANNER],
    ...commonWidgetsConfig,
    name: CheeriosWidgetType.HERO_BANNER,
    id: 'cheerios_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CheeriosWidgetType.INTRO],
    ...commonWidgetsConfig,
    name: CheeriosWidgetType.INTRO,
    id: 'cheerios_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CheeriosWidgetType.MEDIA_EFFECTIVENESS],
    ...commonWidgetsConfig,
    name: CheeriosWidgetType.MEDIA_EFFECTIVENESS,
    id: 'cheerios_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'cheerios_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CheeriosWidgetType.EXCELLENCE_HUB],
    ...commonWidgetsConfig,
    name: CheeriosWidgetType.EXCELLENCE_HUB,
    id: 'cheerios_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[CheeriosWidgetType.MILESTONES_AND_TIMELINES],
    ...commonWidgetsConfig,
    name: CheeriosWidgetType.MILESTONES_AND_TIMELINES,
    id: 'cheerios_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'cheerios_7',
    createdAt: '',
    updatedAt: '',
  },
]
