import { WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import mediaEffectivenessImgSrc from 'assets/pitch/pfizer/media_effectiveness.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/dashboardsWidget/DashboardsWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const DashboardsWidget = () => {
  return (
    <WidgetCard className={styles.card}>
      <WppTypography slot="header" type="m-strong">
        Media effectiveness
      </WppTypography>

      <Link slot="actions" to="#">
        <WppActionButton variant="secondary">All Dashboards</WppActionButton>
      </Link>

      <Flex className={styles.imageWrapper}>
        <img src={mediaEffectivenessImgSrc} className={styles.image} alt="" />
      </Flex>
    </WidgetCard>
  )
}
