import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import introImgSrc from 'pages/home/components/pitch/cheeriosDashboard/components/introWidget/assets/intro.png'
import styles from 'pages/home/components/pitch/cheeriosDashboard/components/introWidget/IntroWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const IntroWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex className={styles.cardContent} justify="between">
      <div className={styles.textContentWrapper}>
        <WppTypography type="2xl-heading" className={styles.title}>
          Simple Good Food
        </WppTypography>
        <WppTypography type="s-body">
          This customized platform serves as a dedicated space where we unite our employees, workflows, and
          technologies, fostering a stronger sense of collaboration, efficiency, and enjoyment in our partnership. Here,
          we provide a central hub for onboarding our teams, facilitating seamless process navigation, and sharing
          valuable insights to drive ongoing enhancements.
        </WppTypography>
      </div>
      <img src={introImgSrc} className={styles.introImg} alt="" />
    </Flex>
  </WidgetCard>
)
