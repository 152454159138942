import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/demoDashboard/components/welcomeWidget/WelcomeWidget.module.scss'

export const WelcomeWidget = () => {
  return (
    <WppCard className={styles.container}>
      <Flex direction="column" className={styles.note} gap={72}>
        <Flex direction="column" gap={24}>
          <WppTypography type="2xl-heading">Welcome to WPP Open Demo Site!</WppTypography>
          <WppTypography type="m-body">
            Warning: this is a Demo Site, here you can test drive Open and explore its capabilities.
          </WppTypography>
        </Flex>
        <Flex direction="column" gap={26}>
          <WppTypography type="m-body">Click here to start your Demo</WppTypography>
          <Flex justify="between">
            <WppButton className={styles.button} variant="secondary">
              Open Demo as client tenant
            </WppButton>
            <WppButton className={styles.button} variant="secondary">
              Open as Wpp Open tenant
            </WppButton>
          </Flex>
        </Flex>
      </Flex>
    </WppCard>
  )
}
