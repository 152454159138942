import { Link } from 'react-router-dom'

import { ProjectsWidgetCard } from 'pages/home/systemWidgets/projectsWidget/projectsWidgetCard/ProjectsWidgetCard'
import { ProjectsWidgetEmptyState } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetEmptyState'
import {
  ProjectsWidgetList,
  LoadingProjectsWidgetList,
} from 'pages/home/systemWidgets/projectsWidget/projectsWidgetList/ProjectsWidgetList'
import {
  resolveProjectsWidgetConfig,
  useWidgetProjects,
  handleTrackAddProjectRedirect,
} from 'pages/home/systemWidgets/projectsWidget/utils'
import { isCompactEmptyOrErrorState } from 'pages/home/utils'
import { ViewAllButton } from 'pages/home/ViewAllButton'
import { WidgetTitle } from 'pages/home/WidgetTitle'
import { ProjectsWidgetDTO } from 'types/widgets/widget'
import { routesManager } from 'utils/routesManager'

interface Props {
  widget: ProjectsWidgetDTO
}

export const ProjectsWidgetView = ({ widget }: Props) => {
  const { title, width, height } = widget
  const { isHierarchyEnabled, detailsType } = resolveProjectsWidgetConfig(widget)

  const {
    isLoading,
    isError,
    data: projects,
  } = useWidgetProjects({
    isHierarchyEnabled,
  })

  return (
    <ProjectsWidgetCard isHierarchyEnabled={isHierarchyEnabled} height={height}>
      <WidgetTitle>{title}</WidgetTitle>

      <>
        {!isError && (
          <Link
            slot="actions"
            to={routesManager.systemApps.orchestration.projects()}
            onClick={handleTrackAddProjectRedirect}
          >
            <ViewAllButton />
          </Link>
        )}
      </>

      {isLoading ? (
        <LoadingProjectsWidgetList height={height} isHierarchyEnabled={isHierarchyEnabled} />
      ) : (
        <>
          {!projects.length ? (
            <ProjectsWidgetEmptyState isError={isError} isCompact={isCompactEmptyOrErrorState({ width, height })} />
          ) : (
            <ProjectsWidgetList
              projects={projects}
              isHierarchyEnabled={isHierarchyEnabled}
              detailsType={detailsType}
              height={height}
            />
          )}
        </>
      )}
    </ProjectsWidgetCard>
  )
}
