import { WppTypography } from '@platform-ui-kit/components-library-react'

import footerImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/processWidget/assets/footer.svg'
import processImgSrc from 'pages/home/components/pitch/volkswagenJapanDashboard/components/processWidget/assets/process-img.png'
import styles from 'pages/home/components/pitch/volkswagenJapanDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => (
  <WidgetCard className={styles.card}>
    <div slot="header">
      <WppTypography type="m-strong" className={styles.headerTitle}>
        Planning Framework
      </WppTypography>
    </div>
    <div className={styles.contentContainer}>
      <img src={processImgSrc} className={styles.processImage} alt="" />
      <img src={footerImgSrc} className={styles.footerImage} alt="" />
    </div>
  </WidgetCard>
)
