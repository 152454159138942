import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import globalDataImgSrc from 'assets/pitch/pfizer/map.png'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/globalDataWidget/GlobalDataWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const GlobalDataWidget = () => {
  return (
    <Link to="https://pfizer.wppopen.com/audience-engine-dashboard">
      <WidgetCard className={styles.card}>
        <WppTypography slot="header" type="m-strong">
          Global data framework
        </WppTypography>
        <img src={globalDataImgSrc} className={styles.image} alt="" />
      </WidgetCard>
    </Link>
  )
}
