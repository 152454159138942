import { WppDivider, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { formatDistanceStrict, isBefore } from 'date-fns'
import { ComponentProps, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { specialCharacters } from 'constants/specialCharacters'
import styles from 'pages/home/systemWidgets/projectsWidget/projectsWidgetList/ProjectsWidgetList.module.scss'
import { ProjectTypeTag } from 'pages/home/systemWidgets/projectsWidget/projectTypeTag/ProjectTypeTag'
import { getProjectsWidgetItemsFitCount } from 'pages/home/systemWidgets/projectsWidget/utils'
import { WidgetListItem, LoadingWidgetListItem } from 'pages/home/widgetListItem/WidgetListItem'
import { WidgetProject } from 'types/projects/widgetProject'
import { ProjectsWidgetDetailsType } from 'types/widgets/widget'
import { join, emptyArray } from 'utils/common'
import { routesManager } from 'utils/routesManager'

interface Props {
  projects: WidgetProject[]
  readOnly?: boolean
  height: number
  isHierarchyEnabled: boolean
  detailsType: ProjectsWidgetDetailsType
}

export const ProjectsWidgetList = ({ readOnly = false, isHierarchyEnabled, detailsType, projects, height }: Props) => {
  const navigate = useNavigate()

  return (
    <Flex className={styles.root} direction="column" gap={8} data-testid="projects-list">
      {projects.map(({ id, name, hierarchy, type, endDate }, index) => {
        const href = routesManager.systemApps.orchestration.project(id)

        const linkConfig: ComponentProps<typeof WidgetListItem>['linkConfig'] = {
          href,
          onClick: e => {
            e.preventDefault()
            navigate(href)
          },
        }

        const levelNames = hierarchy?.map(({ name }) => name)

        return (
          <Fragment key={id}>
            <WidgetListItem
              className={clsx(styles.listItem, { [styles.listItemSmall]: !isHierarchyEnabled && height === 1 })}
              {...(!readOnly && { linkConfig })}
            >
              <span slot="label">{name}</span>

              {isHierarchyEnabled && !!levelNames?.length && (
                <Flex
                  slot="caption"
                  as="span"
                  align="center"
                  gap={4}
                  title={join(levelNames, ` ${specialCharacters.bullet} `)}
                >
                  {levelNames.map((name, index) => (
                    <Fragment key={name}>
                      <span className={styles.level}>{name}</span>
                      {index < levelNames.length - 1 && <span className={styles.dot}>&bull;</span>}
                    </Fragment>
                  ))}
                </Flex>
              )}

              {detailsType !== ProjectsWidgetDetailsType.None && (
                <>
                  {detailsType === ProjectsWidgetDetailsType.ProjectType && <ProjectTypeTag slot="right" type={type} />}
                  {detailsType === ProjectsWidgetDetailsType.DaysToGo && <DaysToGoDetails endDate={endDate} />}
                </>
              )}
            </WidgetListItem>

            {index < projects.length - 1 && <WppDivider />}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export const LoadingProjectsWidgetList = ({
  height,
  isHierarchyEnabled,
}: {
  height: number
  isHierarchyEnabled: boolean
}) => {
  const itemsFitCount = getProjectsWidgetItemsFitCount({ isHierarchyEnabled, height })

  return (
    <Flex className={styles.root} direction="column" gap={8} data-testid="loading-projects-list">
      {emptyArray(itemsFitCount).map((_, index, arr) => (
        <Fragment key={index}>
          <LoadingWidgetListItem
            className={clsx(styles.listItem, styles.loadingListItem, {
              [styles.listItemSmall]: !isHierarchyEnabled && height === 1,
            })}
          >
            <WppSkeleton width={!(index % 2) ? '42%' : '60%'} height={12} slot="label" />
            {isHierarchyEnabled && <WppSkeleton width={!(index % 2) ? '31%' : '23%'} height={9} slot="caption" />}
          </LoadingWidgetListItem>

          {index < arr.length - 1 && <WppDivider />}
        </Fragment>
      ))}
    </Flex>
  )
}

function DaysToGoDetails({ endDate }: { endDate: MayBeNull<string> }) {
  const { t } = useTranslation()

  const today = new Date()

  if (!endDate || isBefore(new Date(endDate), today)) {
    return null
  }

  return (
    <WppTypography type="xs-body" slot="right" data-testid="days-to-go-value">
      {t('os.home.projects_widget.days_to_go_details_text', {
        days: formatDistanceStrict(today, new Date(endDate), {
          unit: 'day',
          roundingMethod: 'ceil',
        }),
      })}
    </WppTypography>
  )
}
