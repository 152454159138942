import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import graph from 'pages/home/components/pitch/playgroundDashboard/assets/images/project-graph.png'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/projectHealthCard/ProjectHealthCard.module.scss'
import { ProjectDto } from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjectHealth/sectionProjectHealthMockData'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  project: ProjectDto
}

export const ProjectHealthCard = ({ project }: Props) => {
  return (
    <Flex className={clsx(styles.flexBaseWidth)} align="center" gap={1}>
      <div className={clsx(stylesMain['w-2/5'], styles.containerLeft)}>
        <div className={styles.title}>{project.name}</div>
        <Flex className={stylesMain.mt5} align="center" gap={8}>
          <div className={styles.caption}>View Project Details</div>
          <WppIconArrow direction="right" color="white" />
        </Flex>

        <img className={stylesMain.mt30} src={graph} alt="graph image" />
      </div>
      <div className={stylesMain['w-3/5']}>
        <Flex align="center" gap={1}>
          <div className={clsx(stylesMain['w-1/2'], styles.containerMiddle)}>
            <Flex className={styles.hRow} align="center" gap={16}>
              <div className={styles.textBlue}>{`${project.percentComplete}%`}</div>
              <div className={styles.title}>Complete</div>
            </Flex>
          </div>
          <div className={clsx(stylesMain['w-1/2'], styles.containerRightTop)}>
            <Flex className={styles.hRow} align="center" gap={16}>
              <div className={styles.textGreen}>{project.projectHealth.onTrack}</div>
              <div className={styles.title}>On Track</div>
            </Flex>
          </div>
        </Flex>
        <Flex className={stylesMain.mt1} align="center" gap={1}>
          <div className={clsx(stylesMain['w-1/2'], styles.containerMiddle)}>
            <Flex className={styles.hRow} align="center" gap={16}>
              <div className={styles.textOrange}>{project.projectHealth.tasksDelayed}</div>
              <div className={styles.title}>Tasks Delayed</div>
            </Flex>
          </div>
          <div className={clsx(stylesMain['w-1/2'], styles.containerRightBottom)}>
            <Flex className={styles.hRow} align="center" gap={16}>
              <div className={styles.textRed}>{project.projectHealth.riskIdentified}</div>
              <div className={styles.title}>Risk identified</div>
            </Flex>
          </div>
        </Flex>
      </div>
    </Flex>
  )
}
