import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import processImgSrc from 'pages/home/components/pitch/kentDashboard/components/processWidget/assets/image.png'
import styles from 'pages/home/components/pitch/kentDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => (
  <WidgetCard>
    <div slot="header">
      <WppTypography type="m-strong">KENT Inspired by Process</WppTypography>
    </div>
    <Flex justify="between" align="center" className={styles.kentProcessCardContent}>
      <img src={processImgSrc} className={styles.kentProcessImage} alt="" />
    </Flex>
  </WidgetCard>
)
