import { ChildHierarchyLevelType, HierarchyLevel, Tenant, UserDetails } from '@wpp-open/core'
import { createContext, useContext } from 'react'

import { TenantPublicShort, TenantShort } from 'types/tenants/tenant'

export interface TenantAndUserDataContextValue {
  currentTenant: Tenant
  navigationHierarchy: HierarchyLevel<ChildHierarchyLevelType>[]
  availableTenants: TenantShort[]
  requestableTenants: TenantPublicShort[]
  userDetails: UserDetails
}

export const TenantAndUserDataContext = createContext<TenantAndUserDataContextValue>(null!)

export const useTenantAndUserData = () => useContext(TenantAndUserDataContext)
