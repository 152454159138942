import clsx from 'clsx'
import { ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/general/card/Card.module.scss'

interface Props {
  number: number
  title: string
  caption: string
  classOuter: string
  children?: ReactNode
}

export const Card = ({ number, title, caption, classOuter, children }: Props) => {
  return (
    <div className={clsx(styles.container, classOuter)}>
      <Flex className={styles.flexBaseWidth} align="start" gap={16}>
        <div className={styles.preTitle}>{number}</div>
        <div>
          <div className={styles.title}>{title}</div>
          <u>{caption}</u>
        </div>
      </Flex>
      <Flex className={styles.flexChildren} align="start" gap={8}>
        {children}
      </Flex>
    </div>
  )
}
