import { BrandWidget } from 'pages/home/components/pitch/kentDashboard/components/brandWidget/BrandWidget'
import { InnovationWidget } from 'pages/home/components/pitch/kentDashboard/components/innovationWidget/InnovationWidget'
import { PostsWidget } from 'pages/home/components/pitch/kentDashboard/components/postsWidget/PostsWidget'
import { ProcessWidget } from 'pages/home/components/pitch/kentDashboard/components/processWidget/ProcessWidget'
import { UserWidget } from 'pages/home/components/pitch/kentDashboard/components/userWidget/UserWidget'
import styles from 'pages/home/components/pitch/kentDashboard/KentDashboard.module.scss'
import { kentWidgets } from 'pages/home/components/pitch/kentDashboard/widgetsMock'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { KentWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | KentWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case KentWidgetType.BRAND:
      return <BrandWidget />
    case KentWidgetType.INNOVATION:
      return <InnovationWidget />
    case KentWidgetType.PROCESS:
      return <ProcessWidget />
    case KentWidgetType.USER:
      return <UserWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case KentWidgetType.POSTS:
      return <PostsWidget />
    default:
      return null
  }
}

export const KentDashboard = () => (
  <div className={styles.container}>
    {kentWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
