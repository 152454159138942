import { faker } from '@faker-js/faker'
import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import * as echarts from 'echarts'
import { useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectTrackingWidget/riskAssessmentTrack/RiskAssessmentTrack.module.scss'

interface RisksProps {
  total?: number
  deadline?: number
  assign?: number
  onTrack?: number
  isLoading: boolean
}
type EChartsOption = echarts.EChartsOption

export const RiskAssessmentTrack = ({
  total = 0,
  deadline = 0,
  assign = 0,
  onTrack = 0,
  isLoading = true,
}: RisksProps) => {
  const elId = faker.database.mongodbObjectId()
  const chartDom = document.getElementById(elId)!

  useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        const el = document.getElementById(elId)!
        const myChart = el && echarts.init(el)
        const option: EChartsOption = {
          color: ['#f87171', '#eab308', '#84cc16'],
          series: [
            {
              type: 'pie',
              radius: ['65%', '95%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 40,
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: deadline, name: 'No deadline' },
                { value: assign, name: 'No assignee' },
                { value: onTrack, name: 'On track' },
              ],
            },
          ],
        }
        option && el && myChart.setOption(option)
        clearTimeout(delay)
      }, 1000)
    }
  }, [elId, chartDom, assign, deadline, onTrack, isLoading])
  return (
    <WppCard className={styles.container}>
      <Flex direction="column" gap={15} className={styles.content}>
        <WppTypography type="s-strong">Risk Assessment</WppTypography>
        <Flex direction="column" gap={6} className={isLoading ? styles.hide : undefined}>
          <Flex justify="center">
            <div id={elId} className={styles.chart} />
            <Flex align="center" justify="center" direction="column" className={styles.legend}>
              <WppTypography type="2xl-heading">{((onTrack / total) * 100).toFixed(0) ?? 0}%</WppTypography>
              <WppTypography type="s-body">On track</WppTypography>
            </Flex>
          </Flex>
          <Flex gap={8}>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.deadline)} />
                <WppTypography type="s-midi" className={styles.description}>
                  No deadline
                </WppTypography>
                <WppTypography type="l-strong">
                  {deadline}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.assign)} />
                <WppTypography type="s-midi" className={styles.description}>
                  No assignee
                </WppTypography>
                <WppTypography type="l-strong">
                  {assign}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
            <WppCard className={styles.infoCard}>
              <Flex align="center" direction="column">
                <div className={clsx(styles.dot, styles.onTrack)} />
                <WppTypography type="s-midi" className={styles.description}>
                  On track
                </WppTypography>
                <WppTypography type="l-strong">
                  {onTrack}/{total}
                </WppTypography>
              </Flex>
            </WppCard>
          </Flex>
        </Flex>
        {isLoading && (
          <Flex align="center" justify="center" className={styles.loading}>
            <WppSpinner size="l" />
          </Flex>
        )}
      </Flex>
    </WppCard>
  )
}
