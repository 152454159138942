import { faker } from '@faker-js/faker'
import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import * as echarts from 'echarts'
import { useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/systemWidgets/projectTrackingWidget/taskProgressTrack/TaskProgressTrack.module.scss'

interface TaskProps {
  completed?: number
  toDo?: number
  inProgress?: number
  archived?: number
  isLoading: boolean
}
type EChartsOption = echarts.EChartsOption
export const TaskProgressTrack = ({
  completed = 0,
  toDo = 0,
  inProgress = 0,
  archived = 0,
  isLoading = true,
}: TaskProps) => {
  const elId = faker.database.mongodbObjectId()
  const chartDom = document.getElementById(elId)!

  useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        const el = document.getElementById(elId)!
        const myChart = el && echarts.init(el)
        const option: EChartsOption = {
          color: ['#f87171', '#eab308', '#84cc16'],
          legend: {},
          tooltip: {},
          xAxis: {
            type: 'category',
            data: ['Done', 'In progress', 'To do', 'Archived'],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: [
                { value: completed, itemStyle: { color: '#6295E4' } },
                { value: inProgress, itemStyle: { color: '#A884DF' } },
                { value: toDo, itemStyle: { color: '#E971A5' } },
                { value: archived, itemStyle: { color: '#F87171' } },
              ],
              type: 'bar',
            },
          ],
        }
        option && el && myChart.setOption(option)
        clearTimeout(delay)
      }, 1000)
    }
  }, [elId, chartDom, completed, inProgress, toDo, archived, isLoading])
  return (
    <WppCard className={styles.container}>
      <WppTypography type="s-strong">Tasks Progress Report</WppTypography>
      <Flex align="center" justify="center" className={styles.loading}>
        {isLoading ? <WppSpinner size="l" /> : <div id={elId} className={styles.chart} />}
      </Flex>
    </WppCard>
  )
}
