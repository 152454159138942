import { workfrontApi } from 'api'

export const patchWorkfrontTask = ({ token, taskId, status }: { token: string; taskId: string; status: string }) =>
  workfrontApi.put(
    `/tasks/${taskId}`,
    {
      status: status,
    },
    {
      headers: {
        'X-Workfront-Authorization': `Bearer ${token}`,
      },
    },
  )
