import { AnalyticsActionType } from '@wpp-open/core'
import { Link } from 'react-router-dom'

import { NewsWidgetCardType } from 'pages/home/systemWidgets/newsWidget/constants'
import { SnippetNewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/snippetNewsPieceCard/SnippetNewsPieceCard'
import { StandardNewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/standardNewsPieceCard/StandardNewsPieceCard'
import { TitleBelowNewsPieceCard } from 'pages/home/systemWidgets/newsWidget/newsPieceCard/titleBelowNewsPieceCard/TitleBelowNewsPieceCard'
import { NewsPiece } from 'types/news'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { routesManager } from 'utils/routesManager'

interface Props {
  newsPiece: NewsPiece
  cardType: NewsWidgetCardType
  readOnly?: boolean
}

export const NewsPieceCard = ({ newsPiece, cardType, readOnly = false }: Props) => {
  const { open_inside_os: openInsideOs, post_url: postUrl, guid } = newsPiece

  const newsPieceCard = (
    <>
      {cardType === NewsWidgetCardType.SNIPPET && <SnippetNewsPieceCard newsPiece={newsPiece} />}
      {cardType === NewsWidgetCardType.STANDARD && <StandardNewsPieceCard newsPiece={newsPiece} />}
      {cardType === NewsWidgetCardType.TITLE_BELOW && <TitleBelowNewsPieceCard newsPiece={newsPiece} />}
    </>
  )

  const handleTrackNewsPieceClick = () =>
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: EVENTS.HOME_PAGE.ACTIONS.HOME_NEWS_WIDGET_ITEM_CLICK,
    })

  if (readOnly || !postUrl || !guid) {
    return newsPieceCard
  }

  if (openInsideOs) {
    return (
      <Link to={routesManager.systemApps.news.article(guid)} onClick={handleTrackNewsPieceClick}>
        {newsPieceCard}
      </Link>
    )
  }

  return (
    <a href={postUrl} target="_blank" rel="noreferrer" onClick={handleTrackNewsPieceClick}>
      {newsPieceCard}
    </a>
  )
}
