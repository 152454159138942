import {
  WppCard,
  WppTooltip,
  WppTypography,
  WppActionButton,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { format, isSameDay } from 'date-fns'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import styles from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget.module.scss'
import { dateLocale } from 'utils/date'

const locale = 'en-GB'

const getMonth = (date: Date) => new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)
const getWeekDay = (date: Date) => new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(date)
const getDay = (date: Date) => new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(date)
const getTime = (date: Date, timeZone: string) =>
  new Intl.DateTimeFormat(locale, { timeStyle: 'short', timeZone }).format(date)

interface Props {
  dateFormat?: 'M/d/yyyy' | 'd/M/yyyy'
  events: { getDate: () => Date; getText: () => string }[]
  clockOptions: { timeZone: string; city: string }[]
}

export const CalendarWidget = ({ dateFormat = 'M/d/yyyy', events, clockOptions }: Props) => {
  const [date, setDate] = useState(() => new Date())

  useEffect(() => {
    const secondsLeft = 60 - date.getSeconds()

    const timeout = window.setTimeout(() => {
      setDate(new Date())
    }, secondsLeft * 1000)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [date])

  const getDate = (date: Date) => format(date, dateFormat)

  return (
    <WppCard size="m" className={clsx(styles.pitchWidget, styles.widget)}>
      <div className={styles.header}>
        <div className={styles.info}>
          <WppTypography type="l-body">{getMonth(date)}</WppTypography>
          <WppTypography className={styles.weekday} type="l-strong">
            {getWeekDay(date)}
          </WppTypography>
          <WppTypography type="4xl-display">{getDay(date)}</WppTypography>
        </div>
        <div className={styles.times}>
          {clockOptions.map(({ timeZone, city }) => (
            <Time key={city} time={getTime(date, timeZone)} city={city} />
          ))}
        </div>
      </div>

      <div className={styles.datePicker}>
        <DatePicker
          inline
          disabled
          showPopperArrow={false}
          disabledKeyboardNavigation
          calendarClassName={styles.calendar}
          onChange={() => setDate(date)}
          locale={dateLocale}
          selected={date}
          previousMonthButtonLabel={
            <WppActionButton>
              <WppIconChevron direction="left" />
            </WppActionButton>
          }
          nextMonthButtonLabel={
            <WppActionButton>
              <WppIconChevron />
            </WppActionButton>
          }
          highlightDates={[
            {
              'pitch-event': Object.values(events).map(({ getDate }) => getDate()),
            },
          ]}
          renderDayContents={(day, date) => {
            const event = events.find(({ getDate }) => isSameDay(date!, getDate()))

            if (event) {
              return (
                <WppTooltip className={styles.tooltip} text={`${getDate(date!)}\n${event.getText()}`}>
                  <span>{day}</span>
                </WppTooltip>
              )
            }

            return day
          }}
        />
      </div>
    </WppCard>
  )
}

function Time({ time, city }: { time: string; city: string }) {
  return (
    <div className={styles.time}>
      <WppTypography type="4xl-display">{time}</WppTypography>
      <WppTypography type="s-body">{city}</WppTypography>
    </div>
  )
}
