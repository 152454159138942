import { AnalyticsActionType } from '@wpp-open/core'

import { useWidgetProjectsApi } from 'api/widgets/queries/useWidgetProjectsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectsWidgetData } from 'pages/home/types'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectsWidgetDTO, ProjectsWidgetConfig, ProjectsWidgetDetailsType } from 'types/widgets/widget'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

const PROJECTS_MAX_COUNT = 20

export const useWidgetProjects = ({ isHierarchyEnabled }: { isHierarchyEnabled: boolean }) =>
  useWidgetProjectsApi({
    params: {
      includeHierarchy: isHierarchyEnabled,
      itemsPerPage: PROJECTS_MAX_COUNT,
    },
  })

export const projectsWidgetSizes = [
  { width: 8, height: 2 },
  { width: 8, height: 1 },
]

export const getProjectsWidgetItemsFitCount = ({
  isHierarchyEnabled,
  height,
}: {
  isHierarchyEnabled: boolean
  height: number
}) => (height === 1 ? (isHierarchyEnabled ? 2 : 3) : 5)

export const defaultProjectsWidgetConfig = {
  isHierarchyEnabled: true,
  detailsType: ProjectsWidgetDetailsType.None,
} satisfies ProjectsWidgetConfig

export const resolveProjectsWidgetConfig = ({
  config,
}: ProjectsWidgetDTO | ProjectsWidgetData): ProjectsWidgetConfig => ({
  ...defaultProjectsWidgetConfig,
  ...config,
})

export const handleTrackAddProjectRedirect = () => {
  trackAnalytics({
    type: AnalyticsActionType.action,
    payload: EVENTS.HOME_PAGE.ACTIONS.HOME_PROJECTS_WIDGET_ADD_PROJECT,
  })
}

export const reloadWidgetProjects = () => queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WIDGET_PROJECTS] })
