import { MayBeNull } from '@wpp-open/core'

import { PageBuilderContent } from 'types/apps/leanApps'
import { PageName } from 'types/pages'
import {
  WidgetTemplate,
  NewsWidgetTemplate,
  RecentClientsWidgetTemplate,
  ProjectsWidgetTemplate,
  ProjectsTemplatesWidgetTemplate,
  ProjectTrackingWidgetTemplate,
  ExpertHubWidgetTemplate,
  CalendarWidgetTemplate,
  FavouriteLinksWidgetTemplate,
  CustomWidgetTemplate,
} from 'types/widgets/widgetTemplate'

type TemplateToWidgetDTO<T extends WidgetTemplate, C = null> = T & {
  x: number
  y: number
  isLocked: boolean
  config: MayBeNull<C>
}

export type RecentClientsWidgetDTO = TemplateToWidgetDTO<RecentClientsWidgetTemplate>
export type ExpertHubWidgetDTO = TemplateToWidgetDTO<ExpertHubWidgetTemplate>
export type FavouriteLinksWidgetDTO = TemplateToWidgetDTO<FavouriteLinksWidgetTemplate>
export type ProjectsTemplatesWidgetDTO = TemplateToWidgetDTO<ProjectsTemplatesWidgetTemplate>
export type ProjectsTrackingWidgetDTO = TemplateToWidgetDTO<ProjectTrackingWidgetTemplate>
export type CustomWidgetDTO = TemplateToWidgetDTO<CustomWidgetTemplate>

/* News widget */
export enum NewsWidgetCardType {
  STANDARD = 'STANDARD',
  TITLE_BELOW = 'TITLE_BELOW',
  SNIPPET = 'SNIPPET',
}

export interface NewsWidgetConfig {
  cardType: NewsWidgetCardType
  sourceId: string[]
  isFilteredByCountry: boolean
  isFilteredByPrimaryKey: boolean
}

export type NewsWidgetDTO = TemplateToWidgetDTO<NewsWidgetTemplate, NewsWidgetConfig>

/* Projects widget */
export enum ProjectsWidgetDetailsType {
  None = 'NONE',
  ProjectType = 'PROJECT_TYPE',
  DaysToGo = 'DAYS_TO_GO',
}

export interface ProjectsWidgetConfig {
  isHierarchyEnabled: boolean
  detailsType: ProjectsWidgetDetailsType
}

export type ProjectsWidgetDTO = TemplateToWidgetDTO<ProjectsWidgetTemplate, ProjectsWidgetConfig>

/* Calendar widget */
export interface CalendarWidgetEventDTO {
  id?: string
  title: string
  date: string
  description: string
  endTime?: string
  startTime?: string
}

export interface CalendarWidgetConfig {
  isTimeEnabled: boolean
  is24HourTime: boolean
  headquartersInfo: MayBeNull<{
    enabled: boolean
    location: string
    label: string
  }>
}

export type CalendarWidgetDTO = TemplateToWidgetDTO<CalendarWidgetTemplate, CalendarWidgetConfig>

export type SystemWidgetDTO =
  | RecentClientsWidgetDTO
  | ExpertHubWidgetDTO
  | FavouriteLinksWidgetDTO
  | ProjectsTemplatesWidgetDTO
  | ProjectsTrackingWidgetDTO
  | NewsWidgetDTO
  | ProjectsWidgetDTO
  | CalendarWidgetDTO

export type WidgetDTO = SystemWidgetDTO | CustomWidgetDTO

type UpsertWidgetBase = Pick<
  WidgetDTO,
  'title' | 'description' | 'width' | 'height' | 'x' | 'y' | 'isLocked' | 'config'
>

export interface CreateWidgetDTO extends UpsertWidgetBase {
  pageName: PageName
  widgetTemplateId: string
}

export interface EditWidgetDTO extends UpsertWidgetBase {
  id: string
  // Should be provided only for existing custom widgets with edited content
  content?: PageBuilderContent
}

export interface BulkWidgetsUpdatePayload {
  create: CreateWidgetDTO[]
  edit: EditWidgetDTO[]
  delete: string[]
}

export enum AllwynWidgetType {
  CALENDAR = 'CALENDAR',
}

export enum EasyJetWidgetType {
  AIR_FLOW = 'AIR_FLOW',
  TERMINAL = 'TERMINAL',
}

export enum CentralWidgetType {
  FAQ = 'FAQ',
  EXPLORE_PROCESS = 'EXPLORE_PROCESS',
  WELCOME = 'WELCOME',
  PROJECT = 'PROJECT',
  ABOUT_PROJECT = 'ABOUT_PROJECT',
}

export enum DemoWidgetType {
  WELCOME = 'WELCOME',
  CLIENT_TENANT = 'CLIENT_TENANT',
  WPP_TENANT = 'WPP_TENANT',
}

export enum PawsAndTailsWidgetType {
  INTRO = 'INTRO',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  MILESTONES_AND_TIMELINES = 'MILESTONES_AND_TIMELINES',
  EXCELLENCE_HUB = 'EXCELLENCE_HUB',
}

export enum PfizerWidgetType {
  VIDEO = 'VIDEO',
  DASHBOARDS = 'DASHBOARDS',
  PROCESS = 'PROCESS',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  OS_ASSISTANT = 'OS_ASSISTANT',
  UPCOMING_DATES = 'UPCOMING_DATES',
  GLOBAL_DATA = 'GLOBAL_DATA',
  TEAM = 'TEAM',
}

export enum NestleWidgetType {
  IMAGE = 'IMAGE',
  WELCOME = 'WELCOME',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  WRIKE = 'WRIKE',
  TEAM = 'TEAM',
  CENTRE_OF_EXCELLENCE = 'CENTRE_OF_EXCELLENCE',
}

export enum GeneralMillsWidgetType {
  HERO_BANNER = 'HERO_BANNER',
  INTRO = 'INTRO',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  MILESTONES_AND_TIMELINES = 'MILESTONES_AND_TIMELINES',
}

export enum OldElPasoWidgetType {
  HERO_BANNER = 'HERO_BANNER',
  INTRO = 'INTRO',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  EXCELLENCE_HUB = 'EXCELLENCE_HUB',
  MILESTONES_AND_TIMELINES = 'MILESTONES_AND_TIMELINES',
}

export enum CheeriosWidgetType {
  HERO_BANNER = 'HERO_BANNER',
  INTRO = 'INTRO',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  EXCELLENCE_HUB = 'EXCELLENCE_HUB',
  MILESTONES_AND_TIMELINES = 'MILESTONES_AND_TIMELINES',
}

export enum VolkswagenWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
  WRIKE = 'WRIKE',
}

export enum VolkswagenChinaWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
  WRIKE = 'WRIKE',
}

export enum VolkswagenJapanWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
  WRIKE = 'WRIKE',
}

export enum OrangeWidgetType {
  BRAND = 'BRAND',
  PROCESS = 'PROCESS',
  USER = 'USER',
  MILESTONES = 'MILESTONES',
  BAV = 'BAV',
  POSTS = 'POSTS',
  SOCIAL_ACTIVITY = 'SOCIAL_ACTIVITY',
}

export enum BeiersdorfWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum AxaWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum AldiWidgetType {
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum UberWidgetType {
  BRAND = 'BRAND',
  CALENDAR = 'CALENDAR',
  PROCESS = 'PROCESS',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum PerrigoWidgetType {
  BRAND = 'BRAND',
  CONTROL = 'CONTROL',
  CAPABILITIES = 'CAPABILITIES',
  COLLABORATION = 'COLLABORATION',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum KentWidgetType {
  BRAND = 'BRAND',
  INNOVATION = 'INNOVATION',
  PROCESS = 'PROCESS',
  USER = 'USER',
  POSTS = 'POSTS',
}

export enum HaleonWidgetType {
  WELCOME = 'WELCOME',
  APPS = 'APPS',
  USER = 'USER',
  TEAMS = 'TEAMS',
}

export enum IllyWidgetType {
  PROCESS = 'PROCESS',
  INTRO = 'INTRO',
  USER = 'USER',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  EXCELLENCE_HUB = 'EXCELLENCE_HUB',
  MAP_DASHBOARD = 'MAP_DASHBOARD',
}

export enum SpotifyWidgetType {
  PROCESS = 'PROCESS',
  REVOLUTION = 'REVOLUTION',
  USER = 'USER',
  CULTURAL_DIGEST = 'CULTURAL_DIGEST',
  METRICS = 'METRICS',
  TOP_CREATIVES = 'TOP_CREATIVES',
}

export enum ValueRetailWidgetType {
  PROCESS = 'PROCESS',
  INTRO = 'INTRO',
  USER = 'USER',
  MEDIA_EFFECTIVENESS = 'MEDIA_EFFECTIVENESS',
  EXCELLENCE_HUB = 'EXCELLENCE_HUB',
  TEAM = 'TEAM',
  MAP_DASHBOARD = 'MAP_DASHBOARD',
}

export enum WidgetType {
  PREDEFINED = 'PREDEFINED',
}

export enum SystemWidget {
  NEWS = 'NEWS',
  FAVOURITE_LINKS = 'FAVOURITE_LINKS',
  RECENT_WORK = 'RECENT_WORK',
  RECENT_CLIENTS = 'RECENT_CLIENTS',
  PROJECTS = 'PROJECTS',
  PROJECT_TEMPLATES = 'PROJECT_TEMPLATES',
  PROJECT_TRACKING = 'PROJECT_TRACKING',
}

export interface Widget {
  id: string
  type: WidgetType
  enabled: boolean
  pageId: string
  gridColumnStart: number
  gridColumnEnd: number
  gridRowStart: number
  gridRowEnd: number
  createdAt: string
  updatedAt: string
  leanAppId?: string
  name?: SystemWidget
  title: string
}
