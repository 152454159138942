import BelleCartwrightImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/belle-cartwright.png'
import DavidBealeImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/David_Beale.png'
import FabianKeitzmannImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/fabian-keitzmann.png'
import JonasZinnöckerImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/jonas-zinnöcker.jpeg'
import LouiseGoodbournImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/Louise_Goodbourn.png'
import MebrulinFranciscoImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/Mebrulin_Francisco.png'
import NicolasAdamsImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/Nicolas_Adams.png'
import RichardMooneyImgSrc from 'pages/home/components/pitch/volkswagenDashboard/components/teamWidget/assets/richard-mooney.png'

export interface TeamMember {
  name: string
  role: string
  profileImgSrc: string
}

export const team: TeamMember[] = [
  {
    name: 'Fabian Kietzmann',
    role: 'Global Account Lead',
    profileImgSrc: FabianKeitzmannImgSrc,
  },
  {
    name: 'Richard Mooney',
    role: 'Chief Data and Tech Officer',
    profileImgSrc: RichardMooneyImgSrc,
  },
  {
    name: 'Belle Cartwright',
    role: 'Global Tech Acceleration Lead',
    profileImgSrc: BelleCartwrightImgSrc,
  },
  {
    name: 'Mebrulin Francisco',
    role: 'Global Data Strat & Mar Tech Lead',
    profileImgSrc: MebrulinFranciscoImgSrc,
  },
  {
    name: 'David Beale',
    role: 'Chief Data Officer',
    profileImgSrc: DavidBealeImgSrc,
  },
  {
    name: 'Louise Goodbourn',
    role: 'Analytics Director',
    profileImgSrc: LouiseGoodbournImgSrc,
  },
  {
    name: 'Jonas Zinnöcker',
    role: 'Data Strategy Director',
    profileImgSrc: JonasZinnöckerImgSrc,
  },
  {
    name: 'Nicolas Adams',
    role: 'VP, Data Strategy',
    profileImgSrc: NicolasAdamsImgSrc,
  },
]
