import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import processImgSrc from 'pages/home/components/pitch/axaDashboard/components/processWidget/assets/process-img.svg'
import styles from 'pages/home/components/pitch/axaDashboard/components/processWidget/ProcessWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const ProcessWidget = () => (
  <Link to="/process/custom">
    <WidgetCard className={styles.card}>
      <div slot="header">
        <WppTypography type="m-strong" className={styles.headerTitle}>
          Breakthrough process
        </WppTypography>
      </div>
      <img src={processImgSrc} className={styles.processImage} alt="" />
    </WidgetCard>
  </Link>
)
