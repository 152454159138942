import styles from 'pages/home/components/pitch/centralDashboard/CentralDashboard.module.scss'
import { centralWidgets } from 'pages/home/components/pitch/centralDashboard/widgetsMock'
import { AboutProjectWidget } from 'pages/home/components/pitch/pitch/aboutProjectWidget/AboutProjectWidget'
import { ExploreProcessWidget } from 'pages/home/components/pitch/pitch/exploreProcessWidget/ExploreProcessWidget'
import { WelcomeWidget } from 'pages/home/components/pitch/pitch/welcomeWidget/WelcomeWidget'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CentralWidgetType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({
  widgetType,
  pageWidget,
}: {
  widgetType: SystemWidget | CentralWidgetType
  pageWidget: any
}) => {
  switch (widgetType) {
    case CentralWidgetType.WELCOME:
      return <WelcomeWidget />
    case CentralWidgetType.EXPLORE_PROCESS:
      return <ExploreProcessWidget />
    case CentralWidgetType.ABOUT_PROJECT:
      return <AboutProjectWidget />
    case CentralWidgetType.PROJECT:
      return <ProjectsWidgetView widget={{ ...pageWidget, title: 'Your initiatives' }} />
    default:
      return null
  }
}

export const CentralDashboard = () => {
  return (
    <div className={styles.container}>
      {centralWidgets.map(pageWidget => {
        const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
        return (
          <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
            <RenderWidget widgetType={name} pageWidget={pageWidget} />
          </div>
        )
      })}
    </div>
  )
}
