import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import dasboardSrc from 'pages/home/components/pitch/demoDashboard/components/wppTenantWidget/assets/dashboard.png'
import styles from 'pages/home/components/pitch/demoDashboard/components/wppTenantWidget/WppTenantWidget.module.scss'

export const WppTenantWidget = () => {
  return (
    <WppCard className={styles.container}>
      <WppTypography type="3xl-heading">WPP Open tenant</WppTypography>
      <img src={dasboardSrc} className={styles.image} />
    </WppCard>
  )
}
