import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import introImgSrc from 'pages/home/components/pitch/illyDashboard/components/introWidget/assets/intro.png'
import styles from 'pages/home/components/pitch/illyDashboard/components/introWidget/IntroWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const IntroWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex className={styles.cardContent} justify="between">
      <Flex direction="column" justify="center">
        <div className={styles.textContent}>
          <WppTypography type="2xl-heading" className={styles.title}>
            LIVE HAPILLY
          </WppTypography>
          <WppTypography type="s-body">The dream to offer the best coffee to the world, since 1933.</WppTypography>
        </div>
      </Flex>
      <img src={introImgSrc} className={styles.introImg} alt="" />
    </Flex>
  </WidgetCard>
)
