import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ReactComponent as IconCheck } from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/check.svg'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import CustomDropdown from 'pages/home/components/pitch/playgroundDashboard/components/general/dropdown/Dropdown'
import { ProjectRow } from 'pages/home/components/pitch/playgroundDashboard/components/projectRow/ProjectRow'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjects/SectionProjects.module.scss'
import {
  Categories,
  // categoriesProjects,
  ProjectApiDto,
} from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionProjects/sectionProjectsMockData'
import { useOverlayContext } from 'pages/home/components/pitch/playgroundDashboard/hooks/useOverlayContext'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'

const PROJECTS = ['All Projects', 'Project 1', 'Project 2', 'Project 3', 'Project 4']

export const SectionProjects = ({ assignments }: { assignments: ProjectApiDto[] }) => {
  const { setVisible } = useOverlayContext()
  const queryClient = useQueryClient()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return
      }

      if (event.data.type === 'message' && event.data?.closeBriefModal) {
        setVisible()
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_PROJECTS] })
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const categoriesProjects: Record<Categories, ProjectApiDto[]> = {
    [Categories.ALL]: [],
    // Row Data
    [Categories.TASKS]: assignments,
    [Categories.APPROVALS]: [],
    [Categories.REQUESTS]: [],
  }

  const allCategories = [Categories.TASKS, Categories.APPROVALS, Categories.REQUESTS]

  const rowCategories = [Categories.TASKS, Categories.APPROVALS, Categories.REQUESTS]

  const [selectedCategories, setSelectedCategories] = useState([...allCategories, Categories.ALL])
  const [selectedCategoriesRow, setSelectedCategoriesRow] = useState([...rowCategories])

  const clickCategory = (category: Categories) => {
    if (category === Categories.ALL) {
      setSelectedCategories([...allCategories, Categories.ALL])
      setSelectedCategoriesRow([...rowCategories])
    } else {
      const index = selectedCategories.indexOf(category)
      if (index === -1) {
        const tmp = [...selectedCategories, category]
        if (tmp.sort().join(',') === allCategories.sort().join(',')) {
          setSelectedCategories([...tmp, Categories.ALL])
        } else {
          setSelectedCategories(tmp)
        }
        if (rowCategories.includes(category)) {
          setSelectedCategoriesRow([...selectedCategoriesRow, category].sort())
        }
      } else {
        const newSelectedCategories = selectedCategories.filter(item => item !== category && item !== Categories.ALL)
        setSelectedCategories(newSelectedCategories)

        const newSelectedCategoriesRow = selectedCategoriesRow.filter(item => item !== category)
        setSelectedCategoriesRow(newSelectedCategoriesRow)
      }
    }
  }

  const Tags = [
    {
      text: 'Tasks',
      count: categoriesProjects[Categories.TASKS].length,
      class: 'greenTag',
      category: Categories.TASKS,
    },
    {
      text: 'Approvals',
      count: categoriesProjects[Categories.APPROVALS].length,
      class: 'blue1Tag',
      category: Categories.APPROVALS,
    },
    {
      text: 'Requests',
      count: categoriesProjects[Categories.REQUESTS].length,
      class: 'blue2Tag',
      category: Categories.REQUESTS,
    },
  ]

  return (
    <div>
      <div className={stylesMain.blackTitle}>Your Assignments</div>
      <Flex className={clsx(styles.flexBaseWidth, stylesMain.mt24)} align="center" gap={0} justify="between">
        {/* Tags */}
        <Flex className={clsx(styles.flexBaseWidth)} align="center" gap={12}>
          <div className={styles.borderedCircleDefault} onClick={() => clickCategory(Categories.ALL)}>
            <Flex className={styles.borderedCircleDefaultInner} align="center" gap={4}>
              {selectedCategories.includes(Categories.ALL) && (
                <div>
                  <IconCheck />
                </div>
              )}
              <div>View All</div>
            </Flex>
          </div>

          {Tags.map((el, index) => {
            return (
              <Flex
                key={index}
                className={clsx(styles.borderedCircle, styles[el.class])}
                align="center"
                gap={8}
                onClick={() => clickCategory(el.category)}
              >
                {selectedCategories.includes(el.category) && (
                  <div>
                    <IconCheck />
                  </div>
                )}
                <div>{el.text}</div>
                <div className={styles.borderedCircleInner}>{el.count}</div>
              </Flex>
            )
          })}
        </Flex>

        <div className={clsx(stylesMain.blackXSBold, styles.mr25)}>PROJECT</div>
        <CustomDropdown options={PROJECTS} />
      </Flex>

      <div className={clsx(styles.container, stylesMain.mt34)}>
        {selectedCategoriesRow.map((el, idx) =>
          categoriesProjects[el].map((project: any, index: number) => {
            return (
              <ProjectRow
                key={idx + index}
                project={project.project}
                assignment={project.assignment}
                category={el}
                roundedTop={idx === 0 && index === 0}
                roundedBottom={idx === selectedCategoriesRow.length - 1 && index === categoriesProjects[el].length - 1}
                borderBottom={idx !== selectedCategoriesRow.length - 1 || index !== categoriesProjects[el].length - 1}
              />
            )
          }),
        )}
      </div>

      <div className={clsx(stylesMain.flexCenter, stylesMain.mt24)}>
        <Link to="/workfront">
          <Button
            text="View on Workfront"
            variant="tertiary"
            iconRight={<WppIconArrow direction="right" color="black" />}
            small
          />
        </Link>
      </div>
    </div>
  )
}
