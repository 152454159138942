import assignments1 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/assignments-1.png'
import assignments2 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/assignments-2.png'
import assignments3 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/assignments-3.png'
import assignments4 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/assignments-4.png'
import growth1 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/growth-1.png'
import growth2 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/growth-2.png'
import growth3 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/growth-3.png'
import growth4 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/growth-4.png'
import meetings1 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/meetings-1.png'
import meetings2 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/meetings-2.png'
import meetings3 from 'pages/home/components/pitch/playgroundDashboard/assets/images/news/meetings-3.png'

export const categories = [
  ['Culture', 'Social Media', 'Innovations'],
  ['Launches', 'Sparkling Tea', 'Design'],
]

export const newsCardsData = [
  {
    title: 'Meetings',
    number: 3,
    caption: 'See Your recommendations',
    bgClass: 'bgBlue',
    images: [meetings1, meetings2, meetings3],
  },
  {
    title: 'Growth Opportunities',
    number: 4,
    caption: 'View New Projects',
    bgClass: 'bgGreen',
    images: [growth1, growth2, growth3, growth4],
  },
  {
    title: 'Meetings',
    number: 4,
    caption: 'See Next Steps',
    bgClass: 'bgOrange',
    images: [assignments1, assignments2, assignments3, assignments4],
  },
]
