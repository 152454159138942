import { WppActionButton } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

type Props = Omit<ComponentPropsWithoutRef<typeof WppActionButton>, 'children' | 'variant'>

export const ViewAllButton = (props: Props) => {
  const { t } = useTranslation()

  return (
    <WppActionButton {...props} variant="secondary" data-testid="view-all-button">
      {t('os.home.view_all')}
    </WppActionButton>
  )
}
