import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Button } from 'pages/home/components/pitch/playgroundDashboard/components/general/button/Button'
import { Card } from 'pages/home/components/pitch/playgroundDashboard/components/general/card/Card'
import styles from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionNews/SectionNews.module.scss'
import {
  categories,
  newsCardsData,
} from 'pages/home/components/pitch/playgroundDashboard/components/sections/sectionNews/sectionNewsMockData'
import { SwiperCards } from 'pages/home/components/pitch/playgroundDashboard/components/swiperCards/SwiperCards'
import stylesMain from 'pages/home/components/pitch/playgroundDashboard/PlaygroundDashboard.module.scss'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'

export const SectionNews = ({ avatarUrl }: { avatarUrl: string }) => {
  const { userDetails } = useTenantAndUserData()

  const { firstname, lastname } = userDetails

  const date = new Date(userDetails.createdAt)
  const month = date.toLocaleString('default', { month: 'long' })
  const day = date.getDate()
  const year = date.getFullYear()

  return (
    <div>
      <Flex className={clsx(styles.flexBaseWidth, styles.pt72)} align="center" gap={28}>
        <div className={stylesMain['w-1/3']}>
          <div className={clsx(stylesMain.header, stylesMain.mt60)}>
            <div className={stylesMain.preWrap}>{'Your\nNews'}</div>
          </div>

          <Flex className={styles.personRow} align="center" gap={28}>
            <div className={stylesMain.avatarBorder}>
              <Avatar size="l" name={firstname || ''} src={avatarUrl} />
            </div>
            <div>
              <div className={stylesMain.baseBold}>{`${firstname} ${lastname}`}</div>
              <div className={stylesMain.baseNormal}>{`${month} ${day}, ${year}`}</div>
              <div className={styles.rainbowLine} />
            </div>
          </Flex>

          <div className={styles.categoriesContainer}>
            <div className={stylesMain.baseBold}>Explore what matters to you:</div>
            {categories.map((categoryRow, idx) => (
              <Flex key={idx} className={styles.categoriesRow} align="center" gap={12}>
                {categoryRow.map((category, idx2) => (
                  <div key={idx + idx2} className={styles.borderedCircle}>
                    {category}
                  </div>
                ))}
              </Flex>
            ))}
          </div>

          <div className={styles.personRow}>
            <Button text="View All News" iconRight={<WppIconArrow direction="right" color="black" />} />
          </div>
        </div>

        <div className={stylesMain['w-2/3']}>
          <SwiperCards />
        </div>
      </Flex>

      {/* Bottom Cards */}
      <Flex className={styles.flexCards} align="center" gap={28}>
        {newsCardsData.map((cardData, idx) => (
          <div key={idx} className={stylesMain['w-1/3']}>
            <Card
              number={cardData.number}
              title={cardData.title}
              caption={cardData.caption}
              classOuter={styles[cardData.bgClass]}
            >
              {cardData.images.map((image, idx2) => (
                <img key={idx + idx2} src={image} alt="logo" className={clsx(idx2 !== 0 && styles.mlImageInner)} />
              ))}
            </Card>
          </div>
        ))}
      </Flex>
    </div>
  )
}
