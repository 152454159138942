import { CentreOfExcellenceWidget } from 'pages/home/components/pitch/nestleDashboard/components/centreOfExcellenceWidget/CentreOfExcellenceWidget'
import { ImageWidget } from 'pages/home/components/pitch/nestleDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/nestleDashboard/components/processWidget/ProcessWidget'
import { ProjectStatistic } from 'pages/home/components/pitch/nestleDashboard/components/projectStatistic/ProjectStatistic'
import { TeamWidget } from 'pages/home/components/pitch/nestleDashboard/components/teamWidget/TeamWidget'
import { WelcomeWidget } from 'pages/home/components/pitch/nestleDashboard/components/welcomeWidget/WelcomeWidget'
import styles from 'pages/home/components/pitch/nestleDashboard/NestleDashboard.module.scss'
import { nestleWidgets } from 'pages/home/components/pitch/nestleDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { NestleWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | NestleWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case NestleWidgetType.IMAGE:
      return <ImageWidget />
    case NestleWidgetType.WELCOME:
      return <WelcomeWidget />
    case NestleWidgetType.CALENDAR:
      return <CalendarWidget events={[]} clockOptions={[{ timeZone: 'Europe/London', city: 'London' }]} />
    case NestleWidgetType.PROCESS:
      return <ProcessWidget />
    case NestleWidgetType.WRIKE:
      return <ProjectStatistic />
    case NestleWidgetType.TEAM:
      return <TeamWidget />
    case NestleWidgetType.CENTRE_OF_EXCELLENCE:
      return <CentreOfExcellenceWidget />
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={3} />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    default:
      return null
  }
}

export const NestleDashboard = () => (
  <div className={styles.container}>
    {nestleWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
