import { getChildHierarchyLevels, MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { useAllTenantsApi } from 'api/tenant/queries/useAllTenantsApi'
import { useAvailableTenantsApi } from 'api/tenant/queries/useAvailableTenantsApi'
import { useTenantApi } from 'api/tenant/queries/useTenantApi'
import { useCurrentUserApi } from 'api/users/queries/useCurrentUserApi'
import { is403Error, is404Error } from 'api/utils'
import { useIntercomUser } from 'components/intercom/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { TenantAndUserDataContext } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { HostType } from 'types/tenants/tenant'

export const TenantAndUserDataProvider = () => {
  const { t } = useTranslation()
  const { hostInfo } = useOsRoot()

  const { isLoading: isUserLoading, data: userDetails, error: userDetailsError } = useCurrentUserApi()

  useIntercomUser(userDetails)

  const isGenericTenant = hostInfo.type === HostType.GENERIC
  const currentTenantPublic = hostInfo.currentTenant

  // Note: Tenant queries are dependent on the user because new users are created
  // during the first call on the backend, so tenants are not available before that happens.
  const {
    data: availableTenants,
    isLoading: isAvailableTenantsLoading,
    isError: isAvailableTenantsError,
  } = useAvailableTenantsApi({
    enabled: !!userDetails,
  })

  const {
    data: allTenants,
    isLoading: isAllTenantsLoading,
    isError: isAllTenantsError,
  } = useAllTenantsApi({
    enabled: !!userDetails,
  })

  // We send the request only when we have public tenant data, so 404 or 403 means user doesn't have access
  const isTenantNotPermittedError = (error: MayBeNull<AxiosError>) => is404Error(error) || is403Error(error)

  const {
    data: currentTenantFull,
    isLoading: isCurrentTenantFullLoading,
    error: currentTenantFullError,
  } = useTenantApi({
    params: {
      tenantId: currentTenantPublic?.id!,
    },
    enabled: !!userDetails && !isGenericTenant,
    // [WPPLONOP-15829] Long polling added for OS first time access while user is being created in several services
    retry: (failureCount, error) => isTenantNotPermittedError(error) && failureCount < 13,
    retryDelay: 5000,
  })

  useProviderNoncriticalError({
    isError: isAvailableTenantsError || isAllTenantsError,
    message: t('os.provider_errors.available_tenants'),
  })

  const navigationHierarchy = useMemo(
    () => (currentTenantFull ? getChildHierarchyLevels(currentTenantFull) : []),
    [currentTenantFull],
  )

  const requestableTenants = useMemo(
    () =>
      (allTenants || [])
        .filter(tenant => !availableTenants?.find(availableTenant => availableTenant.id === tenant.id))
        .filter(({ flags }) => !flags.onlyAdminsAccessible && flags.accessRequestable)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allTenants, availableTenants],
  )

  const sortedAvailableTenants = useMemo(
    () => availableTenants?.sort((a, b) => a.name.localeCompare(b.name)),
    [availableTenants],
  )

  // We send the request only when we have public tenant data, so 404 or 403 means user doesn't have access
  const isTenantNotPermitted = isTenantNotPermittedError(currentTenantFullError)
  const isUserDetailsNotPermitted = is403Error(userDetailsError)
  const isLoading = isAvailableTenantsLoading || isAllTenantsLoading || isCurrentTenantFullLoading || isUserLoading

  if (isTenantNotPermitted || isUserDetailsNotPermitted) {
    return <ForbiddenOSAccessError />
  }

  if (currentTenantFullError || userDetailsError) {
    return <OsIsNotAvailableError />
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <TenantAndUserDataContext.Provider
      value={{
        currentTenant: currentTenantFull!,
        navigationHierarchy,
        availableTenants: sortedAvailableTenants!,
        requestableTenants,
        userDetails: userDetails!,
      }}
    >
      <Outlet />
    </TenantAndUserDataContext.Provider>
  )
}
