import styles from 'pages/home/components/pitch/aldiDashboard/AldiDashboard.module.scss'
import { ImageWidget } from 'pages/home/components/pitch/aldiDashboard/components/imageWidget/ImageWidget'
import { ProcessWidget } from 'pages/home/components/pitch/aldiDashboard/components/processWidget/ProcessWidget'
import { TeamWidget } from 'pages/home/components/pitch/aldiDashboard/components/teamWidget/TeamWidget'
import { UserWidget } from 'pages/home/components/pitch/aldiDashboard/components/userWidget/UserWidget'
import { aldiWidgets } from 'pages/home/components/pitch/aldiDashboard/widgetsMock'
import { NewsWidgetPitch } from 'pages/home/components/pitch/pitch/newsWidgetPitch/NewsWidgetPitch'
import { ProjectsWidgetView } from 'pages/home/systemWidgets/projectsWidget/ProjectsWidgetView'
import { CalendarWidget } from 'pages/wppPitchOsDashboard/components/calendarWidget/CalendarWidget'
import { AldiWidgetType, ProjectsWidgetDetailsType, SystemWidget } from 'types/widgets/widget'

const RenderWidget = ({ widgetType, pageWidget }: { widgetType: SystemWidget | AldiWidgetType; pageWidget: any }) => {
  switch (widgetType) {
    case AldiWidgetType.IMAGE:
      return <ImageWidget />
    case AldiWidgetType.CALENDAR:
      return (
        <CalendarWidget
          events={[
            {
              getDate: () => new Date('2023/07/14'),
              getText: () => '',
            },
            {
              getDate: () => new Date('2023/07/15'),
              getText: () => '',
            },
          ]}
          clockOptions={[{ timeZone: 'Europe/Berlin', city: 'Essen' }]}
        />
      )
    case AldiWidgetType.PROCESS:
      return <ProcessWidget />
    case AldiWidgetType.USER:
      return <UserWidget />
    case AldiWidgetType.TEAM:
      return <TeamWidget />
    case SystemWidget.PROJECTS:
      return (
        <ProjectsWidgetView
          widget={{ ...pageWidget, title: 'Projects', config: { detailsType: ProjectsWidgetDetailsType.ProjectType } }}
        />
      )
    case SystemWidget.NEWS:
      return <NewsWidgetPitch newsCount={4} />
    default:
      return null
  }
}

export const AldiDashboard = () => (
  <div className={styles.container}>
    {aldiWidgets.map(pageWidget => {
      const { id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name } = pageWidget
      return (
        <div id={id} key={id} style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}>
          <RenderWidget widgetType={name} pageWidget={pageWidget} />
        </div>
      )
    })}
  </div>
)
