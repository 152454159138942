import AlexSteer from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Alex_Steer.jpeg'
import AlissaKaplan from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Alissa_Kaplan.jpeg'
import ChrisHopkins from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Chris_Hopkins.jpeg'
import FlorenceMichelet from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Florence_Michelet.jpeg'
import FrancesRalstonGood from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Frances_Ralston_Good.jpeg'
import JasonXenopoulos from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Jason_Xenopoulos.jpeg'
import JoseFerreira from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Jose_Ferreira.jpeg'
import JustinWhite from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Justin_White.jpeg'
import MebFrancisco from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Meb_Francisco.jpeg'
import MikeDuke from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Mike_Duke.jpeg'
import PatriciaPlantinga from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Patricia_Plantinga.jpeg'
import StephanieBrewer from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Stephanie_Brewer.jpeg'
import SusanDorfman from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Susan_Dorfman.jpeg'
import WillSandwick from 'pages/home/components/pitch/pfizerDashboard/components/teamWidget/assets/users/Will_Sandwick.jpeg'

interface Team {
  id: string
  name: string
}
interface TeamMember {
  teamId: Team['id']
  name: string
  position: string
  imageSrc: string
}

interface GroupedTeamMembers {
  [teamId: string]: TeamMember[]
}

export const teamsList: Team[] = [
  { id: 'media', name: 'Media Team' },
  { id: 'creative', name: 'Creative Team' },
  { id: 'planning', name: 'Planning Team' },
  { id: 'strategy', name: 'Strategy Team' },
]

const teamMembersMock: TeamMember[] = [
  {
    teamId: 'media',
    name: 'Alissa Kaplan',
    position: 'Pfizer Global Client Lead',
    imageSrc: AlissaKaplan,
  },
  {
    teamId: 'media',
    name: 'Alex Steer',
    position: 'Global Data & Technology Lead',
    imageSrc: AlexSteer,
  },
  {
    teamId: 'media',
    name: 'Will Sandwick',
    position: 'Data Strategy Lead',
    imageSrc: WillSandwick,
  },
  {
    teamId: 'media',
    name: 'Chris Hopkins',
    position: 'Data Science Lead',
    imageSrc: ChrisHopkins,
  },
  {
    teamId: 'creative',
    name: 'Susan Dorfman',
    position: 'Omnichannel Strategy Lead',
    imageSrc: SusanDorfman,
  },
  {
    teamId: 'creative',
    name: 'Patricia Plantinga',
    position: 'Data Platform Lead',
    imageSrc: PatriciaPlantinga,
  },
  {
    teamId: 'creative',
    name: 'Meb Francisco',
    position: 'Media Activation Lead',
    imageSrc: MebFrancisco,
  },
  {
    teamId: 'creative',
    name: 'Mike Duke',
    position: 'Owned Activation Lead',
    imageSrc: MikeDuke,
  },
  {
    teamId: 'planning',
    name: 'Jose Ferreira',
    position: 'Omnichannel Transformation Lead',
    imageSrc: JoseFerreira,
  },
  {
    teamId: 'planning',
    name: 'Florence Michelet',
    position: 'Content Lead',
    imageSrc: FlorenceMichelet,
  },
  {
    teamId: 'planning',
    name: 'Jason XenopoUlos',
    position: 'Global Creative Lead',
    imageSrc: JasonXenopoulos,
  },
  {
    teamId: 'strategy',
    name: 'Frances Ralston-Good',
    position: 'Transformation Lead',
    imageSrc: FrancesRalstonGood,
  },
  {
    teamId: 'strategy',
    name: 'Justin White',
    position: 'Media Lead',
    imageSrc: JustinWhite,
  },
  {
    teamId: 'strategy',
    name: 'Stephanie Brewer',
    position: 'PhD Medical Lead',
    imageSrc: StephanieBrewer,
  },
]

const groupByTeamId = (teamMembers: TeamMember[]): GroupedTeamMembers => {
  return teamMembers.reduce((acc: GroupedTeamMembers, member: TeamMember) => {
    if (!acc[member.teamId]) {
      acc[member.teamId] = []
    }
    acc[member.teamId].push(member)
    return acc
  }, {})
}

export const groupedTeamMembers = groupByTeamId(teamMembersMock)
