import { HaleonWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type HaleonWidget = Omit<Widget, 'name'> & { name: HaleonWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'haleon_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [HaleonWidgetType.WELCOME]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 9,
  },
  [HaleonWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 9,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 9,
  },
  [HaleonWidgetType.APPS]: {
    gridRowStart: 3,
    gridColumnStart: 9,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [HaleonWidgetType.TEAMS]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [SystemWidget.NEWS]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
}

export const haleonWidgets: HaleonWidget[] = [
  {
    ...gridConfig[HaleonWidgetType.WELCOME],
    ...commonWidgetsConfig,
    name: HaleonWidgetType.WELCOME,
    id: 'haleon_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[HaleonWidgetType.USER],
    ...commonWidgetsConfig,
    name: HaleonWidgetType.USER,
    id: 'haleon_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'haleon_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[HaleonWidgetType.APPS],
    ...commonWidgetsConfig,
    name: HaleonWidgetType.APPS,
    id: 'haleon_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[HaleonWidgetType.TEAMS],
    ...commonWidgetsConfig,
    name: HaleonWidgetType.TEAMS,
    id: 'haleon_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.NEWS],
    ...commonWidgetsConfig,
    name: SystemWidget.NEWS,
    id: 'haleon_6',
    createdAt: '',
    updatedAt: '',
  },
]
