import { OrangeWidgetType, SystemWidget, Widget, WidgetType } from 'types/widgets/widget'

export type OrangeWidget = Omit<Widget, 'name'> & { name: OrangeWidgetType | SystemWidget }

const commonWidgetsConfig: Pick<Widget, 'type' | 'enabled' | 'pageId' | 'title'> = {
  type: WidgetType.PREDEFINED,
  enabled: true,
  pageId: 'orange_page',
  title: '',
}

const gridConfig: Record<any, Pick<Widget, 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'>> = {
  [OrangeWidgetType.BRAND]: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRowEnd: 3,
    gridColumnEnd: 9,
  },
  [OrangeWidgetType.USER]: {
    gridRowStart: 1,
    gridColumnStart: 9,
    gridRowEnd: 3,
    gridColumnEnd: 17,
  },
  [SystemWidget.PROJECTS]: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridRowEnd: 5,
    gridColumnEnd: 9,
  },
  [OrangeWidgetType.PROCESS]: {
    gridRowStart: 3,
    gridColumnStart: 9,
    gridRowEnd: 5,
    gridColumnEnd: 17,
  },
  [OrangeWidgetType.MILESTONES]: {
    gridRowStart: 5,
    gridColumnStart: 1,
    gridRowEnd: 7,
    gridColumnEnd: 9,
  },
  [OrangeWidgetType.BAV]: {
    gridRowStart: 5,
    gridColumnStart: 9,
    gridRowEnd: 7,
    gridColumnEnd: 17,
  },
  [OrangeWidgetType.POSTS]: {
    gridRowStart: 7,
    gridColumnStart: 1,
    gridRowEnd: 9,
    gridColumnEnd: 17,
  },
  [OrangeWidgetType.SOCIAL_ACTIVITY]: {
    gridRowStart: 9,
    gridColumnStart: 1,
    gridRowEnd: 11,
    gridColumnEnd: 17,
  },
}

export const orangeWidgets: OrangeWidget[] = [
  {
    ...gridConfig[OrangeWidgetType.BRAND],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.BRAND,
    id: 'orange_1',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.USER],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.USER,
    id: 'orange_2',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[SystemWidget.PROJECTS],
    ...commonWidgetsConfig,
    name: SystemWidget.PROJECTS,
    id: 'orange_3',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.PROCESS],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.PROCESS,
    id: 'orange_4',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.MILESTONES],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.MILESTONES,
    id: 'orange_5',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.BAV],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.BAV,
    id: 'orange_6',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.POSTS],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.POSTS,
    id: 'orange_7',
    createdAt: '',
    updatedAt: '',
  },
  {
    ...gridConfig[OrangeWidgetType.SOCIAL_ACTIVITY],
    ...commonWidgetsConfig,
    name: OrangeWidgetType.SOCIAL_ACTIVITY,
    id: 'orange_8',
    createdAt: '',
    updatedAt: '',
  },
]
