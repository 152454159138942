import { useContext } from 'react'

import { OverlayContext } from 'pages/home/components/pitch/playgroundDashboard/contexts/OverlayContext'

export const useOverlayContext = () => {
  const context = useContext(OverlayContext)
  if (!context) {
    throw new Error('useOverlayContext must be used within a OverlayProvider')
  }

  return {
    ...context,
  }
}
