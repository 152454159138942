import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { team } from 'pages/home/components/pitch/uberDashboard/components/teamWidget/teamMock'
import styles from 'pages/home/components/pitch/uberDashboard/components/teamWidget/TeamWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const TeamWidget = () => (
  <WidgetCard className={styles.uberTeamCard}>
    <WppTypography type="xl-heading">The Team</WppTypography>
    <div className={styles.teamsContainer}>
      <div className={styles.teamMembersGrid}>
        {team.map((teamMember, index) => (
          <Flex gap={12} align="center" className={styles.teamMemberContainer} key={index}>
            <img src={teamMember.profileImgSrc} alt="" className={styles.teamMemberImg} />
            <div>
              <WppTypography type="s-strong">{teamMember.name}</WppTypography>
              <div>
                <WppTypography type="xs-body">{teamMember.role}</WppTypography>
              </div>
            </div>
          </Flex>
        ))}
      </div>
    </div>
  </WidgetCard>
)
