import { workfrontApi } from 'api'

export const fetchWorkfrontAuthRefresh = ({
  tenantId = '40c0ccbc-e69f-461d-93cf-cf5e08cf9e3c',
}: {
  tenantId?: string
}) =>
  workfrontApi.get<{ accessToken: string }>('/auth/refresh', {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
