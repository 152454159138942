import { format } from 'date-fns'
import { useCallback } from 'react'

import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { DateFormat } from 'types/common/dates'

export const useDateFormat = () => {
  const {
    userDetails: { dateLocale },
  } = useTenantAndUserData()

  return {
    formatDate: useCallback(
      (dateToFormat: string): string => {
        const locale = DateFormat[dateLocale as keyof typeof DateFormat]
        return format(new Date(dateToFormat), locale)
      },
      [dateLocale],
    ),
  }
}
