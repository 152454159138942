import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'pages/home/components/pitch/easyJetDashboard/components/airFlowWidget/AirFlowWidget.module.scss'

export const AirFlowWidget = () => {
  return (
    <Link to="/process/custom">
      <WppCard className={styles.card}>
        <div slot="header">
          <WppTypography type="xl-heading">Air Flow</WppTypography>
        </div>
        <WppTypography type="s-body" className={styles.note}>
          Air flow is our unique solution that capitalizes on heightened awareness. The planning process is here for us
          to work better together, to be more consistent and to have the time and space to do better work. It’s been
          built for strategy, planning, activation and adaptation. These three phases combine to produce an activity
          that is effective and flexible.
        </WppTypography>
      </WppCard>
    </Link>
  )
}
