import { useMemo } from 'react'

import { AppInstanceShort } from 'types/apps/appInstances'
import { sortBy } from 'utils/common'

export const useGroupAppInstances = ({ appInstances }: { appInstances: AppInstanceShort[] }) =>
  useMemo(() => {
    const ungrouped: AppInstanceShort[] = []

    const groupedMap: Record<
      string,
      {
        group: NonNullable<AppInstanceShort['group']>
        appInstances: AppInstanceShort[]
      }
    > = {}

    for (const appInstance of appInstances) {
      const { group } = appInstance

      if (group) {
        const groupId = group.id

        if (!groupedMap[groupId]) {
          groupedMap[groupId] = {
            group,
            appInstances: [appInstance],
          }
        } else {
          groupedMap[groupId].appInstances.push(appInstance)
        }
      } else {
        ungrouped.push(appInstance)
      }
    }

    const groups = Object.values(groupedMap).map(({ group, appInstances }) => ({
      group,
      appInstances: sortBy(appInstances, ({ position }) => position),
    }))

    return {
      ungrouped: sortBy(ungrouped, ({ position }) => position),
      grouped: sortBy(groups, ({ group }) => group.order || Number.POSITIVE_INFINITY),
    }
  }, [appInstances])
