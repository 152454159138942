import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/spotifyDashboard/components/culturalDigestWidget/Event.module.scss'

export interface IEvent {
  title: string
  startDate: string
  endDate?: string
  banner: string
}

export const Event = ({ event }: { event: IEvent }) => {
  return (
    <Flex justify="between" align="center" gap={12} className={styles.root}>
      <div>
        <WppTypography type="s-strong">{event.title}</WppTypography>
        <WppTypography type="xs-body" className={styles.date}>
          {event.startDate}
          {event.startDate && event.endDate && ' - '}
          {event.endDate}
        </WppTypography>
      </div>
      <img src={event.banner} alt="" className={styles.image} />
    </Flex>
  )
}
