import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import imgSrc from 'pages/home/components/pitch/perrigoDashboard/components/collaborationWidget/assets/image.png'
import styles from 'pages/home/components/pitch/perrigoDashboard/components/collaborationWidget/CollaborationWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const CollaborationWidget = () => (
  <WidgetCard className={styles.card}>
    <Flex gap={4} direction="column" className={styles.perrigoProcessCardHeader}>
      <WppTypography type="s-strong">Collaboration</WppTypography>
      <WppTypography type="s-body">Local Market Activation</WppTypography>
    </Flex>
    <img src={imgSrc} className={styles.image} alt="" />
  </WidgetCard>
)
