import { ComponentPropsWithoutRef } from 'react'

import styles from 'components/svg/common.module.scss'

export const SvgNoFavouriteLinks = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8434_887)">
      <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
      <path
        d="M100.369 60.6246C100.635 59.6375 102.097 59.6375 102.363 60.6246L102.502 61.142C102.599 61.5003 102.896 61.7768 103.273 61.8582L103.563 61.921C104.634 62.1525 104.634 63.6161 103.563 63.8476L103.273 63.9104C102.896 63.9917 102.599 64.2682 102.502 64.6265L102.363 65.1439C102.097 66.1311 100.635 66.1311 100.369 65.1439L100.23 64.6265C100.133 64.2682 99.8356 63.9917 99.4591 63.9104L99.1689 63.8476C98.098 63.6161 98.098 62.1525 99.1689 61.921L99.4591 61.8582C99.8356 61.7768 100.133 61.5003 100.23 61.142L100.369 60.6246Z"
        className={styles.fillPrimary400}
      />
      <ellipse cx="105.98" cy="53.8843" rx="1.49992" ry="1.5" className={styles.fillPrimary300} />
      <path
        d="M55.7541 6.86785C56.0866 5.63389 57.9136 5.6339 58.2462 6.86785L58.4205 7.51454C58.5412 7.96247 58.9129 8.30805 59.3835 8.40979L59.7463 8.48822C61.0849 8.77766 61.0849 10.6071 59.7463 10.8965L59.3835 10.975C58.9129 11.0767 58.5412 11.4223 58.4205 11.8702L58.2462 12.5169C57.9136 13.7509 56.0866 13.7509 55.7541 12.5169L55.5798 11.8702C55.4591 11.4223 55.0873 11.0767 54.6167 10.975L54.254 10.8965C52.9153 10.6071 52.9153 8.77766 54.254 8.48822L54.6167 8.40979C55.0873 8.30805 55.4591 7.96247 55.5798 7.51454L55.7541 6.86785Z"
        className={styles.fillPrimary300}
      />
      <circle cx="65.25" cy="15.231" r="1.5" className={styles.fillPrimary400} />
      <circle cx="12.4619" cy="41.7693" r="1.5" className={styles.fillPrimary200} />
      <g opacity="0.8" filter="url(#filter0_d_8434_887)">
        <rect x="12" y="49.5" width="75" height="21" rx="4" className={styles.fillWhite} />
      </g>
      <rect x="34.5" y="54" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
      <rect x="34.5" y="61.5" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <ellipse cx="23.25" cy="60.0001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
      <g clipPath="url(#clip1_8434_887)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2515 56.3813C23.3794 56.3813 23.4962 56.4536 23.5534 56.5679L24.5066 58.4743L26.7716 58.8227C26.8973 58.8421 27.0014 58.9304 27.041 59.0513C27.0805 59.1722 27.0488 59.305 26.9589 59.395L25.3923 60.9615L25.7413 63.2299C25.7607 63.3557 25.7077 63.4816 25.6042 63.5557C25.5007 63.6298 25.3644 63.6393 25.2516 63.5805L23.2515 62.5369L21.2514 63.5805C21.1386 63.6393 21.0023 63.6298 20.8989 63.5557C20.7954 63.4816 20.7424 63.3557 20.7617 63.2299L21.1107 60.9615L19.5442 59.395C19.4542 59.305 19.4225 59.1722 19.4621 59.0513C19.5016 58.9304 19.6058 58.8421 19.7315 58.8227L21.9965 58.4743L22.9497 56.5679C23.0068 56.4536 23.1237 56.3813 23.2515 56.3813ZM23.2515 57.4735L22.5222 58.9323C22.4732 59.0302 22.3798 59.0982 22.2716 59.1149L20.4924 59.3886L21.7089 60.6051C21.785 60.6812 21.8202 60.7889 21.8039 60.8951L21.5302 62.6737L23.0954 61.8571C23.1932 61.806 23.3098 61.806 23.4076 61.8571L24.9728 62.6737L24.6992 60.8951C24.6828 60.7889 24.7181 60.6812 24.7941 60.6051L26.0106 59.3886L24.2314 59.1149C24.1233 59.0982 24.0299 59.0302 23.9809 58.9323L23.2515 57.4735Z"
          className={styles.fillGrey100}
        />
      </g>
      <g opacity="0.8" filter="url(#filter1_d_8434_887)">
        <rect x="24.75" y="75" width="75" height="21" rx="4" className={styles.fillWhite} />
      </g>
      <rect x="47.25" y="79.5" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
      <rect x="47.25" y="87" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <ellipse cx="36" cy="85.5001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0015 81.8813C36.1294 81.8813 36.2462 81.9536 36.3034 82.0679L37.2566 83.9743L39.5216 84.3227C39.6473 84.3421 39.7514 84.4304 39.791 84.5513C39.8305 84.6722 39.7988 84.805 39.7089 84.895L38.1423 86.4615L38.4913 88.7299C38.5107 88.8557 38.4577 88.9816 38.3542 89.0557C38.2507 89.1298 38.1144 89.1393 38.0016 89.0805L36.0015 88.0369L34.0014 89.0805C33.8886 89.1393 33.7523 89.1298 33.6489 89.0557C33.5454 88.9816 33.4924 88.8557 33.5117 88.7299L33.8607 86.4615L32.2942 84.895C32.2042 84.805 32.1725 84.6722 32.2121 84.5513C32.2516 84.4304 32.3558 84.3421 32.4815 84.3227L34.7465 83.9743L35.6997 82.0679C35.7568 81.9536 35.8737 81.8813 36.0015 81.8813ZM36.0015 82.9735L35.2722 84.4323C35.2232 84.5302 35.1298 84.5982 35.0216 84.6149L33.2424 84.8886L34.4589 86.1051C34.535 86.1812 34.5702 86.2889 34.5539 86.3951L34.2802 88.1737L35.8454 87.3571C35.9432 87.306 36.0598 87.306 36.1576 87.3571L37.7228 88.1737L37.4492 86.3951C37.4328 86.2889 37.4681 86.1812 37.5441 86.1051L38.7606 84.8886L36.9814 84.6149C36.8733 84.5982 36.7799 84.5302 36.7309 84.4323L36.0015 82.9735Z"
        className={styles.fillGrey100}
      />
      <g opacity="0.8" filter="url(#filter2_d_8434_887)">
        <rect x="24.75" y="24" width="75" height="21" rx="4" className={styles.fillWhite} />
      </g>
      <rect x="47.25" y="28.5" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
      <rect x="47.25" y="36" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
      <ellipse cx="36" cy="34.5001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0015 30.8813C36.1294 30.8813 36.2462 30.9536 36.3034 31.0679L37.2566 32.9743L39.5216 33.3227C39.6473 33.3421 39.7514 33.4304 39.791 33.5513C39.8305 33.6722 39.7988 33.805 39.7089 33.895L38.1423 35.4615L38.4913 37.7299C38.5107 37.8557 38.4577 37.9816 38.3542 38.0557C38.2507 38.1297 38.1144 38.1393 38.0016 38.0805L36.0015 37.0369L34.0014 38.0805C33.8886 38.1393 33.7523 38.1297 33.6489 38.0557C33.5454 37.9816 33.4924 37.8557 33.5117 37.7299L33.8607 35.4615L32.2942 33.895C32.2042 33.805 32.1725 33.6722 32.2121 33.5513C32.2516 33.4304 32.3558 33.3421 32.4815 33.3227L34.7465 32.9743L35.6997 31.0679C35.7568 30.9536 35.8737 30.8813 36.0015 30.8813ZM36.0015 31.9735L35.2722 33.4323C35.2232 33.5302 35.1298 33.5982 35.0216 33.6149L33.2424 33.8886L34.4589 35.1051C34.535 35.1812 34.5702 35.2889 34.5539 35.3951L34.2802 37.1737L35.8454 36.3571C35.9432 36.306 36.0598 36.306 36.1576 36.3571L37.7228 37.1737L37.4492 35.3951C37.4328 35.2889 37.4681 35.1812 37.5441 35.1051L38.7606 33.8886L36.9814 33.6149C36.8733 33.5982 36.7799 33.5302 36.7309 33.4323L36.0015 31.9735Z"
        className={styles.fillGrey100}
      />
      <circle cx="60" cy="107.654" r="2.25" className={styles.fillPrimary300} />
    </g>
    <defs>
      <filter
        id="filter0_d_8434_887"
        x="4.61538"
        y="44.5769"
        width="89.7692"
        height="35.7692"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.46154" />
        <feGaussianBlur stdDeviation="3.69231" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8434_887" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8434_887" result="shape" />
      </filter>
      <filter
        id="filter1_d_8434_887"
        x="17.3654"
        y="70.0769"
        width="89.7692"
        height="35.7692"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.46154" />
        <feGaussianBlur stdDeviation="3.69231" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8434_887" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8434_887" result="shape" />
      </filter>
      <filter
        id="filter2_d_8434_887"
        x="17.3654"
        y="19.0769"
        width="89.7692"
        height="35.7692"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.46154" />
        <feGaussianBlur stdDeviation="3.69231" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8434_887" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8434_887" result="shape" />
      </filter>
      <clipPath id="clip0_8434_887">
        <rect width="120" height="120" rx="60" className={styles.fillWhite} />
      </clipPath>
      <clipPath id="clip1_8434_887">
        <rect width="8.99989" height="8.99989" className={styles.fillWhite} transform="translate(18.7515 55.5)" />
      </clipPath>
    </defs>
  </svg>
)
