import { WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'

import jiraIconSrc from 'assets/pitch/pfizer/jira_icon.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/home/components/pitch/pfizerDashboard/components/projectManagementWidget/ProjectManagementWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

const jiraOptions = [
  {
    label: 'Indispensable Inlyta 2023',
    link: '#',
  },
  {
    label: 'Light the way Inlyta 2023',
    link: '#',
  },
  {
    label: 'Call it a comeback Rjtlecitinib 2023',
    link: '#',
  },
  {
    label: 'Don’t got this Nurtec ODT 2023',
    link: '#',
  },
  {
    label: 'Against the ‘Graine Nurtec ODT 2023',
    link: '#',
  },
  {
    label: 'Growth starts within Rjtlecitinib 2023',
    link: '#',
  },
]

export const JiraContent = () => (
  <WidgetCard className={styles.projectContent}>
    <Flex gap={15} direction="column" className={styles.listContainer}>
      {jiraOptions.map((option, idx) => (
        <a key={idx} className={styles.link} href={option.link}>
          <img src={jiraIconSrc} alt="" />
          <WppTypography type="s-body">{option.label}</WppTypography>
        </a>
      ))}
    </Flex>

    <WppActionButton variant="primary" className={styles.actionButton}>
      Go to Jira
    </WppActionButton>
  </WidgetCard>
)
