import { facadeApi } from 'api'

export interface SignUpRequestFormDto {
  firstName: string
  lastName: string
  email: string
}

export interface SignUpResponse {
  id: string
  tenantId: string
  firstName: string
  lastName: string
  createdBy: string
  status: 'new'
}

export const createSignUpRequestApi = ({ userData, tenantId }: { userData: SignUpRequestFormDto; tenantId: string }) =>
  facadeApi.post<SignUpResponse>('/sign-up-requests', { ...userData, tenantId })
