import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { TeamCard } from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/components/teamCard/TeamCard'
import { teams } from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/teamsMock'
import styles from 'pages/home/components/pitch/haleonDashboard/components/teamsWidget/TeamsWidget.module.scss'
import { WidgetCard } from 'pages/home/widgetCard/WidgetCard'

export const TeamsWidget = () => (
  <WidgetCard className={styles.haleonTeamCard}>
    <WppTypography type="l-strong" className={styles.haleonTeamsWidgetTitle}>
      Teams
    </WppTypography>
    <Flex gap={24}>
      {teams.map((team, index) => (
        <TeamCard team={team} key={index} />
      ))}
    </Flex>
  </WidgetCard>
)
